import React from "react";
import { Grid, Snackbar } from "@material-ui/core/";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
//import axios from "../../../utils/axiosInstance";
import { withRouter } from "react-router-dom";
import axios from "axios";
import JobListItem from "./JobListItem/JobListItem";
import Spinner from "../../../UI/Skeletons/JobsSkeleton";
import Pagination from "../../../UI/Pagination/Pagination";
import AxiosErrorHandling from "../../../components/AxiosErrorHandling/AxiosErrorHandling";
import { JobsCacheRefresh } from "../../../config/jobsConfigData";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    marginBotton: theme.spacing(4),
  },
  checkbox: {
    marginLeft: theme.spacing(2),
  },
}));

const JobsList = ({ filter, viewType, ...props }) => {
  const [jobs, setJobs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalJobs, setTotalJobs] = React.useState(0);
  const [duplicatedSuccessfully, setDuplicatedSuccessfully] = React.useState(
    -1
  ); //if true, holds the id of the new job
  const [error, setError] = React.useState(null);
  const classes = useStyles();

  const getJobDetailsHandler = (jobId) => {
    props.viewJobDashboard(jobs.find((job) => job.id === jobId));
  };

  React.useEffect(() => {
    const lastJobPage = sessionStorage.getItem("ljp")
      ? parseInt(sessionStorage.getItem("ljp"))
      : 1;
    if (lastJobPage) setCurrentPage(lastJobPage);
    if ((props.clearFilter || filter) && currentPage !== 1) setCurrentPage(1);

    getJobs(props.clearFilter || filter ? 1 : lastJobPage, filter.refreshList); //last param to indicates force refresh if createdbyme is checked.
  }, [filter, props.clearFilter]);

  React.useEffect(() => {
    if (jobs.length > 0) saveOrUpdateCache();
  }, [jobs]);

  const handleReloadPage = () => {
    setError(null);
    if (currentPage !== 1) setCurrentPage(1);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    //return to the top of the page:
    //Keep last page in localStorage:
    sessionStorage.setItem("ljp", page);
    getJobs(page, false);
  };

  const handleOnNewCandidateNotification = async (jobId, setNotification, forwardNotifications) => {
    const res = await axios.post(
      `/wp-json/api/v2/new_candidates_notification/${parseInt(jobId)}`,
      {
        new_candidates_notification: setNotification,
        forward_notifications: forwardNotifications.length > 0 ? forwardNotifications : undefined
      }
    );
    if (res.data) {
      const localJobs = [...jobs];
      const jobIndex = localJobs.findIndex((j) => j.id === jobId);
      if (jobIndex > -1) {
        localJobs[jobIndex].new_candidates_notification = setNotification;
        setJobs(localJobs);
      }
    }
  };

  const handleOnAutoDistribution = async (jobId, setAutoDist) => {
    try {
      const res = await axios.post(
        `/wp-json/api/v2/auto_distribution/${parseInt(jobId)}`,
        {
          autoDist: setAutoDist,
        }
      );
      if (res.data) {
        //set job state to value
        const cacheType = getCacheType();
        let tempJobs = null;
        let jobIndex = -1;
        if (cacheType !== null) {
          tempJobs = sessionStorage.getItem(cacheType);
          //update cache:
          if (tempJobs !== "") {
            tempJobs = JSON.parse(tempJobs);
            jobIndex = tempJobs.jobs.findIndex((job) => job.id === jobId);
            if (jobIndex > -1) {
              tempJobs.jobs[jobIndex].is_auto_distribution = setAutoDist;
            }
            sessionStorage.removeItem(cacheType);
            sessionStorage.setItem(cacheType, JSON.stringify(tempJobs));
          }
        }
        //update state
        tempJobs = jobs;
        jobIndex = tempJobs.findIndex((job) => job.id === jobId);
        if (jobIndex > -1) {
          tempJobs[jobIndex].is_auto_distribution = setAutoDist;
          setJobs(tempJobs);
          getJobs(currentPage, false);
        }
      }
    } catch (err) {
      setError(err.response);
    }
  };

  const handleDuplicateJob = async (jobId, withFQ) => {
    try {
      const duplicatedJob = await axios.post(
        `/wp-json/api/v2/duplicate_job/${parseInt(jobId)}`,
        {
          author: localStorage.getItem("userId"),
          duplicate_filter_questions: withFQ,
        }
      );
      setDuplicatedSuccessfully(parseInt(duplicatedJob.data.id));
      //setJobs((prev) => [...prev, duplicatedJob.data]);
      //refreshing the cache:
      getJobs(1, true);
    } catch (err) {
      setError(err.response);
    }
  };

  const isCacheRequired = () => {
    return (
      filter.selectedRecruiters.length === 1 &&
      filter.selectedRecruiters[0] ===
        parseInt(localStorage.getItem("userId")) &&
      filter.searchTitle === ""
    );
  };

  //Possible return values:
  //jobsClosed
  //jobs
  //draftsClosed
  //drafts
  //null - no cache required
  const getCacheType = () => {
    let cacheType = null;
    //First, check validation for caching, by checking if the filter is for current loggedin user:
    if (
      filter.selectedRecruiters.length === 1 &&
      filter.selectedRecruiters[0] === parseInt(localStorage.getItem("userId"))
    ) {
      //drafts
      if (
        (props.location.pathname === "/jobs/drafts" || filter.showDrafts) &&
        !filter.closedOnly
      )
        cacheType = "drafts";
      if (
        (props.location.pathname === "/jobs/drafts" || filter.showDrafts) &&
        filter.closedOnly
      )
        cacheType = "draftsClosed";
      if (
        (props.location.pathname !== "/jobs/drafts" || !filter.showDrafts) &&
        filter.closedOnly
      )
        cacheType = "jobsClosed";
      if (
        (props.location.pathname !== "/jobs/drafts" || !filter.showDrafts) &&
        !filter.closedOnly
      )
        cacheType = "jobs";

      return cacheType;
    }
    return null;
  };

  //Check if cache type, for the current page exists. If true, return the cache
  const isCacheExists = (cacheType, page) => {
    if (!cacheType || filter.searchTitle !== "") return false;
    const cache = sessionStorage.getItem(cacheType);
    if (cache) {
      //Check if not expired:
      const parsedCache = JSON.parse(cache);
      if (parsedCache.exp > Date.now()) {
        //check if page exists:
        if (parsedCache.pages.includes(page)) return parsedCache;
      } else {
        //expired cache - clear cache and return false;
        sessionStorage.removeItem(cacheType);
      }
    }
    return false;
  };

  //This function is called whenever jobs is updated after fetch
  const saveOrUpdateCache = () => {
    const cacheType = getCacheType();
    if (!cacheType) return;
    const cacheObj = isCacheExists(cacheType, currentPage);
    if (!cacheObj) {
      //check if this is just an update or new
      let cacheUpdate = sessionStorage.getItem(cacheType)
        ? JSON.parse(sessionStorage.getItem(cacheType))
        : sessionStorage.getItem(cacheType);
      if (!cacheUpdate) {
        let cachedjobs = {
          jobs: jobs,
          headers: {
            totalPages: totalPages,
            totalJobs: totalJobs,
          },
          exp: Date.now() + JobsCacheRefresh,
          pages: [currentPage],
        };
        //no cache found
        //clearCache:
        sessionStorage.removeItem(cacheType);
        sessionStorage.setItem(cacheType, JSON.stringify(cachedjobs));
      } else {
        //we have cache, needs an update:
        //the order of adding the pages, determain the order of jobs
        if (filter.searchTitle.trim() === "")
          cacheUpdate = {
            jobs: cacheUpdate.jobs.concat(jobs),
            pages: [...cacheUpdate.pages, currentPage],
            headers: { totalJobs: totalJobs, totalPages: totalPages },
            exp: cacheUpdate.exp,
          };
        sessionStorage.removeItem(cacheType);
        sessionStorage.setItem(cacheType, JSON.stringify(cacheUpdate));
      }
    }
  };

  const fetchJobs = (filter, page) => {
    setIsLoading(true);
    axios
      .get("/wp-json/api/v2/jobs_summary", {
        params: {
          per_page: 10,
          page: page,
          filter,
          showStats: false,
        },
      })
      .then((res) => {
        setTotalPages(parseInt(res.headers["x-wp-totalpages"]));
        setTotalJobs(parseInt(res.headers["x-wp-total"]));
        setJobs(res.data);
        setIsLoading(false);
      });
  };
  //Brings jobs summary from server.
  //Page - the page number
  //forceRefresh - if true, get data from server.
  const getJobs = async (page = 1, forceRefresh = false) => {
    if (forceRefresh || !isCacheRequired()) {
      fetchJobs(filter, page);
    } else {
      //Check if we have cache.
      const cacheObj = isCacheExists(getCacheType(), page);
      if (cacheObj) {
        //read cache:
        setTotalPages(parseInt(cacheObj.headers.totalPages));
        setTotalJobs(parseInt(cacheObj.headers.totalJobs));
        //the jobs from cache taken at the order of pages:
        //check the index of page, then take the correct chunk
        const pageIndex = cacheObj.pages.findIndex(
          (cachepage) => parseInt(cachepage) === page
        );
        if (pageIndex > -1) {
          setJobs(cacheObj.jobs.slice(pageIndex * 10, pageIndex * 10 + 10));
        } else {
          setJobs(cacheObj.jobs.slice(0, 10));
        }
        //setJobs(cacheObj.jobs.slice((page - 1) * 10, (page - 1) * 10 + 10));
      } else {
        //we did not find cache. Fetch from server, and update the cache

        fetchJobs(filter, page);
      }
    }
  };

  let showPagination = !isLoading ? (
    <Pagination
      onChange={handlePageChange}
      totalPages={totalPages}
      currentPage={currentPage}
      total={totalJobs}
      reload={handleReloadPage}
      allJobs={filter.closedOnly}
    />
  ) : null;

  let jobsListItems = [];
  jobsListItems = jobs.map((job) => {
    return (
      <Grid item xs={viewType === "list" ? 12 : 4} key={job.id}>
        <JobListItem
          menuItemClick={props.menuItemClick}
          menuOptions={props.jobsMenuOptions}
          editJob={props.editJob}
          viewJobDashboard={getJobDetailsHandler}
          job={job}
          viewType={viewType}
          onDuplicate={handleDuplicateJob}
          onAutoDistribute={handleOnAutoDistribution}
          onSetNewCandidatesNotification={handleOnNewCandidateNotification}
          showStats={false}
        />
      </Grid>
    );
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.toolbar}>
          {showPagination}
        </Grid>
      </Grid>
      {error && <AxiosErrorHandling title={error.data.message} error={error} />}
      {isLoading ? (
        <Spinner open={isLoading} />
      ) : (
        <Grid container spacing={2}>
          {jobsListItems}
        </Grid>
      )}
      {showPagination}
      {duplicatedSuccessfully > -1 && (
        <Snackbar
          open={duplicatedSuccessfully > -1}
          autoHideDuration={6000}
          onClose={() => setDuplicatedSuccessfully(-1)}
        >
          <Alert
            onClose={() => setDuplicatedSuccessfully(-1)}
            severity="success"
          >
            המשרה שוכפלה בהצלחה
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};
export default withRouter(React.memo(JobsList));
