import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { Icon, Button, Box } from "@material-ui/core";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import FilterQuestionRelationsTag from "../../../../Jobs/AddJob/FilterQuestions/FilterQuestionRelationsTag";
import { AI_EXPERIENCES } from "../../../../../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const AIExperience = (props) => {
  const classes = useStyles();
  const [experienceName, setExperienceName] = useState("");
  const [yearsExperience, setYearsExperience] = useState(0);
  const [isManagementRole, setIsManagementRole] = useState(false);
  const [experienceAIObject, setExperienceAIObject] = useState([]);
  const [tags, setTags] = useState([]);
  const [updatedChip, setUpdatedChip] = useState(-1);

  useEffect(() => {
    if (props.aIValue.data && Object.keys(props.aIValue.data).length) {
      let aiTempObject = props.aIValue.data.map((aiItem) => {
        return (
          [...experienceAIObject],
          {
            key: aiItem.experienceId,
            experienceId: aiItem.experienceId,
            experienceName: aiItem.experienceName,
            yearsExperience: parseInt(aiItem.yearsExperience),
            isManagementRole: aiItem.isManagementRole,
          }
        );
      });

      setExperienceAIObject(aiTempObject);
      setTags(props.aIValue.tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const buildAIExperienceObject = (event) => {
    props.addAI({ data: experienceAIObject, tags: tags });
  };

  const handleYearsValue = (event) => {
    setYearsExperience(event.target.value);
  };

  const handleCheckEnableQ = (value) => {
    if (!value) setYearsExperience(0);
  };

  const handleExperienceChange = (event) => {
    setExperienceName(event.target.value);
    setIsManagementRole(event.target.value.includes("מנהל"));
  };

  /*
  Single expereince Object: 
  {
      experienceId: id,
      experienceName: string,
      yearsSkill: num,
      isManagementRole: boolean,      
  }
  */
  const handleAddSingleExperienceAI = () => {
    experienceAIObject === null
      ? setExperienceAIObject([
          {
            experienceId:
              experienceAIObject === null ? 1 : experienceAIObject.length + 1,
            experienceName,
            yearsExperience,
            isManagementRole,
          },
        ])
      : setExperienceAIObject([
          ...experienceAIObject,
          {
            experienceId: experienceAIObject.length + 1,
            experienceName,
            yearsExperience,
            isManagementRole,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = experienceAIObject.find(
      (chip) => chip.experienceId === updatedChip
    );

    //remove the selected chip from the array:
    const tempExperienceAIObject = experienceAIObject.filter(
      (ai) => ai.experienceId !== tempUpdatedChip.experienceId
    );

    //update the form:
    setExperienceAIObject([
      ...tempExperienceAIObject,
      {
        experienceId: tempUpdatedChip.experienceId,
        experienceName,
        yearsExperience: yearsExperience,
        isManagementRole: isManagementRole,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setYearsExperience(0);
    setIsManagementRole(false);
    setUpdatedChip(-1);
    setExperienceName("");
  };

  const handleIsManagementRole = (event) =>
    setIsManagementRole(event.target.checked);

  const handleRemoveExperience = (experienceId) => {
    //locate index id
    const newExperienceFQObject = experienceAIObject.filter(
      (exp) => exp.experienceId !== experienceId
    );
    setExperienceAIObject(newExperienceFQObject);
    clearForm();
  };

  const handleReadChip = (expId) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleExpObj = experienceAIObject.find(
        (exp) => exp.experienceId === expId
      );
      setYearsExperience(singleExpObj.yearsExperience);
      setExperienceName(singleExpObj.experienceName);
      setIsManagementRole(singleExpObj.isManagementRole);
      setUpdatedChip(expId);
    }
  };

  const displayChips =
    experienceAIObject !== null
      ? experienceAIObject.map((exp) => {
          return (
            <Chip
              key={exp.experienceId}
              icon={<FaceIcon />}
              label={exp.experienceName}
              clickable
              onClick={() => handleReadChip(exp.experienceId)}
              onDelete={() => handleRemoveExperience(exp.experienceId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== exp.experienceId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <StylesProvider jss={jss}>
      <Container maxWidth="lg" className={classes.root}>
        <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
        <Grid container>
          <Grid item style={{ width: "100%" }}>
            <Typography
              align="center"
              color="primary"
              variant="h5"
              className={classes.modalTitle}
            >
              {props.candidateFullName !== "undefined" &&
              props.candidateFullName.length > 1
                ? `הניסיון התעסוקתי של  ${props.candidateFullName}`
                : "ניסיון תעסוקתי"}
            </Typography>
            <Divider />
            <div>
              <Collapse in={experienceAIObject !== null}>
                <Typography variant="h5" className={classes.modalTitle}>
                  ניסיון תעסוקתי של המועמד
                </Typography>
                {displayChips}
                <Divider />
              </Collapse>
            </div>
            <Typography
              style={{ fontWeight: 700, marginBottom: 15 }}
              color="textPrimary"
              variant="subtitle1"
            >
              בחר או הזן תפקיד:
            </Typography>

            <TextField
              id="experienceId"
              label="ניסיון בתפקיד"
              variant="outlined"
              value={experienceName}
              onChange={handleExperienceChange}
              style={{ width: "100%" }}
            />

            <QuestionValueInput
              question="מספר שנות נסיון בתפקיד זה:"
              minYearsValue={handleYearsValue}
              changeToManagementRole
              value={yearsExperience}
              checkEnalbeQ={handleCheckEnableQ}
              isAMustCheck={handleIsManagementRole}
              isAmustValue={isManagementRole}
            />
            <Box my={2}>
              <FilterQuestionRelationsTag
                category={AI_EXPERIENCES}
                value={tags}
                onSelect={(value) => setTags(value)}
                readOnly
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                disabled={experienceName === ""}
                className={classes.button}
                endIcon={<Icon>add</Icon>}
                onClick={
                  updatedChip <= 0
                    ? handleAddSingleExperienceAI
                    : updateSelectedChip
                } //add new Object with all values of this Component
              >
                {updatedChip > 0 ? "עדכן מידע" : "הוסף מידע"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  experienceAIObject === null ||
                  experienceAIObject === "undefined" ||
                  Object.keys(experienceAIObject).length <= 0 ||
                  experienceAIObject.length <= 0
                }
                className={classes.button}
                endIcon={<Icon>done</Icon>}
                onClick={buildAIExperienceObject}
              >
                סיימתי
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StylesProvider>
  );
};
export default React.memo(AIExperience);
