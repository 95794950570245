import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, Avatar } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export default function CoordinatorsDropdown({
  selectedAuthor,
  onSelect,
  coordsList,
  label,
  variant = "outlined",
}) {
  const [open, setOpen] = React.useState(false);
  const loading = open && coordsList.length === 0;
  const theme = useTheme();

  const handleChange = (event, value) => {
    onSelect(value);
  };
  return (
    <Autocomplete
      id="users"
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={handleChange}
      getOptionSelected={(user, value) => user.id === value.id}
      getOptionLabel={(user) => user.display_name}
      renderOption={(user) => (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="User profile"
              src={user.img ? user.img : ""}
              style={{ marginLeft: theme.spacing(2) }}
            />
            <Typography variant="body1">{user.display_name}</Typography>
          </div>
        </React.Fragment>
      )}
      options={coordsList ? coordsList : []}
      loading={loading}
      value={
        selectedAuthor && coordsList && coordsList.length
          ? coordsList.find((user) => user.id === selectedAuthor)
          : null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? label : "לינק עבור הרכזת"}
          variant={variant ? variant : "variant"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
