import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, Paper, Grid, CircularProgress } from "@material-ui/core";
import { green, yellow, grey, blueGrey } from "@material-ui/core/colors";
import ThumbUpRoundedIcon from "@material-ui/icons/ThumbUpRounded";
import ThumbDownRoundedIcon from "@material-ui/icons/ThumbDownRounded";
import moment from "moment";
import { translateSkillLevel } from "../../config/jobsConfigData";
import {
  translateDegreeAndType,
  LanguageLevels,
} from "../../config/jobsConfigData";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";

function getNISSign() {
  return { __html: "&#8362;" };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTimelineItem-missingOppositeContent:before": {
      display: "none",
    },
    "& .MuiTimelineDot-root": {
      borderWidth: 1,
    },
  },
  paper: {
    padding: theme.spacing(2),
    "& .MuiSvgIcon-root": {
      color: grey[700],
    },
  },
  textHeader: {
    marginBottom: theme.spacing(2),
  },
  textTitle: {
    color: blueGrey[700],
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tlconent: {
    //minWidth: 350,
  },
  passIcon: {
    color: green[500],
  },
  failedIcon: {
    color: theme.palette.secondary.main,
  },
}));

function requiredQuestion() {
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <PriorityHighRoundedIcon />{" "}
      <Typography variant="caption">שאלת סף</Typography>
    </span>
  );
}

function generateQuestionReportLine(question, classes) {
  let text = "";
  let candAnswer = "";
  switch (question.question_key) {
    case "location":
      if (question.answer_value === "no") text = "המועמד סירב למיקום זה";
      else if (question.answer_value === "maybe")
        text = "המועמד אינו בטוח במיקום זה";
      else text = "המועמד אישר מיקום זה";

      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>מיקום</strong>
            {requiredQuestion()}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            {text}
          </Typography>
          <Typography variant="body1">
            <strong>נדרש:</strong> {question.location.city}
          </Typography>
        </React.Fragment>
      );
    case "availability":
      moment.locale("he-IL");
      //const days = moment(question.answer_value, "MM/DD/YYYY").days();

      const days = moment(question.answer_value).diff(moment(), "days") + 1;
      text =
        days < 2
          ? "המועמד יהיה זמין לעבודה מיידית"
          : `המועמד יהיה זמין לעבודה בעוד ${days} ימים`;
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>זמינות</strong>
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            {text}
          </Typography>
        </React.Fragment>
      );
    case "salary":
      if (question.salary_type.is_open) {
        if (question.pass_result === "passed")
          text = "ציפיות השכר של המועמד הינן בטווח המשרה";
        else text = "ציפיות השכר של המועמד חורגות מטווח השכר של משרה זו";
      } else {
        if (question.answer_value === "no")
          text = "המועמד לא אישר את תנאי השכר";
        else if (question.answer_value === "yes")
          text = "המועמד אישר את תנאי השכר";
        else text = "המועמד אינו בטוח לגבי תנאי השכר";
      }

      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>שכר</strong>
            {requiredQuestion()}
          </Typography>
          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {text}
          </Typography>
          <Typography variant="body1">
            <strong>נדרש:</strong> בין: {question.salary.minimum}{" "}
            <span dangerouslySetInnerHTML={getNISSign()}></span> ל{" "}
            {question.salary.maximum}{" "}
            <span dangerouslySetInnerHTML={getNISSign()}></span>
          </Typography>
          {question.salary_type.is_open ? (
            <Typography variant="body1">
              <strong>דרישות המועמד:</strong> {question.answer_value}{" "}
              <span dangerouslySetInnerHTML={getNISSign()}></span> /{" "}
              {question.salary_type.salary_by === "hour" ? "שעה" : "חודש"}
            </Typography>
          ) : null}
        </React.Fragment>
      );
    case "experience":
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>ניסיון מקצועי</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            בשאלת ניסיון מקצועי ב{" "}
            <strong>
              {
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "selectedProfession"
                ).profession_translate
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>נדרש:</strong>{" "}
              {
                question.filterQuestion.find((fq) => fq.fq_key === "minYears")
                  .fq_value
              }{" "}
              שנות ניסיון,
            </Typography>
            <Typography variant="body1" style={{ marginRight: 5 }}>
              <strong>תשובת המועמד:</strong> {question.answer_value} שנים
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "languages":
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>כישורי שפה</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            דרישות לשליטה בשפה ה{" "}
            <strong>
              {
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "languageName"
                ).fq_value
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>נדרשת שליטה ברמת:</strong>{" "}
              {
                LanguageLevels.find(
                  (ll) =>
                    ll.value ===
                    question.filterQuestion.find(
                      (fq) => fq.fq_key === "languageLevel"
                    ).fq_value
                ).label
              }{" "}
            </Typography>
            <Typography variant="body1" style={{ marginRight: 5 }}>
              <strong>תשובת המועמד:</strong>{" "}
              {
                LanguageLevels.find((ll) => ll.value === question.answer_value)
                  .label
              }
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "skills":
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>כישורים</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            בשאלת כישורים נדרשים ב{" "}
            <strong>
              {
                question.filterQuestion.find((fq) => fq.fq_key === "skillName")
                  .fq_value
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>רמת ידע נדרשת:</strong>{" "}
              {translateSkillLevel(
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "knowledgeLevel"
                ).fq_value
              )}{" "}
            </Typography>
            <Typography variant="body1" style={{ marginRight: 5 }}>
              <strong>תשובת המועמד:</strong>{" "}
              {translateSkillLevel(question.answer_value)}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "licenses":
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>רישיונות</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            נדרש רישיון ב{" "}
            <strong>
              {
                question.filterQuestion.find((fq) => fq.fq_key === "licenseOn")
                  .fq_value
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>נדרש :</strong>{" "}
              {
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "minYearsLicense"
                ).fq_value
              }{" "}
              שנות ניסיון
            </Typography>
            <Typography variant="body1" style={{ marginRight: 5 }}>
              <strong>תשובת המועמד:</strong> {question.answer_value} שנים
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "certificates":
      candAnswer = "יש תעודה מתאימה";
      if (question.answer_value === "no") candAnswer = "אין תעודה מתאימה";
      else if (question.answer_value === "maybe")
        candAnswer = "המועמד ענה בערך";
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>תעודות</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            נדרשה תעודה ב{" "}
            <strong>
              {
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "selectedCertificate"
                ).fq_value
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1" style={{ marginRight: 5 }}>
              <strong>תשובת המועמד:</strong> {candAnswer}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "academicDegrees":
      candAnswer = "יש תואר מתאים";
      if (question.answer_value === "no") candAnswer = "אין תואר מתאים";
      else if (question.answer_value === "maybe")
        candAnswer = "המועמד ענה בערך";
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>תארים אקדמיים</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            נדרש{" "}
            <strong>
              {translateDegreeAndType(
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "selectedDegree"
                ).fq_value
              )}
            </strong>{" "}
            {question.filterQuestion.find((fq) => fq.fq_key === "degreeType")
              .fq_value !== "" &&
              question.filterQuestion.find((fq) => fq.fq_key === "degreeType")
                .fq_value !== "general" &&
              ` מסוג ${
                question.filterQuestion.find((fq) => fq.fq_key === "degreeType")
                  .fq_value
              }`}
          </Typography>

          <Grid alignItems="center" container direction="row">
            <Typography variant="body1" style={{ marginRight: 5 }}>
              <strong>תשובת המועמד:</strong> {candAnswer}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "dynamics":
      let dynamicAnswer = "כן";
      if (question.answer_value === "no") dynamicAnswer = "לא";
      else if (question.answer_value === "maybe")
        dynamicAnswer = "המועמד ענה בערך";
      let passAnswerLabel = "כן";
      if (
        question.filterQuestion.length &&
        question.filterQuestion[0].dfq_pass_answer === "no"
      )
        passAnswerLabel = "לא";
      else passAnswerLabel = "כן";
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>שאלות דינאמיות</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            על השאלה:
            <strong>{question.filterQuestion[0].dfq_display_question}</strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>שבה תשובה עוברת היא :</strong> {passAnswerLabel}{" "}
            </Typography>
            <Typography variant="body1" style={{ marginRight: 5 }}>
              <strong>ענה המועמד:</strong> {dynamicAnswer}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    default:
      return null;
  }
}

function setIcon(result, theme) {
  if (result === "passed")
    return <ThumbUpRoundedIcon style={{ color: green[500] }} />;
  else if (result === "failed")
    return (
      <ThumbDownRoundedIcon style={{ color: theme.palette.primary.main }} />
    );
  else return <PriorityHighRoundedIcon style={{ color: yellow[500] }} />;
}

const CandidateJobAnswersReport = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const renderQuestions = props.questions.map((question) => {
    return (
      <div className={classes.item} key={question.id}>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined">
              {setIcon(question.pass_result, theme)}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className={classes.tlconent}>
            <Paper elevation={1} className={classes.paper}>
              {generateQuestionReportLine(question, classes)}
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </div>
    );
  });
  return (
    <React.Fragment>
      {props.isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <Timeline className={classes.root}>{renderQuestions}</Timeline>
      )}
    </React.Fragment>
  );
};
export default CandidateJobAnswersReport;
