import React, { useState } from "react";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import FilterQuestionDisplayPrevew from "../FilterQuestionDisplayPreview";
import { certificationPreviewQuestion } from "../../../../../config/jobsConfigData";
import FilterQuestionRelationsTag from "../FilterQuestionRelationsTag";
import { AI_CERTIFICATES } from "../../../../../config/constants";

const FQCertificate = ({
  onCancel,
  onCompletion,
  fqEdit = null,
  isActive,
  isActiveReadOnly,
}) => {
  const [selectedCertificate, setSelectedCertificate] = React.useState("");
  const [isCertificateIsMust, setIsCertificateIsMust] = React.useState(false);
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [tags, setTags] = useState([]);

  React.useEffect(() => {
    if (fqEdit !== null) {
      setSelectedCertificate(fqEdit.selectedCertificate);
      setIsCertificateIsMust(
        fqEdit.isCertificateAMust === 1 || fqEdit.isCertificateAMust
          ? true
          : false
      );
      setAdditionalInfo(fqEdit.addInfo);
      setTags(fqEdit.tags);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  const handleSelectedCertificate = (event) => {
    setSelectedCertificate(event.target.value);
  };

  const handleIsCertificateIsMust = (event) => {
    setIsCertificateIsMust(event.target.checked);
  };

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["selectedCertificate"] = selectedCertificate;
      existingObject["isCertificateAMust"] = isCertificateIsMust;
      existingObject["addInfo"] = additionalInfo;
      existingObject["tags"] = tags;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      selectedCertificate,
      isCertificateAMust: isCertificateIsMust,
      addInfo: additionalInfo,
      tags,
    });
  };

  /*
  Certificate Object:
  selectedCertificate = string,  
  isCertificateAMust = boolean
  */

  const clearForm = () => {
    setSelectedCertificate("");
    setIsCertificateIsMust(false);
    setAdditionalInfo("");
    setTags([]);
  };

  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          הוסף תעודה
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="certificate"
            label="תעודה"
            variant="filled"
            value={selectedCertificate}
            onChange={handleSelectedCertificate}
            fullWidth
            disabled={isActiveReadOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="additionalInfo"
            label="מידע נוסף למועמד"
            variant="filled"
            value={additionalInfo}
            onChange={(event) => setAdditionalInfo(event.target.value)}
            fullWidth
            helperText="לדוגמה: כולל הכשרות מקצועיות"
            disabled={isActiveReadOnly}
          />
        </Grid>
        <Grid item xs={6}>
          {isActive && (
            <FilterQuestionRelationsTag
              category={AI_CERTIFICATES}
              term={selectedCertificate}
              onSelect={(value) => setTags(value)}
              value={tags}
              readOnly={isActiveReadOnly}
            />
          )}
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={isActiveReadOnly}
            control={
              <Checkbox
                checked={isCertificateIsMust}
                onClick={handleIsCertificateIsMust}
                name="requiredFQ"
                color="primary"
              />
            }
            label="תנאי זה הוא חובה"
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FilterQuestionDisplayPrevew
              previewQuestion={certificationPreviewQuestion(
                selectedCertificate.trim(),
                additionalInfo
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CancelApproveButtons
            positiveCaption="סיימתי"
            negativeCaption="ביטול"
            positiveDisabled={
              selectedCertificate === "" || !tags || tags.length === 0
            }
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default FQCertificate;
