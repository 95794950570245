import React from "react";
import ConfigurationListsContext from "../../../context/configurationListsContext";
import axios from "axios";
import {
  AI_EXPERIENCES,
  AI_SKILLS,
  AI_LICENSES,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LANGUAGES,
  AI_SALARY_EXPECTATIONS,
} from "../../../config/constants";
import { RatingLabel } from "../../../config/candidatesConfigData";
import { MobilePrefixes, CitiesList } from "../../../config/jobsConfigData";
import Spinner from "../../../UI/Spinner/Spinner";
import MyModal from "../../../UI/MyModal/MyModal";
import AdditionalInformation from "./AdditionalInformation/AdditionalInformation";
import AIExperience from "./AdditionalInformation/AIExperience/AIExperience";
import AISkills from "./AdditionalInformation/AISkills/AISkills";
import AILicenses from "./AdditionalInformation/AILicenses/AILicenses";
import AIAcademicDegrees from "./AdditionalInformation/AIAcademiDegree/AIAcademiDegree";
import AICertificates from "./AdditionalInformation/AICertificates/AICertificates";
import AILanguages from "./AdditionalInformation/AILanguages/AILanguages";
import AISalaryExpectations from "./AdditionalInformation/AISalaryExpectations/AISalaryExpectations";
import Tags from "../../../components/Tags/Tags";
import SplitButton from "../../../components/SplitButton/SplitButton";
import SearchJob from "../../../components/SearchJob/SearchJob";
import AddCancelButton from "../../../components/AddCancelButtons/AddCancelButton";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Button,
  Box,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(2),
    },
    "& .MuiButton-endIcon": {
      display: "inherit",
      marginLeft: 8,
      marginRight: -4,
    },

    padding: theme.spacing(1),
  },
  typography: {
    direction: "rtl",
  },
  layout: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  generalDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "30%",
  },
  cancel: {
    marginLeft: theme.spacing(1),
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
}));

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const AddNewCandidate = (props) => {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [selectedFamilyStatus, setSelectedFamilyStatus] = React.useState([]);
  const [candidateEmail, setCandidateEmail] = React.useState("");
  const [yearOfBirthList, setYearOfBirthList] = React.useState([]);
  const [externalId, setExternalId] = React.useState("");
  const [selectedYearOfBirth, setSelectedYearOfBirth] = React.useState("");
  const [selectedProfessions, setSelectedProfessions] = React.useState([]);
  const [selectedGender, setSelectedGender] = React.useState("female");
  const [selectedArea, setSelectedArea] = React.useState([]);
  const [ratingValue, setRatingValue] = React.useState(-1);
  const [mobilePrefix, setMobilePrefix] = React.useState("050");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [candidateDescription, setCandidateDescription] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [source, setSource] = React.useState("");
  const [city, setCity] = React.useState("");
  const [candidateTags, setCandidateTags] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [ratingValueHover, setRatingValueHover] = React.useState(-1);
  const [linkToJob, setLinkToJob] = React.useState(-1);
  const [showLinkCandidateToJob, setShowLinkCandidateToJob] = React.useState(
    false
  );
  const [
    candidateIdentifierNumber,
    setCandidateIdentifierNumber,
  ] = React.useState("");
  //const [candidateId, setCandidateId] = React.useState(-1); //always -1 in add mode, or job id in edit mode
  const [isEditMode, setIsEditMode] = React.useState(false); //if this is true, we are in job edit mode
  const [
    additionalInformationModalType,
    setAdditionalInformationModalType,
  ] = React.useState("");
  const [aiExperienceObjectData, setAIExperienceObjectData] = React.useState(
    []
  );
  const [aiSkillsObjectData, setAISkillsObjectData] = React.useState([]);
  const [aiLicensesObjectData, setAILicensesObjectData] = React.useState([]);
  const [
    aiAcademicDegreesObjectData,
    setAIAcademicDegreesObjectData,
  ] = React.useState([]);
  const [
    aiCertificatesObjectData,
    setAICertificatesObjectData,
  ] = React.useState([]);
  const [aiLanguagesObjectData, setAILangagesObjectData] = React.useState([]);
  const [
    aiSalaryExpectationsObjectData,
    setAISalaryExpectationsObjectData,
  ] = React.useState([]);
  const theme = useTheme();
  const areasListContext = React.useContext(ConfigurationListsContext)
    .areasList;
  React.useEffect(() => {
    if (props.editMode > 0) {
      setIsEditMode(true);
      //setCandidateId(props.editMode);
      setAddress(props.candidateData[0].acf.address);
      setCity(props.candidateData[0].acf.city);
      setSource(props.candidateData[0].acf.source);
      setCandidateEmail(props.candidateData[0].acf.email);
      setExternalId(props.candidateData[0].acf.externalNumber);
      setSelectedFamilyStatus(
        props.familyStatusList.filter(
          (fid) => fid.id === props.candidateData[0].acf.family_status
        )[0]
      );
      setSelectedGender(props.candidateData[0].acf.gender);
      setCandidateIdentifierNumber(props.candidateData[0].acf.identifierNumber);
      if (props.candidateData[0].acf.mobile) {
        const prefixNumber = props.candidateData[0].acf.mobile.split("-");
        setMobileNumber(prefixNumber[1]);
        setMobilePrefix(prefixNumber[0]);
      }

      setCandidateTags(props.candidateData[0].tags.map((tag) => tag.tag_name));
      setRatingValue(parseInt(props.candidateData[0].acf.rating));
      //source is missing:

      let areas =
        props.candidateData[0].acf.workArea &&
        props.candidateData[0].acf.workArea.includes(",")
          ? props.candidateData[0].acf.workArea.split(",")
          : props.candidateData[0].acf.workArea;
      //if not array - convert to array
      if (typeof areas === "string") {
        areas === "" ? (areas = []) : (areas = [areas]);
      }
      //props.candidateData[0].acf.workArea.split(",");

      if (areas) {
        const localAreas = areas.map((area) =>
          areasListContext.find((al) => parseInt(al.id) === parseInt(area))
        );
        setSelectedArea(localAreas);
      }

      setCandidateDescription(props.candidateData[0].description);
      setSelectedYearOfBirth(parseInt(props.candidateData[0].acf.yearOfBirth));
      setFirstName(
        props.candidateData[0].name.substring(
          0,
          props.candidateData[0].name.indexOf(" ")
        )
      );

      setLastName(
        props.candidateData[0].name.substring(
          props.candidateData[0].name.indexOf(" ") + 1,
          props.candidateData[0].name.length
        )
      );

      setSelectedProfessions(props.candidateData[0].professions);
      //missing profile Image upload
      //Get Additional Information:
      if (
        props.candidateData[0].candidatesAdditionalInformationUpdatedData &&
        props.candidateData[0].candidatesAdditionalInformationUpdatedData.length
      ) {
        const {
          candidatesAdditionalInformationUpdatedData,
        } = props.candidateData[0];
        candidatesAdditionalInformationUpdatedData.forEach((aiData) => {
          const keys = Object.keys(aiData)[0];
          const values = Object.values(aiData)[0];
          switch (keys) {
            case AI_EXPERIENCES: {
              let localAIObject = Object.values(values.typeBySubType).map(
                (exp) => {
                  //build Experince Object:
                  let sId = 0;
                  let sName = "";
                  let years = 0;
                  let isManagementRole = false;
                  let tags = [];
                  exp.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "experienceName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "yearsExperience":
                        years = parseInt(singleExp.ai_value);
                        break;
                      case "isManagementRole":
                        isManagementRole =
                          parseInt(singleExp.ai_value) === 1 ? true : false;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    experienceId: sId,
                    experienceName: sName,
                    yearsExperience: years,
                    isManagementRole: isManagementRole,
                    tags,
                  };
                }
              );

              const localTags = localAIObject.filter(
                (aiItem) => aiItem.experienceId === "-1"
              );

              setAIExperienceObjectData({
                data: localAIObject.filter(
                  (aiItem) => aiItem.experienceId !== "-1"
                ),
                tags:
                  localTags && localTags.length > 0 ? localTags[0].tags : [],
              });
              break;
            }
            case AI_SKILLS: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let years = 0;
                  let level = false;
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "skillName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "yearsSkill":
                        years = parseInt(singleExp.ai_value);
                        break;
                      case "knowledgeLevel":
                        level = singleExp.ai_value;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    skillId: sId,
                    skillName: sName,
                    yearsSkill: years,
                    knowledgeLevel: level,
                    tags,
                  };
                }
              );
              const localTags = localAIObject.filter(
                (aiItem) => aiItem.skillId === "-1"
              );

              setAISkillsObjectData({
                data: localAIObject.filter((aiItem) => aiItem.skillId !== "-1"),
                tags:
                  localTags && localTags.length > 0 ? localTags[0].tags : [],
              });
              break;
            }
            case AI_ACADEMIC_DGREE: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let type = "";
                  let dOn = "";
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "degreeName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "degreeType":
                        type = singleExp.ai_value;
                        break;
                      case "degreeOn":
                        dOn = singleExp.ai_value;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    degreeId: sId,
                    degreeName: sName,
                    degreeType: type,
                    degreeOn: dOn,
                    tags,
                  };
                }
              );
              const localTags = localAIObject.filter(
                (aiItem) => aiItem.degreeId === "-1"
              );

              setAIAcademicDegreesObjectData({
                data: localAIObject.filter(
                  (aiItem) => aiItem.degreeId !== "-1"
                ),
                tags:
                  localTags && localTags.length > 0 ? localTags[0].tags : [],
              });

              break;
            }
            case AI_CERTIFICATES: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "certificateName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    certificateId: sId,
                    certificateName: sName,
                    tags,
                  };
                }
              );
              const localTags = localAIObject.filter(
                (aiItem) => aiItem.certificateId === "-1"
              );

              setAICertificatesObjectData({
                data: localAIObject.filter(
                  (aiItem) => aiItem.certificateId !== "-1"
                ),
                tags:
                  localTags && localTags.length > 0 ? localTags[0].tags : [],
              });
              break;
            }

            case AI_LICENSES: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let years = 0;
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "licenseName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "licenseYears":
                        years = parseInt(singleExp.ai_value);
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    licenseId: sId,
                    licenseName: sName,
                    licenseYears: years,
                    tags,
                  };
                }
              );
              const localTags = localAIObject.filter(
                (aiItem) => aiItem.licenseId === "-1"
              );

              setAILicensesObjectData({
                data: localAIObject.filter(
                  (aiItem) => aiItem.licenseId !== "-1"
                ),
                tags:
                  localTags && localTags.length > 0 ? localTags[0].tags : [],
              });
              break;
            }
            case AI_LANGUAGES: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let level = false;
                  let tags = [];

                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "languageName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "languageLevel":
                        level = singleExp.ai_value;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    languageId: sId,
                    languageName: sName,
                    languageLevel: level,
                    tags,
                  };
                }
              );
              const localTags = localAIObject.filter(
                (aiItem) => aiItem.languageId === "-1"
              );

              setAILangagesObjectData({
                data: localAIObject.filter(
                  (aiItem) => aiItem.languageId !== "-1"
                ),
                tags:
                  localTags && localTags.length > 0 ? localTags[0].tags : [],
              });
              break;
            }
            case AI_SALARY_EXPECTATIONS: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sRole = "";
                  let sMeasure = "";
                  let min = 0;
                  let max = 0;
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "seForRole":
                        sRole = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "seSalaryMinimum":
                        min = parseFloat(singleExp.ai_value);
                        break;
                      case "seSalaryMaximum":
                        max = parseFloat(singleExp.ai_value);
                        break;
                      case "seMeasure":
                        sMeasure = singleExp.ai_value;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    seId: sId,
                    seForRole: sRole,
                    seIsRange: min !== max,
                    seRange: min === max ? min : [min, max],
                    seMeasure: sMeasure,
                    tags,
                  };
                }
              );
              const localTags = localAIObject.filter(
                (aiItem) => aiItem.seId === "-1"
              );

              setAISalaryExpectationsObjectData({
                data: localAIObject.filter((aiItem) => aiItem.seId !== "-1"),
                tags:
                  localTags && localTags.length > 0 ? localTags[0].tags : [],
              });
              break;
            }
            default:
              break;
          }
          //
        });
      }
    }

    // in case of edit mode - get the data from the server:
  }, [
    props.editMode,
    props.candidateAIData,
    areasListContext,
    props.familyStatusList,
    props.candidateData,
  ]);

  React.useEffect(() => {
    const uptoyear = new Date().getFullYear() - 10;
    let years = [];
    for (let i = 1950; i <= uptoyear; i++) {
      years = [...years, i];
    }
    setYearOfBirthList(years);
  }, []);

  const handleAdditionalInformationClick = (event, type) => {
    setAdditionalInformationModalType(type);
  };

  const handleAddNewtag = (tag) => {
    setCandidateTags([...candidateTags, tag.trim()]);
  };

  const handleRemoveTag = (tag) => {
    setCandidateTags(candidateTags.filter((t) => t !== tag));
  };

  const handleAdditionalInformationObject = (value) => {
    switch (additionalInformationModalType) {
      case AI_EXPERIENCES:
        setAIExperienceObjectData(value);
        break;
      case AI_SKILLS:
        setAISkillsObjectData(value);
        break;
      case AI_LICENSES:
        setAILicensesObjectData(value);
        break;
      case AI_ACADEMIC_DGREE:
        setAIAcademicDegreesObjectData(value);
        break;
      case AI_CERTIFICATES:
        setAICertificatesObjectData(value);
        break;
      case AI_LANGUAGES:
        setAILangagesObjectData(value);
        break;
      case AI_SALARY_EXPECTATIONS:
        setAISalaryExpectationsObjectData(value);
        break;
      default:
        break;
    }
    setAdditionalInformationModalType("");
  };

  const handleGroupSelect = (event, value) => {
    if (value.value === "doneAndLink") {
      setShowLinkCandidateToJob(true);
    } else {
      postNewCandidate();
    }
  };

  const postNewCandidate = async () => {
    setIsLoading(true);
    const candidateData = {
      title: `${firstName} ${lastName}`,
      fields: {
        first_name: firstName,
        last_name: lastName,
        email: candidateEmail,
        work_in_area: selectedArea.map((sa) => sa.id).join(", "),
        candidate_external_number: externalId,
        identifier_number: candidateIdentifierNumber,
        year_of_birth: selectedYearOfBirth,
        address: address,
        family_status: selectedFamilyStatus ? selectedFamilyStatus.id : "",
        telephone_main: `${mobilePrefix}-${mobileNumber}`,
        is_active: "active",
        city,
        source,
        gender: selectedGender,
        rating: ratingValue,
      },
      "jobs-sectors": selectedProfessions.map((prof) => prof.id).join(", "),
      content: candidateDescription,
      status: "publish",
    };
    const addOrUpdateUrl =
      props.editMode > 1
        ? `/wp-json/wp/v2/candidates/${props.editMode}`
        : "/wp-json/wp/v2/candidates";
    axios
      .post(addOrUpdateUrl, candidateData)
      .then((candidateRes) => {
        return axios
          .post("/wp-json/api/v2/updateCandidateAdditionalInformation", {
            candidateId: parseInt(candidateRes.data.id),
            candidateTags,
            aiExperienceObjectData,
            aiCertificatesObjectData,
            aiSkillsObjectData,
            aiLicensesObjectData,
            aiAcademicDegreesObjectData,
            aiLanguagesObjectData,
            aiSalaryExpectationsObjectData,
          })
          .then((rsAfter) => {
            if (linkToJob > -1) {
              return axios
                .post(`/wp-json/api/v2/link_multiples/${linkToJob}`, {
                  candidateIds: [parseInt(candidateRes.data.id)],
                  authorId: parseInt(localStorage.getItem("userId")),
                })
                .then(() => props.addedSuccessfully());
            }
            props.addedSuccessfully();
          })
          .catch((err) => {
            props.closeOnError(err, err.response);
          });
      })
      .catch((err) => {
        props.closeOnError(err, err.response);
      });
  };

  function filterQuestionModalDetect() {
    switch (additionalInformationModalType) {
      case AI_EXPERIENCES:
        return (
          <AIExperience
            professionsList={props.professionsList}
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiExperienceObjectData}
          />
        );
      case AI_SKILLS: {
        return (
          <AISkills
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiSkillsObjectData}
          />
        );
      }
      case AI_LICENSES: {
        return (
          <AILicenses
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiLicensesObjectData}
          />
        );
      }
      case AI_ACADEMIC_DGREE: {
        return (
          <AIAcademicDegrees
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiAcademicDegreesObjectData}
          />
        );
      }
      case AI_CERTIFICATES: {
        return (
          <AICertificates
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiCertificatesObjectData}
          />
        );
      }
      case AI_LANGUAGES: {
        return (
          <AILanguages
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiLanguagesObjectData}
          />
        );
      }
      case AI_SALARY_EXPECTATIONS: {
        return (
          <AISalaryExpectations
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiSalaryExpectationsObjectData}
          />
        );
      }

      default:
        break;
    }
  }

  return (
    <StylesProvider jss={jss}>
      {isLoading ? (
        <Spinner open={isLoading} />
      ) : (
        <React.Fragment>
          <AdditionalInformation
            clicked={handleAdditionalInformationClick}
            experienceCount={
              aiExperienceObjectData.data
                ? Object.keys(aiExperienceObjectData.data).length
                : 0
            }
            skillsCount={
              aiSkillsObjectData.data
                ? Object.keys(aiSkillsObjectData.data).length
                : 0
            }
            licensesCount={
              aiLicensesObjectData.data
                ? Object.keys(aiLicensesObjectData.data).length
                : 0
            }
            academicDegreeCount={
              aiAcademicDegreesObjectData.data
                ? Object.keys(aiAcademicDegreesObjectData.data).length
                : 0
            }
            certificateCount={
              aiCertificatesObjectData.data
                ? Object.keys(aiCertificatesObjectData.data).length
                : 0
            }
            languagesCount={
              aiLanguagesObjectData.data
                ? Object.keys(aiLanguagesObjectData.data).length
                : 0
            }
            salaryExpectationsCount={
              aiSalaryExpectationsObjectData.data
                ? Object.keys(aiSalaryExpectationsObjectData.data).length
                : 0
            }
          />
          <div dir="rtl" className={clsx(classes.root, classes.layout)}>
            <Grid container spacing={3}>
              <Grid item lg={12} align="right">
                <Paper className={classes.paper}>
                  <div className={classes.generalDetails}>
                    <Typography
                      align="right"
                      variant="h5"
                      color="primary"
                      className={classes.typography}
                    >
                      {isEditMode
                        ? `עדכון מידע על ${firstName} ${lastName}`
                        : "הוספת מועמד"}
                    </Typography>
                    <div
                      className={classes.rating}
                      style={{ width: "30%", justifyContent: "flex-end" }}
                    >
                      {ratingValue !== null && (
                        <Box ml={2} style={{ marginLeft: 16 }}>
                          {
                            RatingLabel[
                              ratingValueHover !== -1
                                ? ratingValueHover
                                : ratingValue
                            ]
                          }
                        </Box>
                      )}
                      <Rating
                        name="hover-feedback"
                        value={ratingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          setRatingValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setRatingValueHover(newHover);
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.layout}>
                    <Tags
                      AddTags={handleAddNewtag}
                      tags={candidateTags}
                      onChipDelete={handleRemoveTag}
                    />
                  </div>
                  <div
                    className={classes.layout}
                    style={{ position: "relative" }}
                  >
                    <TextField
                      required
                      label="שם פרטי"
                      id="firstName"
                      margin="normal"
                      onBlur={(event) =>
                        setFirstName(event.target.value.trim())
                      }
                      variant="outlined"
                      value={firstName}
                      style={{ width: "25%" }}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                    <TextField
                      required
                      label="שם משפחה"
                      id="lastName"
                      onBlur={(event) => setLastName(event.target.value.trim())}
                      margin="normal"
                      variant="outlined"
                      style={{ width: "25%", marginRight: theme.spacing(2) }}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                      style={{ position: "absolute", left: 0 }}
                      label="מספר מועמד חיצוני"
                      id="externalID"
                      margin="normal"
                      size="small"
                      variant="filled"
                      value={externalId}
                      onChange={(e) => setExternalId(e.target.value)}
                    />
                  </div>
                  <div className={classes.generalDetails}>
                    <TextField
                      label="תעודת זהות"
                      id="candidateID"
                      margin="normal"
                      variant="outlined"
                      value={candidateIdentifierNumber}
                      style={{ width: "30%" }}
                      onChange={(e) =>
                        setCandidateIdentifierNumber(e.target.value)
                      }
                    />

                    <Autocomplete
                      options={props.familyStatusList}
                      id="familyStatus"
                      style={{ width: "30%" }}
                      getOptionLabel={(option) => option.value}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      value={
                        selectedFamilyStatus &&
                        selectedFamilyStatus !== null &&
                        Object.keys(selectedFamilyStatus).length
                          ? props.familyStatusList.filter(
                              (item) => selectedFamilyStatus.id === item.id
                            )[0]
                          : null
                      }
                      onChange={(e, value) => {
                        setSelectedFamilyStatus(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          label="מצב משפחתי"
                          variant="outlined"
                        />
                      )}
                    />
                    <Autocomplete
                      options={yearOfBirthList}
                      id="yearOfBirth"
                      style={{ width: "30%" }}
                      getOptionLabel={(option) => option.toString()}
                      value={selectedYearOfBirth}
                      onChange={(e, value) => {
                        setSelectedYearOfBirth(value);
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          label="שנת לידה"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className={classes.generalDetails}>
                    <FormControl
                      variant="outlined"
                      style={{
                        width: "30%",
                        marginTop: theme.spacing(2),
                        textAlign: "left",
                      }}
                    >
                      <InputLabel htmlFor="outlined-adornment-email">
                        אימייל
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-email"
                        value={candidateEmail}
                        onChange={(e) => setCandidateEmail(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">@</InputAdornment>
                        }
                        labelWidth={60}
                      />
                    </FormControl>

                    <TextField
                      label="כתובת מגורים"
                      id="candidateAddressId"
                      margin="normal"
                      variant="outlined"
                      value={address ? address : ""}
                      style={{ width: "30%" }}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="tags-cities"
                      options={CitiesList.map((city) => city.name).sort(
                        (a, b) => a.name > b.name
                      )}
                      // getOptionLabel={(city) => {
                      //   console.log(city);
                      // }}
                      style={{ width: "30%" }}
                      onChange={(e, value) => setCity(value)}
                      value={city !== "" ? city : ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="יישוב"
                          placeholder="יישוב/עיר"
                        />
                      )}
                    />
                  </div>
                  <div className={classes.layout}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        margin="normal"
                        label="מספר נייד"
                        variant="outlined"
                        helperText="מספר הנייד ללא קידומת (7 ספרות)"
                        onChange={(e) => setMobileNumber(e.target.value)}
                        value={mobileNumber}
                      />
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        style={{
                          width: 80,
                          marginTop: theme.spacing(2),
                          marginRight: theme.spacing(1),
                          marginLeft: theme.spacing(2),
                        }}
                      >
                        <InputLabel id="demo-customized-select-label">
                          קידומת
                        </InputLabel>
                        <Select
                          labelId="mobileprefix"
                          id="mobileprefixlabel"
                          value={mobilePrefix}
                          onChange={(e) => setMobilePrefix(e.target.value)}
                          label="קידומת"
                        >
                          {MobilePrefixes.map((pref) => (
                            <MenuItem key={pref.id} value={pref.id}>
                              {pref.id}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <ConfigurationListsContext.Consumer>
                      {(context) => (
                        <Autocomplete
                          options={context.areasList.sort(
                            (a, b) => a.parent_id > b.parent_id
                          )}
                          getOptionLabel={(area) => area.value}
                          limitTags={2}
                          multiple
                          id="area"
                          style={{ flex: 1 }}
                          value={
                            selectedArea.length > 0
                              ? selectedArea.map(
                                  (sp) =>
                                    context.areasList.filter(
                                      (item) => item.id === sp.id
                                    )[0]
                                )
                              : []
                          }
                          onChange={(e, value) => setSelectedArea(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              value={
                                selectedArea !== null
                                  ? Object.values(selectedArea)
                                  : null
                              }
                              label="אזור מותאם לעבודה"
                              helperText="האזור בו מתקיימת העבודה"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </ConfigurationListsContext.Consumer>

                    <ConfigurationListsContext.Consumer>
                      {(context) => (
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          limitTags={2}
                          options={context.professionsList.sort(
                            (a, b) => a.parent_id > b.parent_id
                          )}
                          //groupBy={(options) => options.parent_id}
                          getOptionLabel={(prof) => prof.name}
                          style={{ marginRight: theme.spacing(2), flex: 1 }}
                          onChange={(e, value) => setSelectedProfessions(value)}
                          //value={Object.values(selectedProfessions)}
                          value={selectedProfessions.map(
                            (sp) =>
                              context.professionsList.filter(
                                (item) => item.id === sp.id
                              )[0]
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="בחר מקצועות רלוונטיים"
                              placeholder="מקצועות"
                            />
                          )}
                        />
                      )}
                    </ConfigurationListsContext.Consumer>
                  </div>
                  <div className={classes.layout}>
                    <div style={{ width: "15%" }}>
                      <FormControl
                        component="fieldset"
                        style={{ margin: theme.spacing(2) }}
                        variant="outlined"
                      >
                        <FormLabel component="legend">מגדר</FormLabel>
                        <RadioGroup
                          aria-label="מגדר"
                          name="gender1"
                          value={selectedGender}
                          onChange={(e) => setSelectedGender(e.target.value)}
                        >
                          <FormControlLabel
                            value="female"
                            control={<Radio color="primary" />}
                            label="נקבה"
                          />
                          <FormControlLabel
                            value="male"
                            control={<Radio color="primary" />}
                            label="זכר"
                          />
                          <FormControlLabel
                            value="other"
                            control={<Radio color="primary" />}
                            label="אחר"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <TextField
                      id="job-requirements"
                      label="מידע חופשי על המועמד"
                      multiline
                      style={{ flex: 1 }}
                      onChange={(e) => setCandidateDescription(e.target.value)}
                      rows={5}
                      InputLabelProps={{
                        shrink: candidateDescription !== "",
                      }}
                      defaultValue={candidateDescription}
                      variant="outlined"
                    />
                  </div>
                  <div style={{ marginTop: theme.spacing(2) }}>
                    <SplitButton
                      onSelect={handleGroupSelect}
                      options={[
                        {
                          label: isEditMode ? "עדכן מועמד " : "הוסף מועמד",
                          value: "done",
                        },
                        {
                          label: isEditMode ? "עדכן ושייך " : "הוסף ושייך",
                          value: "doneAndLink",
                        },
                      ]}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.cancel}
                      onClick={props.cancelNewCandidate}
                    >
                      בטל
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <MyModal
              openModal={additionalInformationModalType !== ""}
              closeModal={() => setAdditionalInformationModalType("")}
            >
              {filterQuestionModalDetect()}
            </MyModal>
            <MyModal
              openModal={showLinkCandidateToJob}
              closeModal={() => setShowLinkCandidateToJob(false)}
            >
              <React.Fragment>
                <SearchJob
                  searchMode
                  linkToJobId={(id) => setLinkToJob(id)}
                  className={classes.topMargin}
                />
                <div>
                  <AddCancelButton
                    addCaption="שייך מועמד"
                    cancelCaption="בטל"
                    cancelClick={() => {
                      setLinkToJob(-1);
                      setShowLinkCandidateToJob(false);
                    }}
                    addClick={() => {
                      setShowLinkCandidateToJob(false);
                      postNewCandidate();
                    }}
                  />
                </div>
              </React.Fragment>
            </MyModal>
          </div>
        </React.Fragment>
      )}
    </StylesProvider>
  );
};
export default AddNewCandidate;
