import { Box, TextField, useTheme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import GeneralSelection from "../../components/GeneralSelection/GeneralSelection";
import { AI_EXPERIENCES } from "../../config/constants";
import ConfigurationListsContext from "../../context/configurationListsContext";
import FilterQuestionRelationsTag from "../Jobs/AddJob/FilterQuestions/FilterQuestionRelationsTag";

const SelectExploreFQ = ({
  onSelect,
  fqLabel,
  fqValueLabel,
  valueType = "number",
}) => {
  const [fqType, setFqType] = useState("");
  const [fqValue, setFqValue] = useState(valueType === "number" ? 1 : "");
  const [tags, setTags] = useState([]);
  const context = useContext(ConfigurationListsContext);
  const theme = useTheme();

  const handleFQChange = (value) => {
    setFqType(value.name);
  };

  const handleValueChange = (value) => {
    setFqValue(value);
  };

  useEffect(() => {
    onSelect({
      type: fqType,
      value: fqValue,
      tags,
    });
  }, [fqType, fqValue, tags]);

  const handleSetTags = (value) => {
    setTags(value);
    onSelect({
      type: fqType,
      value: fqValue,
      tags: value,
    });
  };

  return (
    <Box display={"flex"} justifyContent="flex-start" alignItems={"flex-start"}>
      <Autocomplete
        id="experience"
        options={context.professionsList}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => handleFQChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label={fqLabel}
            margin="normal"
          />
        )}
        style={{ flex: 1, marginLeft: theme.spacing(2) }}
      />
      <FilterQuestionRelationsTag
        category={AI_EXPERIENCES}
        onSelect={handleSetTags}
        styling={{
          flex: 1,
        }}
      />
      {valueType === "number" && (
        <TextField
          type="number"
          variant="filled"
          label={fqValueLabel}
          onChange={(event) => handleValueChange(event.target.value)}
          defaultValue={1}
          margin="normal"
          style={{
            marginRight: theme.spacing(2),
            flex: 1,
          }}
          InputProps={{ inputProps: { min: 1, max: 5 } }}
        />
      )}
    </Box>
  );
};
export default SelectExploreFQ;

export const FQFreeTextLevel = ({
  onSelect,
  fqLabel,
  fqValueLabel,
  levelList,
  category,
}) => {
  const [fqType, setFqType] = useState("");
  const [fqValue, setFqValue] = useState("");
  const [tags, setTags] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    onSelect({
      type: fqType,
      value: fqValue,
      tags,
    });
  }, [fqType, fqValue]);

  const handleSetTags = (value) => {
    setTags(value);
    onSelect({
      type: fqType,
      value: fqValue,
      tags: value,
    });
  };

  return (
    <Box display={"flex"} justifyContent="flex-start" alignItems={"flex-start"}>
      <TextField
        variant="filled"
        label={fqLabel}
        onChange={(event) => setFqType(event.target.value)}
        margin="normal"
        InputProps={{ inputProps: { min: 1, max: 5 } }}
      />
      <FilterQuestionRelationsTag
        category={category}
        onSelect={handleSetTags}
        styling={{
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          width: "100%",
        }}
      />
      <GeneralSelection
        onSelect={(value) => setFqValue(value)}
        listOptions={levelList}
        label={fqValueLabel}
        style={{ marginLeft: theme.spacing(2) }}
      />
    </Box>
  );
};
