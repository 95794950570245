import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Box, Typography, Grow } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .MuiDialog-paper": {
      margin: 0,
      padding: theme.spacing(2),
      background: theme.palette.background.default,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow in={true} ref={ref} {...props} />;
});

export default function CustomDialog({
  fullWidth,
  maxWidth,
  open,
  close,
  title,
  ...props
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      className={classes.root}
    >
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="secondary"
          endIcon={<CloseRounded color="secondary" />}
          onClick={close}
        >
          סגור
        </Button>
      </Box>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: 700 }}
        paragraph
        align="center"
      >
        {title}
      </Typography>
      <Box>{props.children}</Box>
    </Dialog>
  );
}
