import axios from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../reactQuery/rqConstants";

const getProfessions = async () => {
  try {
    const request = await axios.get("/wp-json/api/v2/all_terms/jobs-sectors");
    return request.data;
  } catch (err) {
    return err;
  }
};

const useProfessions = () => {
  return useQuery(queryKeys.categories, getProfessions, {
    cacheTime: 600000,
    retry: 2,
    staleTime: 300000,
  });
};
export default useProfessions;
