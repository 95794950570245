import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { Icon, Button, Box } from "@material-ui/core";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  FormControlLabel,
  Radio,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import {
  AI_ACADEMIC_DGREE,
  DEGREE_TYPES,
} from "../../../../../config/constants";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import FilterQuestionRelationsTag from "../../../../Jobs/AddJob/FilterQuestions/FilterQuestionRelationsTag";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
  formControl: {
    minWidth: 300,
    marginBottom: theme.spacing(2),
  },
}));

//return a label for the chip
function academicDegreeLabel(degree, type) {
  switch (degree) {
    case "firstDegree":
      return type !== "general" ? `תואר ראשון - ${type}` : "תואר ראשון";
    case "secondDegree":
      return type !== "general" ? `תואר שני - ${type}` : "תואר שני";
    case "doctor":
      return type !== "general" ? `תואר דוקטור - ${type}` : "תואר דוקטור";
    case "engineer":
      return `תואר מההדס - ${type}`;
    case "practicalEngineer":
      return `תואר הנדסאי`;
    case "practicalTechnition":
      return `תואר טכנאי`;
    default:
      break;
  }
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const AIAcademicDegree = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedDegree, setSelectedDegree] = useState("");
  const [degreeType, setDegreeType] = useState("");
  const [degreeOn, setDegreeOn] = useState("");
  const [tags, setTags] = useState([]);
  const [academicDegreesAIObject, setAcademicDegreesAIObject] = useState([]);
  const [updatedChip, setUpdatedChip] = useState(-1);

  useEffect(() => {
    if (props.aIValue.data && Object.keys(props.aIValue.data).length) {
      const aiTempObject = props.aIValue.data.map((aiItem) => {
        return (
          [...academicDegreesAIObject],
          {
            key: aiItem.degreeId,
            degreeId: aiItem.degreeId,
            degreeName: aiItem.degreeName,
            degreeType: aiItem.degreeType,
            degreeOn: aiItem.degreeOn,
          }
        );
      });
      setAcademicDegreesAIObject(aiTempObject);
      setTags(props.aIValue.tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [props.aIValue]);

  const buildAIAcademicDegreesObject = () => {
    props.addAI({ data: academicDegreesAIObject, tags });
  };

  const handleAddSingleAcademicDegreeAI = () => {
    academicDegreesAIObject === null
      ? setAcademicDegreesAIObject([
          {
            degreeId:
              academicDegreesAIObject === null
                ? 1
                : academicDegreesAIObject.length + 1,
            degreeName: selectedDegree,
            degreeType,
            degreeOn,
          },
        ])
      : setAcademicDegreesAIObject([
          ...academicDegreesAIObject,
          {
            degreeId: academicDegreesAIObject.length + 1,
            degreeName: selectedDegree,
            degreeType,
            degreeOn,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = academicDegreesAIObject.find(
      (chip) => chip.degreeId === updatedChip
    );

    //remove the selected chip from the array:
    const tempDegreeAIObject = academicDegreesAIObject.filter(
      (ai) => ai.degreeId !== tempUpdatedChip.degreeId
    );

    //update the form:
    setAcademicDegreesAIObject([
      ...tempDegreeAIObject,
      {
        degreeId: tempUpdatedChip.degreeId,
        degreeName: selectedDegree,
        degreeType,
        degreeOn,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setSelectedDegree("");
    setDegreeType("");
    setDegreeOn("");
    setUpdatedChip(-1);
  };

  const handleRemoveDegree = (degreeId) => {
    //locate index id
    const newDegreeAIObject = academicDegreesAIObject.filter(
      (ad) => ad.degreeId !== degreeId
    );
    setAcademicDegreesAIObject(newDegreeAIObject);
    clearForm();
  };

  const handleDegreeTypeChange = (event) => {
    setDegreeType(event.target.value);
  };
  const handleChangeDegree = (event) => {
    setSelectedDegree(event.target.value);
    switch (event.target.value) {
      case "firstDegree":
        setDegreeType(DEGREE_TYPES.first[0]);
        break;
      case "secondDegree":
        setDegreeType(DEGREE_TYPES.first[1]);
        break;
      case "doctor":
        setDegreeType(DEGREE_TYPES.first[2]);
        break;
      case "":
        setDegreeType("");
        break;
      default:
        break;
    }
  };

  function createDegreeTypeRadios() {
    switch (selectedDegree) {
      case "firstDegree":
        return DEGREE_TYPES.first.map((dt) => (
          <FormControlLabel
            key={dt}
            value={dt}
            control={<Radio color="primary" />}
            label={dt}
          />
        ));
      case "secondDegree":
        return DEGREE_TYPES.second.map((dt) => (
          <FormControlLabel
            key={dt}
            value={dt}
            control={<Radio color="primary" />}
            label={dt}
          />
        ));

      case "doctor":
        return DEGREE_TYPES.doctor.map((dt) => (
          <FormControlLabel
            key={dt}
            value={dt}
            control={<Radio color="primary" />}
            label={dt}
          />
        ));
      default:
        break;
    }
  }

  const handleReadChip = (adId) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleDegreeObj = academicDegreesAIObject.find(
        (ad) => ad.degreeId === adId
      );
      setSelectedDegree(singleDegreeObj.degreeName);
      setDegreeType(singleDegreeObj.degreeType);
      setDegreeOn(singleDegreeObj.degreeOn);
      setUpdatedChip(adId);
    }
  };

  const displayChips =
    academicDegreesAIObject !== null
      ? academicDegreesAIObject.map((ad) => {
          return (
            <Chip
              key={ad.degreeId}
              icon={<FaceIcon />}
              label={academicDegreeLabel(ad.degreeName, ad.degreeType)}
              clickable
              onClick={() => handleReadChip(ad.degreeId)}
              onDelete={() => handleRemoveDegree(ad.degreeId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== ad.degreeId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <StylesProvider jss={jss}>
      <Container maxWidth="lg" className={classes.root}>
        <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
        <Grid container>
          <Grid item style={{ width: "100%" }}>
            <Typography
              align="center"
              color="primary"
              variant="h5"
              className={classes.modalTitle}
            >
              {props.candidateFullName !== "undefined" &&
              props.candidateFullName.length > 1
                ? `התארים האקדמיים של  ${props.candidateFullName}`
                : "תארים אקדמיים"}
            </Typography>
            <Divider />
            <div>
              <Collapse
                in={
                  academicDegreesAIObject !== null &&
                  Object.keys(academicDegreesAIObject).length > 0
                }
              >
                <Typography variant="h5" className={classes.modalTitle}>
                  תארים אקדמיים של המועמד
                </Typography>
                {displayChips}
                <Divider />
              </Collapse>
            </div>

            <Typography
              style={{ fontWeight: 700, marginBottom: 15 }}
              color="textPrimary"
              variant="subtitle1"
            >
              בחר תואר:
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                תואר אקדמי
              </InputLabel>
              <Select
                labelId="AcademicDegreeLabel"
                id="select-outlined"
                value={selectedDegree}
                onChange={handleChangeDegree}
                label="תואר אקדמי"
              >
                <MenuItem value="">
                  <em>ללא</em>
                </MenuItem>
                <MenuItem value={"firstDegree"}>תואר ראשון</MenuItem>
                <MenuItem value={"secondDegree"}>תואר שני</MenuItem>
                <MenuItem value={"doctor"}>דוקטור</MenuItem>
                <MenuItem value={"engineer"}>מהנדס</MenuItem>
                <MenuItem value={"practicalEngineer"}>הנדסאי</MenuItem>
                <MenuItem value={"technition"}>טכנאי</MenuItem>
              </Select>
            </FormControl>
            <Collapse in={selectedDegree !== "" && degreeType !== "general"}>
              <Typography
                style={{ fontWeight: 700, marginBottom: 15 }}
                color="textPrimary"
                variant="subtitle1"
              >
                בחר את סוג התואר:
              </Typography>
              <div
                style={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <TextField
                  id="degreeOnField"
                  label="תואר ב:"
                  variant="outlined"
                  fullWidth
                  value={degreeOn}
                  onChange={(e) => setDegreeOn(e.target.value)}
                />
              </div>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="degreeTypeRadio"
                  name="radioType"
                  value={degreeType}
                  onChange={handleDegreeTypeChange}
                >
                  {createDegreeTypeRadios()}
                </RadioGroup>
              </FormControl>
            </Collapse>
            <Box my={2}>
              <FilterQuestionRelationsTag
                category={AI_ACADEMIC_DGREE}
                value={tags}
                onSelect={(value) => setTags(value)}
                readOnly
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                disabled={
                  selectedDegree === "" || degreeType === "" || degreeOn === ""
                }
                className={classes.button}
                endIcon={<Icon>add</Icon>}
                onClick={
                  updatedChip <= 0
                    ? handleAddSingleAcademicDegreeAI
                    : updateSelectedChip
                } //add new Object with all values of this Component
              >
                {updatedChip > 0 ? "עדכן תואר" : "הוסף תואר"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  academicDegreesAIObject === null ||
                  academicDegreesAIObject === "undefined" ||
                  Object.keys(academicDegreesAIObject).length <= 0 ||
                  academicDegreesAIObject.length <= 0
                }
                className={classes.button}
                endIcon={<Icon>done</Icon>}
                onClick={buildAIAcademicDegreesObject}
              >
                סיימתי
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StylesProvider>
  );
};
export default React.memo(AIAcademicDegree);
