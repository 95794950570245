import axios from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../reactQuery/rqConstants";

const getCompanies = async () => {
  try {
    const companiesRequest = await axios.get("/wp-json/api/v2/get_companies");
    return companiesRequest.data;
  } catch (err) {
    return err;
  }
};

const useCompanies = () => {
  return useQuery(queryKeys.companies, getCompanies, {
    cacheTime: 600000,
    retry: 2,
    staleTime: 300000,
  });
};
export default useCompanies;
