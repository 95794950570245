import React from "react";
import FQDropdownBox from "../../components/FQDropdownBox/FQDropdownBox";
import {
  AI_EXPERIENCES,
  AI_SKILLS,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_DYNAMICS,
  AI_LANGUAGES,
  AI_LICENSES,
} from "../../config/constants";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-fullWidth": {
      minWidth: 150,
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.grey[500],
    },
  },
}));

const JobDetailsViewStep3 = ({
  experienceFilterQuestions,
  talentsFilterQuestions,
  licensesFilterQuestions,
  languagesFilterQuestions,
  academicDegreeFilterQuestions,
  certificateFilterQuestions,
  dynamicFilterQuestions,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        {experienceFilterQuestions && experienceFilterQuestions.length > 0 && (
          <FQDropdownBox
            fqName="ניסיון תעסוקתי"
            fqValue={experienceFilterQuestions}
            type={AI_EXPERIENCES}
            readOnlyMode
          />
        )}
        {talentsFilterQuestions && talentsFilterQuestions.length > 0 && (
          <FQDropdownBox
            fqName="כישורים"
            fqValue={talentsFilterQuestions}
            type={AI_SKILLS}
            readOnlyMode
          />
        )}
        {licensesFilterQuestions && licensesFilterQuestions.length > 0 && (
          <FQDropdownBox
            fqName="רישיונות"
            fqValue={licensesFilterQuestions}
            type={AI_LICENSES}
            readOnlyMode
          />
        )}
        {languagesFilterQuestions && languagesFilterQuestions.length > 0 && (
          <FQDropdownBox
            fqName="שפות"
            fqValue={languagesFilterQuestions}
            type={AI_LANGUAGES}
            readOnlyMode
          />
        )}
        {academicDegreeFilterQuestions &&
          academicDegreeFilterQuestions.length > 0 && (
            <FQDropdownBox
              fqName="תארים אקדמיים"
              fqValue={academicDegreeFilterQuestions}
              type={AI_ACADEMIC_DGREE}
              readOnlyMode
            />
          )}
        {certificateFilterQuestions &&
          certificateFilterQuestions.length > 0 && (
            <FQDropdownBox
              fqName="תעודות"
              fqValue={certificateFilterQuestions}
              type={AI_CERTIFICATES}
              readOnlyMode
            />
          )}
        {dynamicFilterQuestions && dynamicFilterQuestions.length > 0 && (
          <FQDropdownBox
            fqName="שאלות דינאמיות"
            fqValue={dynamicFilterQuestions}
            type={AI_DYNAMICS}
            readOnlyMode
          />
        )}
      </Grid>
    </Grid>
  );
};
export default JobDetailsViewStep3;
