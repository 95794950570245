import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import AxiosErrorHandling from "../../../../../components/AxiosErrorHandling/AxiosErrorHandling";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const DistributionReport = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(true);
  const [summery, setSummery] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(`wp-json/api/v2/distribution_report/${props.jobId}`)
      .then((res) => {
        setSummery(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response);
      });
  }, [props.jobId]);
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          סיכום הפצות
        </Typography>
        {error && (
          <AxiosErrorHandling
            title="אירעה שגיאה בעת טעינת היסטורית הפצות"
            error={error.response}
          />
        )}
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : summery.length === 0 ? (
          <Typography variant="caption">אין היסטורית הפצות למשרה זו</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>סה"כ מועמדים</TableCell>
                  <TableCell>SMS יוצאים</TableCell>
                  <TableCell>אימיילים יוצאים</TableCell>
                  <TableCell>הזמנת WA</TableCell>
                  <TableCell>הופץ על ידי</TableCell>
                  <TableCell>הופץ בתאריך</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summery.map((row) => (
                  <TableRow key={row.distribution_id}>
                    <TableCell component="th" scope="row">
                      <Link
                        to={`${props.location.pathname}/distribution/${row.distribution_id}`}
                        style={{ color: theme.palette.primary.main }}
                      >
                        {row.distribution_id}
                      </Link>
                    </TableCell>
                    <TableCell>{row.total_candidates}</TableCell>
                    <TableCell>{row.sms}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.wa}</TableCell>
                    <TableCell>{row.distributed_by}</TableCell>
                    <TableCell>{row.distribution_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
export default withRouter(DistributionReport);
