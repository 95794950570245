import {withStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core'

const CandidateTextFieldFilled = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#E6007E',
      },
      '& .MuiFilledInput-underline:after': {
        borderBottomColor: '#E6007E',
      },      
    },
  })(TextField);

export default CandidateTextFieldFilled;

