import React, { useEffect, useState } from "react";
import useTags from "../../../../customHooks/useTags";
import {
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
const FilterQuestionRelationsTag = ({
  category,
  term,
  onSelect,
  value,
  readOnly = false,
  styling = {},
}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [open, setOpen] = useState(false);
  const { data: localTagsList, isLoading } = useTags(1, 1000, "", [category]);

  useEffect(() => {
    if (
      value &&
      value.length > 0 &&
      localTagsList &&
      localTagsList.tags &&
      localTagsList.tags.length
    ) {
      const localSelectedTags = value.map((tagId) => {
        return parseInt(getTagById(tagId).id);
      });
      setSelectedTags(localSelectedTags);
    } else {
      //only if no selected tags
      if (
        term !== "" &&
        localTagsList &&
        localTagsList.tags &&
        selectedTags.length === 0
      ) {
        const foundTags = localTagsList.tags.filter((tagItem) =>
          tagItem.name.includes(term)
        );
        if (foundTags.length === 1) {
          //check if not already selected:
          const foundIndex = selectedTags.findIndex(
            (tag) => parseInt(tag) === parseInt(foundTags[0].id)
          );

          if (foundIndex < 0) {
            setSelectedTags([...selectedTags, parseInt(foundTags[0].id)]);
            onSelect([...selectedTags, parseInt(foundTags[0].id)]);
          }
        }
      }
    }
  }, [term, category, open, localTagsList, value]);

  const handleChange = (event, value) => {
    setSelectedTags(value.map((v) => parseInt(v)));
    onSelect(value.map((v) => parseInt(v)));
  };

  const getTagById = (id) => {
    const tagIndex = localTagsList.tags.findIndex(
      (tag) => parseInt(tag.id) === parseInt(id)
    );
    if (tagIndex > -1) return localTagsList.tags[tagIndex];

    return "";
  };

  return (
    <Autocomplete
      id="asynchronousTag"
      multiple
      open={open}
      options={
        localTagsList && localTagsList.tags && localTagsList.tags.length > 0
          ? localTagsList.tags.map((tag) => tag.id)
          : []
      }
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectedTags}
      style={styling ? styling : ""}
      disableCloseOnSelect
      disabled={readOnly}
      onChange={handleChange}
      getOptionLabel={(tag) => getTagById(tag).name}
      getOptionSelected={(option, tag) => parseInt(option) === parseInt(tag)}
      loading={isLoading}
      renderOption={(tag) => (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <FormControlLabel
            control={
              <Checkbox
                //checked={selectedTags && selectedTags.includes(tag)}
                color="primary"
              />
            }
            label={`#${getTagById(tag).name}`}
          />
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="בחירת תגית"
          variant="filled"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
export default FilterQuestionRelationsTag;
