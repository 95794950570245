import React, { Suspense, useEffect } from "react";
//THIS COMMENT IS BRAnched from main. should appear soley in all merged version
import { jssPreset, StylesProvider } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { JobExtentList, AreasList } from "./config/jobsConfigData";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Login from "./Screens/Login/Login";
import Jobs from "./Screens/Jobs/Jobs";
import Candidates from "./Screens/Candidates/Candidates";
import { heIL } from "@material-ui/core/locale";
import axios from "axios";
import "./App.css";
import WithMenuContainer from "./hoc/WithMenuContainer";
import ConfigurationListsContext from "./context/configurationListsContext";
import CandidateLogin from "./Screens/ProfilesManagement/CandidateProfile/Login/Login";
import CssBaseline from "@material-ui/core/CssBaseline";
//import TracerCandidate from "./Screens/Tracers/TracerCandidate";
import cookies from "js-cookie";
import LibiExplore from "./Screens/Explore/LibiExplore";
import { pink } from "@material-ui/core/colors";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import AxiosErrorHandling from "./components/AxiosErrorHandling/AxiosErrorHandling";
import { ReactQueryDevtools } from "react-query/devtools";
import useUsers from "./customHooks/useUsers";
import useCompanies from "./customHooks/useCompanies";
import useProfessions from "./customHooks/useProfessionals";
//import UploadTags from "./Screens/TagsManager/uploadTags";

const HLDashboard = React.lazy(() =>
  import("./Screens/Dashboard/HighLevelStatsDashboard")
);

const Settings = React.lazy(() =>
  import("./Screens/Settings/MainSettings/Settings.js")
);

const DeatiledActivityReport = React.lazy(() =>
  import("./Screens/Dashboard/DeatiledActivityReport")
);

const DetailedDistributionReport = React.lazy(() =>
  import(
    "./Screens/Jobs/JobsList/JobDashboard/DetailedDistributionReport/DetailedDistributionReport"
  )
);

const TracerCandidate = React.lazy(() =>
  import("./Screens/Tracers/TracerCandidate.js")
);

const Tracers = React.lazy(() => import("./Screens/Tracers/Tracers.js"));

const PublishJobs = React.lazy(() => import("./publishJobs/PublishJobs.js"));

const TagsManager = React.lazy(() =>
  import("./Screens/TagsManager/TagsManager.js")
);

const UploadTags = React.lazy(() =>
  import("./Screens/TagsManager/uploadTags.js")
);

const Unsubscribe = React.lazy(() =>
  import("./components/Unsubscribe/Unsubscribe.js")
);

const CandidateDashboard = React.lazy(() =>
  import(
    "./Screens/ProfilesManagement/CandidateProfile/Dashboard/CandidateDashboard"
  )
);

const Registration = React.lazy(() =>
  import(
    "./Screens/ProfilesManagement/CandidateProfile/Registration/Registration"
  )
);

//axios.defaults.baseURL = "http://localhost/libi-smart-backoffice/";
axios.defaults.baseURL = "https://l-b.backoffice.libi.ai/";
//axios.defaults.baseURL = "https://testing-backoffice.libi.ai/";

axios.defaults.timeout = 80000;
axios.defaults.headers["Content-Type"] = "application/json; charset=UTF-8";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

axios.interceptors.request.use(
  (request) => {
    if (
      request.url !== "/wp-json/jwt-auth/v1/token" &&
      !request.url.includes("/already_answered/") &&
      !request.url.includes("/candidateAuthentication/") &&
      !request.url.includes("/api/v2/job/") &&
      !request.url.includes("/api/v2/questionier_answers/") &&
      !request.url.includes("wp-json/api/v2/declinedJob/") &&
      !request.url.includes("/api/v2/unsubscribe") &&
      !request.url.includes("/api/v2/locateCandidate/") &&
      !request.url.includes("/api/v2/generate_login_passcode") &&
      !request.url.includes("/api/v2/generate_login_token") &&
      !request.url.includes("/api/v2/accept_registration") &&
      !request.url.includes("/api/v2/areas_professions_list") &&
      !request.url.includes("/api/v2/trace/") &&
      !request.url.includes("/api/v2/upload_trace_cv") &&
      !request.url.includes("/api/v2/send_trace") &&
      !request.url.includes("/api/v2/reset_password")
    ) {
      //check if this a candidate domain. all candidate domains must include /my-profile/lobby

      if (
        request.url.includes("/my-profile/lobby") ||
        request.url.includes("/my-profile/settings")
      ) {
        request.headers["Authorization"] = `Bearer ${cookies.get("token")}`;
      } else {
        request.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
    }

    console.log("request", request);
    return request;
  },
  (error) => {
    console.log("request error", error);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    console.log("response", response);
    return response;
  },
  (error) => {
    console.log("response error", error.response, error);

    if (
      (error.response && error.response.data.code === "jwt_auth_bad_iss") ||
      error.response.data.code === "jwt_auth_invalid_token" ||
      error.response.data.data.status === 401
    ) {
      localStorage.clear();
      //window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const theme = createMuiTheme(
  {
    typography: {
      fontFamily: "Rubik, sans-serif",
      h4: {
        fontWeight: 700,
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: "1.2rem",
      },
      body2: {
        fontSize: "1.1rem",
        fontWeight: 400,
      },
      smallText: {
        fontSize: "0.75rem",
        fontWeight: 400,
      },
    },
    palette: {
      primary: {
        main: "#FF0A90",
        light: "#FFA3D5",
        dark: "#A6005B",
      },
      secondary: {
        main: "#2941FF",
        light: "#8593FF",
        dark: "#001194",
      },
      success: {
        main: "#0BD03B",
        light: "#EDF9F0",
        dark: "#06681E",
      },
      text: {
        primary: "#363740",
        secondary: "#636575",
      },
      background: {
        default: "#F5F6F8",
      },
    },
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#F5F6F8",
        },
      },
      MuiInputLabel: {
        root: {
          color: "#A4A6B3",
        },
      },
      MuiChip: {
        root: {
          backgroundColor: pink[50],
          color: "#FF0A90",
          "& .MuiSvgIcon-root": {
            color: "#FF0A90",
          },
          size: "medium",
        },
      },
    },
    spacing: (factor) => `${0.5 * factor}rem`,
  },
  { direction: "rtl" },
  heIL
);

function App() {
  const [professionsList, setProfessionalList] = React.useState([]);
  const [customersList, setCustomerList] = React.useState([]);
  const [jobExtentList, setJobExtentList] = React.useState([]);
  const [areasList, setAreasList] = React.useState([]);
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [error, setError] = React.useState(null);
  const { data: usersData } = useUsers();
  const { data: companiesData } = useCompanies();
  const { data: professionsData } = useProfessions();

  //  const { data: tags } = usePrefetchTags(1, 50);

  React.useEffect(() => {
    //load config files:

    if (window.location.pathname.includes("/my-profile/")) {
      //get token or redirect to login
      if (window.location.pathname.includes("/my-profile/lobby")) {
        const token = cookies.get("token");
        if (!token) window.location.href = "/my-profile/login";
      }
      return;
    }
    if (window.location.pathname.includes("/publishjob/")) return;
    if (
      !window.location.pathname.includes("/login") &&
      !window.location.pathname.includes("/tracer/") &&
      !window.location.pathname.includes("/publishJob")
    ) {
      setAreasList(
        Object.keys(AreasList).map((item) => {
          return { id: item, value: AreasList[item] };
        })
      );
      setJobExtentList(
        Object.keys(JobExtentList).map((item) => {
          return { id: item, value: JobExtentList[item] };
        })
      );
    }
  }, []);

  useEffect(() => {
    setProfessionalList(professionsData);
    setCustomerList(companiesData);
    setUsers(usersData);
  }, [professionsData, usersData, companiesData]);

  const createCustomTheme = () => {
    return !isDarkMode
      ? createMuiTheme(
          {
            typography: {
              h2: {
                fontSize: "3rem",
                [theme.breakpoints.down("md")]: {
                  fontSize: "2rem",
                },
              },
              h3: {
                fontSize: "2rem",
              },
              h4: {
                fontSize: "1.5rem",
              },
            },
            spacing: (factor) => `${0.5 * factor}rem`,
            palette: {
              type: "light",
              text: {
                primary: "#363740",
              },
            },
          },
          { direction: "rtl" },
          heIL
        )
      : createMuiTheme(
          {
            typography: {
              h2: {
                fontSize: "3rem",
              },
              h3: {
                fontSize: "2rem",
              },
              h4: {
                fontSize: "1.5rem",
              },
            },
            spacing: (factor) => `${0.5 * factor}rem`,
            palette: {
              type: "dark",
              background: {
                paper: "#212936",
                default: "#2B3648",
              },
            },
          },
          { direction: "rtl" },
          heIL
        );
  };

  const handleToggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ConfigurationListsContext.Provider
        value={{
          professionsList,
          customersList,
          jobExtentList,
          areasList,
          users,
        }}
      >
        <Router>
          <Switch>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Route exact path="/login">
                <Login />
              </Route>
              <StylesProvider jss={jss}>
                <Route exact path="/dashboard">
                  <WithMenuContainer screenTitle="לוח מחוונים">
                    <Suspense fallback={<div>Loading Data...</div>}>
                      <HLDashboard />
                    </Suspense>
                  </WithMenuContainer>
                </Route>
                <Route exact path="/activityReport">
                  <WithMenuContainer screenTitle="דוח פעילות מפורט">
                    <Suspense fallback={<div>Loading...</div>}>
                      <DeatiledActivityReport />
                    </Suspense>
                  </WithMenuContainer>
                </Route>

                <Route exact path="/settings">
                  <WithMenuContainer screenTitle="הגדרות מערכת">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Settings />
                    </Suspense>
                  </WithMenuContainer>
                </Route>
                <Route exact path="/explore">
                  <WithMenuContainer screenTitle="אקספלור">
                    <LibiExplore />
                  </WithMenuContainer>
                </Route>

                <Route exact path="/candidates">
                  <WithMenuContainer screenTitle="ניהול מועמדים">
                    <Candidates />
                  </WithMenuContainer>
                </Route>
                <Route exact path="/jobs/dashboard/:jobid">
                  <WithMenuContainer screenTitle="ניהול משרות">
                    <ErrorBoundary>
                      <Jobs innerpath="dashboard" />
                    </ErrorBoundary>
                  </WithMenuContainer>
                </Route>
                <Route
                  exact
                  path="/jobs/dashboard/:jobid/distribution/:distributionId"
                >
                  <WithMenuContainer screenTitle="ניהול משרות">
                    <ErrorBoundary>
                      <Suspense fallback={<div>Loading...</div>}>
                        <DetailedDistributionReport />
                      </Suspense>
                    </ErrorBoundary>
                  </WithMenuContainer>
                </Route>
                <Route exact path="/jobs">
                  <WithMenuContainer screenTitle="ניהול משרות">
                    <Jobs innerpath="jobs" />
                  </WithMenuContainer>
                </Route>
                <Route exact path="/jobs/addNew">
                  <WithMenuContainer screenTitle="ניהול משרות">
                    <Jobs innerpath="AddNewJob" />
                  </WithMenuContainer>
                </Route>
                <Route exact path="/jobs/drafts">
                  <WithMenuContainer screenTitle="ניהול משרות">
                    <Jobs innerpath="drafts" />
                  </WithMenuContainer>
                </Route>
                <Route exact path="/jobs/uploads/:jobid">
                  <WithMenuContainer screenTitle="ניהול משרות">
                    <Jobs innerpath="uploads" />
                  </WithMenuContainer>
                </Route>
                <Route exact path="/tracers">
                  <WithMenuContainer screenTitle="קישורי הפצה">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Tracers />
                    </Suspense>
                  </WithMenuContainer>
                </Route>
                <Route exact path="/tracer/:traceid">
                  <Suspense fallback={<div>Loading...</div>}>
                    <TracerCandidate />
                  </Suspense>
                </Route>
                <Route path="/publishJob/:id" exact>
                  <Suspense fallback={<div>Loading...</div>}>
                    <PublishJobs />
                  </Suspense>
                </Route>
                <Route path="/unsubscribe/:auth" exact>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Unsubscribe />
                  </Suspense>
                </Route>
                <Route exact path="/tags-manager">
                  <WithMenuContainer screenTitle="ניהול תגיות">
                    <Suspense fallback={<div>Loading Tag Manager...</div>}>
                      <TagsManager />
                    </Suspense>
                  </WithMenuContainer>
                </Route>
                <Route exact path="/tags-manager/upload">
                  <WithMenuContainer screenTitle="טעינת תגיות">
                    <Suspense fallback={<div>Loading...</div>}>
                      <UploadTags />
                    </Suspense>
                  </WithMenuContainer>
                </Route>
                <Route path="/" exact>
                  <WithMenuContainer screenTitle="לוח מחוונים">
                    <Jobs innerpath="jobs" />
                  </WithMenuContainer>
                </Route>
              </StylesProvider>
            </ThemeProvider>
          </Switch>
          <Switch>
            <ThemeProvider theme={createCustomTheme()}>
              <CssBaseline />
              <StylesProvider jss={jss}>
                <Route exact path="/my-profile/login">
                  <CandidateLogin />
                </Route>
                <Route exact path="/my-profile/lobby">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CandidateDashboard
                      onToggleDark={handleToggleDarkMode}
                      isDarkMode={isDarkMode}
                    />
                  </Suspense>
                </Route>
                <Route exact path="/my-profile/settings">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CandidateDashboard
                      onToggleDark={handleToggleDarkMode}
                      isDarkMode={isDarkMode}
                    />
                  </Suspense>
                </Route>
                <Route exact path="/my-profile/signup">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Registration />
                  </Suspense>
                </Route>
              </StylesProvider>
            </ThemeProvider>
          </Switch>
        </Router>
      </ConfigurationListsContext.Provider>
      {error && (
        <AxiosErrorHandling title="Catastrophic Failure" error={error} />
      )}
      <ReactQueryDevtools />
    </React.Fragment>
  );
}

export default App;
