import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  FilledInput,
} from "@material-ui/core";
import { AI_SALARY_EXPECTATIONS } from "../../config/constants";
import FilterQuestionRelationsTag from "../../Screens/Jobs/AddJob/FilterQuestions/FilterQuestionRelationsTag";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      marginRight: theme.spacing(2),
    },
  },
}));

const SalaryExpectationsSelection = ({ onSelect }) => {
  const [salaryFrom, setSalaryFrom] = useState(10000);
  const [salaryTo, setSalaryTo] = useState(12000);
  const [tags, setTags] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    //validate data:
    onSelect({
      value: {
        salaryFrom,
        salaryTo,
      },
      tags,
    });
  }, [salaryFrom, salaryTo, tags]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
      width={"100%"}
    >
      <FormControl fullWidth variant="filled" margin="normal">
        <InputLabel htmlFor="minimumAmount">שכר החל מ</InputLabel>
        <FilledInput
          id="minimumAmount"
          value={salaryFrom}
          variant="filled"
          type="number"
          onChange={(event) => setSalaryFrom(parseInt(event.target.value))}
          inputProps={{ min: 0, step: 500 }}
          startAdornment={<InputAdornment position="start">₪</InputAdornment>}
        />
      </FormControl>
      <FormControl fullWidth variant="filled" margin="normal">
        <InputLabel htmlFor="maxAmount">שכר החל מ</InputLabel>
        <FilledInput
          id="maxAmount"
          value={salaryTo}
          variant="filled"
          type="number"
          onChange={(event) =>
            setSalaryTo(parseInt(event.target.value ? event.target.value : 0))
          }
          inputProps={{ min: salaryFrom, step: 500 }}
          startAdornment={<InputAdornment position="start">₪</InputAdornment>}
          fullWidth
          error={salaryFrom > salaryTo}
        />
      </FormControl>
      <FilterQuestionRelationsTag
        category={AI_SALARY_EXPECTATIONS}
        onSelect={(value) => setTags(value)}
        styling={{
          width: "100%",
        }}
      />
    </Box>
  );
};
export default SalaryExpectationsSelection;
