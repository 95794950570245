import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const GeneralSelection = ({ onSelect, listOptions, label }) => {
  return (
    <FormControl margin="normal" variant="filled">
      <InputLabel id="simple-select-label">{label}</InputLabel>
      <Select
        labelId="simple-select-label"
        id="simple-select"
        onChange={(event) => onSelect(event.target.value)}
        defaultValue={""}
      >
        {listOptions.map((option) => {
          return (
            <MenuItem key={option.name} value={option.name}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default GeneralSelection;
