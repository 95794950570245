import React, { useState } from "react";

import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import { Box, Typography, TextField, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ConfigurationListsContext from "../../../../../context/configurationListsContext";
import FilterQuestionDisplayPrevew from "../FilterQuestionDisplayPreview";
import { experiencePreviewQuestion } from "../../../../../config/jobsConfigData";
import FilterQuestionRelationsTag from "../FilterQuestionRelationsTag";
import { AI_EXPERIENCES } from "../../../../../config/constants";

const FQExperience = ({
  onCompletion,
  onCancel,
  fqEdit = null,
  isActive,
  isActiveReadOnly,
}) => {
  const [selectedProfession, setSelectedPropfession] = React.useState("");
  const [minYearsExperience, setMinYearsExperience] = React.useState(0);
  const [isExperienceIsMust, setIsExperienceIsMust] = React.useState(false);
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [tags, setTags] = useState([]);
  const { professionsList } = React.useContext(ConfigurationListsContext);
  React.useEffect(() => {
    if (fqEdit) {
      const propfessions = professionsList.find(
        (item) => item.id === parseInt(fqEdit.selectedProfession)
      );
      setSelectedPropfession(propfessions);
      setMinYearsExperience(fqEdit.minYears);
      setIsExperienceIsMust(
        fqEdit.isAMust === 1 || fqEdit.isAMust ? true : false
      );
      setAdditionalInfo(fqEdit.addInfo);
      setTags(fqEdit.tags);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit, professionsList]);

  const handleMinYearsValue = (event) => {
    setMinYearsExperience(event.target.value);
  };

  const clearForm = () => {
    setSelectedPropfession("");
    setMinYearsExperience(0);
    setIsExperienceIsMust(false);
    setAdditionalInfo("");
    setTags([]);
  };

  const handleOnCompletion = () => {
    //keep the add info before clearing the form:

    clearForm();
    //if edit, return with the id:

    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["selectedProfession"] = parseInt(selectedProfession.id);
      existingObject["minYears"] = parseInt(minYearsExperience);
      existingObject["isAMust"] = isExperienceIsMust ? true : false;
      existingObject["addInfo"] = additionalInfo;
      existingObject["tags"] = tags;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      selectedProfession: parseInt(selectedProfession.id),
      minYears: parseInt(minYearsExperience),
      isAMust: isExperienceIsMust ? true : false,
      addInfo: additionalInfo,
      tags: tags,
    });
  };

  const handleChangeProfession = (event, value, reason) => {
    if (value !== null || reason !== "clear") setSelectedPropfession(value);
  };

  const handleIsExperienceIsMust = (event) => {
    setIsExperienceIsMust(event.target.checked);
  };

  const handleCheckEnableQ = (value) => {
    if (!value) setMinYearsExperience(0);
  };
  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          בחר תפקיד:
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {
            <ConfigurationListsContext.Consumer>
              {(context) => (
                <Autocomplete
                  id="sectors"
                  key={selectedProfession}
                  options={context.professionsList}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={handleChangeProfession}
                  value={selectedProfession ? selectedProfession : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="primary"
                      label="בחר מקצוע"
                      variant="filled"
                    />
                  )}
                  disabled={isActiveReadOnly}
                />
              )}
            </ConfigurationListsContext.Consumer>
          }
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="additionalInfo"
            label="מידע נוסף למועמד"
            variant="filled"
            value={additionalInfo}
            onChange={(event) => setAdditionalInfo(event.target.value)}
            fullWidth
            helperText="לדוגמה: נדרש ניסיון בניהול יומנים"
            disabled={isActiveReadOnly}
          />
        </Grid>
        <Grid item xs={6}>
          {isActive && (
            <FilterQuestionRelationsTag
              category={AI_EXPERIENCES}
              term={
                selectedProfession !== "" &&
                professionsList.find(
                  (item) => item.id === parseInt(selectedProfession.id)
                ).name
              }
              value={tags}
              onSelect={(value) => setTags(value)}
              readOnly={isActiveReadOnly}
            />
          )}
          {isActive && selectedProfession && (
            <Typography variant="body1" fontSize={15}>
              תגית נוספת מעבר לתגית המקצוע{" "}
              {
                professionsList.find(
                  (item) => item.id === parseInt(selectedProfession.id)
                ).name
              }
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <QuestionValueInput
            question="שנות ניסיון מינימלי הדרוש בתפקיד זה:"
            minYearsValue={handleMinYearsValue}
            isAMustCheck={handleIsExperienceIsMust}
            value={minYearsExperience}
            isAmustValue={isExperienceIsMust}
            checkEnalbeQ={handleCheckEnableQ}
            disabled={isActiveReadOnly}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterQuestionDisplayPrevew
            previewQuestion={experiencePreviewQuestion(
              selectedProfession ? selectedProfession.name : "",
              additionalInfo,
              selectedProfession.hebrew_pronounce
                ? selectedProfession.hebrew_pronounce
                : undefined
            )}
          />
        </Grid>
      </Grid>
      <CancelApproveButtons
        positiveCaption="סיימתי"
        negativeCaption="ביטול"
        positiveDisabled={
          selectedProfession === "" || !tags || tags.length === 0
        }
        onPositiveClick={handleOnCompletion}
        onNegativeClick={onCancel}
      />
    </React.Fragment>
  );
};
export default FQExperience;
