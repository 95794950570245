import React from "react";
import { Badge, Chip } from "@material-ui/core";
import { translateCategories } from "../../config/constants";
const FailureReasons = ({ data, onClick }) => {
  return (
    <Badge
      badgeContent={data.length}
      color="primary"
      overlap="circle"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Chip
        label={data.length > 0 ? translateCategories(data[0].key) : ""}
        variant="outlined"
        clickable        
        onClick={onClick}
      />
    </Badge>
  );
};
export default FailureReasons;
