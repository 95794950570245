import React from "react";
import { Pagination } from "@material-ui/lab";
import { IconButton, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& .MuiTypography-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const MyPagination = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <React.Fragment>
        <Tooltip title="טען רשימה מחדש" arrow>
          <IconButton
            aria-label="refresh"
            size="small"
            className={classes.button}
            onClick={props.reload}
          >
            <ReplayRoundedIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="caption" size="small" color="secondary">
          {props.totalLabel ? props.totalLabel : 'סה"כ משרות'} {props.total}
        </Typography>
        {!props.disablePaging ? (
          <Pagination
            count={props.totalPages}
            size="small"
            page={props.currentPage}
            onChange={props.onChange}
            siblingCount={0}
            boundaryCount={1}
          />
        ) : null}
      </React.Fragment>
    </div>
  );
};
export default MyPagination;
