import React, { useState } from "react";
import {
  TextField,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import FilterQuestionDisplayPrevew from "../FilterQuestionDisplayPreview";
import { skillsPreviewQuestion } from "../../../../../config/jobsConfigData";
import FilterQuestionRelationsTag from "../FilterQuestionRelationsTag";
import { AI_SKILLS } from "../../../../../config/constants";

const FQTalents = ({
  onCompletion,
  onCancel,
  fqEdit = null,
  isActive,
  isActiveReadOnly,
}) => {
  const [isSkillIsMust, setIsSkillIsMust] = React.useState(false);
  const [selectedSkill, setSelectedSkill] = React.useState("");
  const [selectedKnowledgeLevel, setSelectedKnowledgeLevel] = React.useState(
    "average"
  );
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [tags, setTags] = useState([]);

  React.useEffect(() => {
    if (fqEdit !== null) {
      setSelectedSkill(fqEdit.skillName);
      setIsSkillIsMust(fqEdit.isSkillAMust);
      setSelectedKnowledgeLevel(fqEdit.knowledgeLevelRequired);
      setAdditionalInfo(fqEdit.addInfo);
      setTags(fqEdit.tags);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  const handleKnowledgeLevelChange = (event) => {
    setSelectedKnowledgeLevel(event.target.value);
  };

  const handleSelectedSkill = (event) => {
    setSelectedSkill(event.target.value);
  };

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:

    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["skillName"] = selectedSkill;
      existingObject["isSkillAMust"] = isSkillIsMust;
      existingObject["knowledgeLevelRequired"] = selectedKnowledgeLevel;
      existingObject["addInfo"] = additionalInfo;
      existingObject["tags"] = tags;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      skillName: selectedSkill,
      isSkillAMust: isSkillIsMust,
      knowledgeLevelRequired: selectedKnowledgeLevel,
      addInfo: additionalInfo,
      tags,
    });
  };

  /*
  Single skills Object: 
  {
      skillId: id,
      skillName: string,
      minYearsSkill: num, || ==> REMOVED 30.06.2022
      isSkillAMust: boolean,
      knowledgeLevelRequired: string
  }
  */

  const handleSkillIsAMust = () => {
    setIsSkillIsMust((prev) => !prev);
  };

  function clearForm() {
    setIsSkillIsMust(false);
    setSelectedSkill("");
    setSelectedKnowledgeLevel("average");
    setAdditionalInfo("");
    setTags([]);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
          gutterBottom={false}
        >
          הוסף כישורים:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="skill"
          label="מיומנות"
          variant="filled"
          color="primary"
          value={selectedSkill}
          onChange={handleSelectedSkill}
          fullWidth
          disabled={isActiveReadOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="additionalInfo"
          label="מידע נוסף למועמד"
          variant="filled"
          value={additionalInfo}
          onChange={(event) => setAdditionalInfo(event.target.value)}
          fullWidth
          helperText="לדוגמה: נדרש ניסיון בניהול יומנים"
          disabled={isActiveReadOnly}
        />
      </Grid>
      <Grid item xs={6}>
        {isActive && (
          <FilterQuestionRelationsTag
            category={AI_SKILLS}
            term={selectedSkill}
            onSelect={(value) => setTags(value)}
            value={tags}
            readOnly={isActiveReadOnly}
          />
        )}
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <FormControl component="fieldset" disabled={isActiveReadOnly}>
          <FormLabel component="legend">רמת הידע הנדרשת למומחיות זו</FormLabel>
          <RadioGroup
            aria-label="knowledgeLevel"
            name="knowledgeLevel"
            value={selectedKnowledgeLevel ? selectedKnowledgeLevel : ""}
            onChange={handleKnowledgeLevelChange}
          >
            <FormControlLabel
              value={"basic"}
              control={
                <Radio
                  color="primary"
                  checked={selectedKnowledgeLevel === "basic"}
                />
              }
              label="בסיסית"
            />
            <FormControlLabel
              value={"average"}
              control={
                <Radio
                  color="primary"
                  checked={selectedKnowledgeLevel === "average"}
                />
              }
              label="ממוצעת"
            />
            <FormControlLabel
              value={"advanced"}
              control={
                <Radio
                  color="primary"
                  checked={selectedKnowledgeLevel === "advanced"}
                />
              }
              label="מתקדם"
            />
            <FormControlLabel
              value={"expert"}
              control={
                <Radio
                  color="primary"
                  checked={selectedKnowledgeLevel === "expert"}
                />
              }
              label="מומחה"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSkillIsMust ? true : false}
              onClick={handleSkillIsAMust}
              name="requiredQ"
              color="primary"
              disabled={isActiveReadOnly}
            />
          }
          label="דרישה זו היא חובה"
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterQuestionDisplayPrevew
            previewQuestion={skillsPreviewQuestion(
              selectedSkill ? selectedSkill.trim() : "",
              additionalInfo
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CancelApproveButtons
          positiveCaption="סיימתי"
          negativeCaption="ביטול"
          positiveDisabled={selectedSkill === "" || !tags || tags.length === 0}
          onPositiveClick={handleOnCompletion}
          onNegativeClick={onCancel}
        />
      </Grid>
    </Grid>
  );
};
export default FQTalents;
