import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";

const CustomIcon = ({
  step,
  label,
  isActive = false,
  stepClick,
  completed,
}) => {
  const theme = useTheme();
  return (
    <Button
      style={{ position: "relative", textAlign: "right" }}
      onClick={() => stepClick()}
    >
      <div
        style={{
          width: 30,
          height: 30,
          backgroundColor: completed
            ? green[500]
            : isActive
            ? theme.palette.primary.main
            : theme.palette.grey[500],
          color: "#fff",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          position: "absolute",
          right: 0,
        }}
      >
        {completed ? <CheckRoundedIcon /> : step}
      </div>
      <Typography
        variant="body1"
        color={isActive ? "primary" : "textSecondary"}
        align="center"
        style={{ marginRight: theme.spacing(1), marginLeft: theme.spacing(1) }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default CustomIcon;
