import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
//import ProfessionsListAsync from "../../../../../components/ProfessionsListAsync/ProfessionsListAsync";
import rtl from "jss-rtl";
import { Icon, Button, Box } from "@material-ui/core";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import FilterQuestionRelationsTag from "../../../../Jobs/AddJob/FilterQuestions/FilterQuestionRelationsTag";
import { AI_SKILLS } from "../../../../../config/constants";
//import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const AISkills = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedSkill, setSelectedSkill] = useState("");
  const [yearsSkill, setYearsSkill] = useState(0);
  const [updatedChip, setUpdatedChip] = useState(-1);
  const [tags, setTags] = useState([]);
  const [selectedKnowledgeLevel, setSelectedKnowledgeLevel] = useState(null);
  const [skillsAIObject, setSkillsAIObject] = useState([]);

  useEffect(() => {
    if (props.aIValue.data && Object.keys(props.aIValue.data).length) {
      const aiTempObject = props.aIValue.data.map((aiItem) => {
        return (
          [...skillsAIObject],
          {
            key: aiItem.skillId,
            skillId: aiItem.skillId,
            skillName: aiItem.skillName,
            yearsSkill: parseInt(aiItem.yearsSkill),
            knowledgeLevel: aiItem.knowledgeLevel,
          }
        );
      });
      props.aIValue.tags && setTags(props.aIValue.tags);
      setSkillsAIObject(aiTempObject);
    }

    return () => {
      clearForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const handleSelectedSkill = (event) => {
    setSelectedSkill(event.target.value);
  };

  const buildAISkillObject = (event) => {
    props.addAI({ data: skillsAIObject, tags });
  };

  const handleYearsValue = (event) => {
    setYearsSkill(event.target.value);
  };

  const handleCheckEnableQ = (value) => {
    if (!value) setYearsSkill(0);
  };

  /*
  Single Skill Object: 
  {
      skillId: id,
      skillName: string,
      yearsSkill: num,      
  }
  */
  const handleAddSingleSkillAI = () => {
    skillsAIObject === null
      ? setSkillsAIObject([
          {
            skillId: skillsAIObject === null ? 1 : skillsAIObject.length + 1,
            skillName: selectedSkill,
            yearsSkill,
            knowledgeLevel: selectedKnowledgeLevel,
          },
        ])
      : setSkillsAIObject([
          ...skillsAIObject,
          {
            skillId: skillsAIObject.length + 1,
            skillName: selectedSkill,
            yearsSkill,
            knowledgeLevel: selectedKnowledgeLevel,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = skillsAIObject.find(
      (chip) => chip.skillId === updatedChip
    );

    //remove the selected chip from the array:
    const tempSkillAIObject = skillsAIObject.filter(
      (ai) => ai.skillId !== tempUpdatedChip.skillId
    );

    //update the form:
    setSkillsAIObject([
      ...tempSkillAIObject,
      {
        skillId: tempUpdatedChip.skillId,
        skillName: selectedSkill,
        yearsSkill: yearsSkill,
        knowledgeLevel: selectedKnowledgeLevel,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setYearsSkill(0);
    setUpdatedChip(-1);
    setSelectedSkill("");
    setSelectedKnowledgeLevel(null);
  };

  const handleRemoveSkill = (skillId) => {
    //locate index id
    const newSkillFQObject = skillsAIObject.filter(
      (exp) => exp.skillId !== skillId
    );
    setSkillsAIObject(newSkillFQObject);
    clearForm();
  };

  const handleKnowledgeLevelChange = (event) => {
    setSelectedKnowledgeLevel(event.target.value);
  };

  const handleReadChip = (sklId) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleSklObj = skillsAIObject.find(
        (skill) => skill.skillId === sklId
      );
      setYearsSkill(singleSklObj.yearsSkill);
      setSelectedSkill(singleSklObj.skillName);
      setSelectedKnowledgeLevel(singleSklObj.knowledgeLevel);
      setUpdatedChip(sklId);
    }
  };

  const displayChips =
    skillsAIObject !== null
      ? skillsAIObject.map((skl) => {
          return (
            <Chip
              key={skl.skillId}
              icon={<FaceIcon />}
              label={skl.skillName}
              clickable
              color="primary"
              onClick={() => handleReadChip(skl.skillId)}
              onDelete={() => handleRemoveSkill(skl.skillId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== skl.skillId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <StylesProvider jss={jss}>
      <Container maxWidth="lg" className={classes.root}>
        <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
        <Grid container>
          <Grid item style={{ width: "100%" }}>
            <Typography
              align="center"
              color="primary"
              variant="h5"
              className={classes.modalTitle}
            >
              {props.candidateFullName !== "undefined" &&
              props.candidateFullName.length > 1
                ? `הכישורים של ${props.candidateFullName}`
                : "כישורים"}
            </Typography>
            <Divider />
            <div>
              <Collapse in={skillsAIObject !== null}>
                <Typography variant="h5" className={classes.modalTitle}>
                  כישורי המועמד
                </Typography>
                {displayChips}
                <Divider />
              </Collapse>
            </div>
            <Typography
              style={{ fontWeight: 700, marginBottom: theme.spacing(2) }}
              color="textPrimary"
              variant="subtitle1"
            >
              הוסף מיומנות:
            </Typography>
            <TextField
              id="skill"
              label="מיומנות"
              variant="outlined"
              value={selectedSkill}
              onChange={handleSelectedSkill}
              style={{ width: "100%" }}
            />

            <QuestionValueInput
              question="מספר שנות נסיון במיומנות זו:"
              minYearsValue={handleYearsValue}
              disableVerificationQuestion
              value={yearsSkill}
              checkEnalbeQ={handleCheckEnableQ}
            />
            <FormControl component="fieldset">
              <FormLabel component="legend">רמת הידע במומחיות זו</FormLabel>
              <RadioGroup
                aria-label="knowledgeLevel"
                name="knowledgeLevel"
                value={selectedKnowledgeLevel}
                onChange={handleKnowledgeLevelChange}
              >
                <FormControlLabel
                  value={"basic"}
                  control={<Radio color="primary" />}
                  label="בסיסית"
                />
                <FormControlLabel
                  value={"average"}
                  control={<Radio color="primary" />}
                  label="ממוצעת"
                />
                <FormControlLabel
                  value={"advanced"}
                  control={<Radio color="primary" />}
                  label="מתקדם"
                />
                <FormControlLabel
                  value={"expert"}
                  control={<Radio color="primary" />}
                  label="מומחה"
                />
              </RadioGroup>
            </FormControl>
            <Box my={2}>
              <FilterQuestionRelationsTag
                category={AI_SKILLS}
                value={tags}
                onSelect={(value) => setTags(value)}
                readOnly
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                disabled={selectedSkill === ""}
                className={classes.button}
                endIcon={<Icon>add</Icon>}
                onClick={
                  updatedChip <= 0 ? handleAddSingleSkillAI : updateSelectedChip
                } //add new Object with all values of this Component
              >
                {updatedChip > 0 ? "עדכן מידע" : "הוסף מידע"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  skillsAIObject === null ||
                  skillsAIObject === "undefined" ||
                  Object.keys(skillsAIObject).length <= 0 ||
                  skillsAIObject.length <= 0
                }
                className={classes.button}
                endIcon={<Icon>done</Icon>}
                onClick={buildAISkillObject}
              >
                סיימתי
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StylesProvider>
  );
};
export default React.memo(AISkills);
