import React, { useState } from "react";
import {
  Box,
  makeStyles,
  IconButton,
  useTheme,
  Tooltip,
  Link,
} from "@material-ui/core";
import { MenuMoreOptions } from "../../config/candidatesConfigData";
import EntityMoreActions from "../../components/EntityMoreActions/EntitysMoreActions";
import DownloadCVIcon from "../../Assets/CustomIcons/DownloadCV";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import { green, grey } from "@material-ui/core/colors";
import CandidateQuickView from "../../components/CandidateQuickView/CandidateQuickView";
import WhatsAppIcon from "../../Assets/CustomIcons/whatsApp";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
    },
  },
}));

const SingleCandidateActions = ({
  menuItemClick,
  downloadCV,
  quickViewData,
  underJobDashboard,
  isValidMobile,
  mobileNumber,
}) => {
  const [showQuickView, setShowQuickView] = React.useState(false);

  const classes = useStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.root}
      >
        {/* <IconButton>
          <DeleteIcon style={{ color: red[500] }} />
        </IconButton> */}
        <Tooltip title="צפיה בקורות חיים" arrow>
          <IconButton onClick={downloadCV}>
            <DownloadCVIcon
              style={{ color: theme.palette.primary.main }}
              strokeColor={theme.palette.primary.main}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="צפיה מהירה בפרופיל מועמד">
          <IconButton onClick={() => setShowQuickView(true)}>
            <VisibilityRoundedIcon />
          </IconButton>
        </Tooltip>
        {underJobDashboard && (
          <Link href={`https://wa.me/${mobileNumber}`} target="_blank">
            <IconButton
              onClick={() => console.log("clicked")}
              disabled={!isValidMobile}
            >
              <WhatsAppIcon
                strokeColor={isValidMobile ? green[500] : grey[500]}
                style={{ width: 20, height: 20 }}
              />
            </IconButton>
          </Link>
        )}
        <EntityMoreActions
          style={{ marginLeft: 0 }}
          options={MenuMoreOptions}
          menuItemClick={menuItemClick}
        />
      </Box>
      {showQuickView && (
        <CandidateQuickView
          onClose={() => setShowQuickView(false)}
          open={showQuickView}
          data={quickViewData}
        />
      )}
    </React.Fragment>
  );
};

export default SingleCandidateActions;
