import React from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";
import errorImage from "../../Assets/Images/ErrorBoundary.png";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    errorMessage: "",
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorMessage) {
    console.log("EB:", error, errorMessage);
    this.setState({ hasError: true, errorMessage: errorMessage });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div>
              <img src={errorImage} alt="ImageError" />
            </div>
            <Typography variant="h3" align="center" color="textPrimary">
              סורי...משהו השתבש לי
            </Typography>
            <Box my={8}>
              <Button variant="contained" color="secondary">
                שלח שגיאה למפתחים
              </Button>
            </Box>
          </Box>
        </Container>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
