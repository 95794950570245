import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  // },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
}));

export default function MySort({ onSortChange, value }) {
  const classes = useStyles();

  const handleSortChange = (event) => {
    onSortChange(event.target.value);
  };

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl} fullWidth>
        <InputLabel id="demo-simple-select-filled-label">סדר לפי:</InputLabel>
        <Select
          labelId="sort-by"
          id="sort-by"
          value={value ? value : "recent"}
          margin="dense"
          disableUnderline
          onChange={handleSortChange}
        >
          <MenuItem value={"recent"}>נוספו לאחרונה</MenuItem>
          <MenuItem value={"first"}>נוספו ראשונים</MenuItem>
          <MenuItem value={"name_asc"}>א-ת</MenuItem>
          <MenuItem value={"name_desc"}>ת-א</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
