import React from "react";
import { Box } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { RatingLabel } from "../../config/candidatesConfigData";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
}));

const CandidateRating = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [ratingValue, setRatingValue] = React.useState(-1);
  const [ratingValueHover, setRatingValueHover] = React.useState(-1);
  return (
    <div className={classes.root}>
      <Rating
        name="hover-feedback"
        value={props.ratingValue ? parseInt(props.ratingValue) : ratingValue}
        readOnly={props.ratingValue > 0}
        precision={1}
        onChange={(event, newValue) => {
          setRatingValue(newValue);
          props.onRatingChange(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setRatingValueHover(newHover);
        }}
      />
      <Box ml={2} style={{ marginRight: theme.spacing(1) }}>
        {props.ratingValue
          ? RatingLabel[props.ratingValue]
          : RatingLabel[
              ratingValueHover !== -1 ? ratingValueHover : ratingValue
            ]}
      </Box>
    </div>
  );
};
export default CandidateRating;
