import React from "react";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import { grey, red } from "@material-ui/core/colors";

const DeleteEditActionButtons = ({
  maxWidth,
  onRemove,
  onEdit,
  readOnlyMode = false,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      maxWidth={maxWidth ? maxWidth : "none"}
      alignItems="center"
    >
      <Tooltip title="עריכה">
        <span>
          <IconButton aria-label="edit" onClick={onEdit} color="secondary">
            {readOnlyMode ? (
              <VisibilityRoundedIcon fontSize="small" />
            ) : (
              <EditRoundedIcon fontSize="small" />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="הסרת השאלה">
        <span>
          <IconButton
            aria-label="delete"
            onClick={onRemove}
            style={!readOnlyMode ? { color: red[500] } : { color: grey[500] }}
            disabled={readOnlyMode}
          >
            <DeleteOutlineRoundedIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};
export default DeleteEditActionButtons;
