import axios from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../reactQuery/rqConstants";

const getUsers = async () => {
  try {
    const usersRequest = await axios.get("/wp-json/api/v2/users");
    return usersRequest.data;
  } catch (err) {
    return err;
  }
};

const useUsers = () => {
  return useQuery(queryKeys.users, getUsers, {
    cacheTime: 600000,
    retry: 2,
    staleTime: 300000,
  });
};
export default useUsers;
