import { Box, IconButton, TextField, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { makeStyles } from "@material-ui/core/styles";
import CopyToClipboard from "react-copy-to-clipboard";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-input": {
      fontSize: "0.75rem",
    },
    "& .MuiTextField-root": {
      fontSize: "11px",
    },
  },
  success: {
    fill: green[500],
  },
}));
const ExternalLinkExportList = ({ jobId, activeFilter }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
  };

  const exportLink = buildExportLink(jobId, activeFilter);
  return (
    <Box display={"flex"} className={classes.root}>
      <Tooltip title="Click to copy">
        <CopyToClipboard text={exportLink} onCopy={handleCopy}>
          <IconButton onClick={handleCopy}>
            {copied ? (
              <CheckCircleRoundedIcon className={classes.success} />
            ) : (
              <FileCopyRoundedIcon />
            )}
          </IconButton>
        </CopyToClipboard>
      </Tooltip>
      {/* <FilledInput
        placeholder={exportLink}
        margin="dense"
        readOnly
        style={{ direction: "ltr" }}
        className={classes.root}
      /> */}
      <TextField
        value={exportLink}
        disabled
        margin="dense"
        className={classes.root}
        helperText="העתק לייצוא רשימה"
      />
    </Box>
  );
};
export default ExternalLinkExportList;

function buildExportLink(jobId, filterType) {
  switch (filterType) {
    case "all":
      return `j-al-${jobId}`;
    case "watched":
      return `j-w-${jobId}`;
    case "autoRejected":
      return `j-rc-${jobId}`;
    case "notSent":
      return `j-ns-${jobId}`;
    case "invitedNotResponded":
      return `j-inr-${jobId}`;
    case "watchedNotResponded":
      return `j-wnr-${jobId}`;
    case "interested":
      return `j-i-${jobId}`;
    case "suitable":
      return `j-sc-${jobId}`;
    case "notInterested":
      return `j-ni-${jobId}`;
    case "cvReferrals":
      return `j-cvr-${jobId}`;
    case "sendToInterview":
      return `j-itv-${jobId}`;
    default:
      return `j-al-${jobId}`;
  }
}
