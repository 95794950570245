import axios from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../reactQuery/rqConstants";

const getAcceptedCities = async () => {
  try {
    const accpetedCitiesRequest = await axios.get(
      "/wp-json/api/v2/accepted_cities"
    );
    return accpetedCitiesRequest.data;
  } catch (err) {
    return err;
  }
};

const useAcceptedCities = () => {
  return useQuery(queryKeys.cities, getAcceptedCities, {
    cacheTime: 600000,
    retry: 2,
    staleTime: 300000,
  });
};
export default useAcceptedCities;
