import React from "react";
import GlobalStyles from "../../../../components/candidatesDashboardGlobalStyles";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Container,
  Box,
  Typography,
  Link,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CandidateTextFieldFilled from "../../../../components/CandidatesDashboardComponents/TextFields/CandidateFilledTextField";
import CandidatesButton from "../../../../components/CandidatesDashboardComponents/Buttons/CandidateButton";
import validator from "validator";
import axios from "axios";
import packageJson from "../../../../../package.json";
import cookies from "js-cookie";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import libiLogin from "../../../../Assets/Images/libi-login.png";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fabProgress: {
    color: "#fff",
    position: "absolute",
    top: 6,
    right: 20,
    zIndex: 1,
  },
  alert: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
});

const emailMobileValidation = (value) => {
  if (!value) {
    return {
      isValid: false,
      message: "יש להזין טלפון או כתובת אימייל",
      type: "unknown",
    };
  }
  //Detect wether this is a valid email:
  if (validator.isEmail(value)) {
    return {
      isValid: true,
      isType: "email",
    };
  }
  //if we are here, email is not valid. Check of mobile
  const mobile = value.replace("-", "");
  if (validator.isMobilePhone(mobile, "he-IL")) {
    return {
      isValid: true,
      isType: "telephone_main",
    };
  }
  return {
    isValid: false,
  };
};

class CandidateLogin extends React.Component {
  state = {
    textTouched: false,
    isValid: true,
    textValue: "",
    isSubmitting: false,
    loginType: "",
    error: null,
    awiatConfirmation: false,
    id: -1,
  };

  componentDidMount() {
    //if the there is a passcode cookie = get the passcode:
    const awiatConfirmation =
      new URLSearchParams(this.props.location.search).get(
        "await_confirmation"
      ) || cookies.get("awc") === "true";

    const id = cookies.get("cand_id");
    const token = cookies.get("token");
    const awc = cookies.get("awc");
    if (token && id) {
      //try to login:
      this.props.history.push("/my-profile/lobby");
      return;
    }
    //if no cookie for await confirmation, we must go to login
    if (awc !== "true") {
      this.props.history.push({
        pathname: "/my-profile/login",
      });
      return;
    }
    //check if we already sent code:
    if (awiatConfirmation) {
      //change url to ?awa
      if (this.props.location.search === "")
        this.props.history.push({
          pathname: "/my-profile/login",
          search: "await_confirmation=true",
        });
      this.setState({
        awiatConfirmation: true,
      });
    } else {
      this.props.history.push({
        pathname: "/my-profile/login",
        search: "",
      });
    }
  }
  handleTextChange = (e) => {
    if (this.state.awiatConfirmation) {
      this.setState({ textValue: e.target.value });
      return;
    }

    const validation = emailMobileValidation(e.target.value);
    validation.isValid
      ? this.setState({
          isValid: true,
          loginType: validation.isType,
          textValue: e.target.value,
        })
      : this.setState({ isValid: false });
    this.setState({
      textValue: e.target.value,
      textTouched: true,
    });
  };

  handleGenerateAuthToken = async () => {
    try {
      this.setState({ isSubmitting: true, error: null });
      const tokenObj = await axios.post(
        "/wp-json/api/v2/generate_login_token",
        {
          id: this.state.id,
          passcode: this.state.textValue,
        }
      );
      cookies.remove("awc", { path: "/my-profile/login" });
      cookies.set("cand_id", tokenObj.data.id, { expires: 3 });
      cookies.set("token", tokenObj.data.token, { expires: 3 });

      //cookies.remove("passcode", { path: "/my-profile/login" });
      this.setState({ isSubmitting: false, error: null });
      //redirect to lobby:
      this.props.history.push("/my-profile/lobby");
    } catch (err) {
      this.setState({ error: err.response, isSubmitting: false });
    }
  };

  handleLogin = () => {
    this.setState({ isSubmitting: true });
    axios
      .post("/wp-json/api/v2/generate_login_passcode", {
        loginBy: this.state.loginType,
        loginData: this.state.textValue,
      })
      .then((res) => {
        cookies.set("awc", true, {
          expires: 0.1,
          path: "/my-profile/login",
          domain: packageJson.homepage ? packageJson.homepage : "",
        });
        this.setState({
          isSubmitting: false,
          error: null,
          awiatConfirmation: true,
          passcode: res.data.passcode,
          id: parseInt(res.data.id),
          textValue: "",
        });
        this.props.history.push({
          pathname: "/my-profile/login",
          search: "await_confirmation=true",
        });
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
          error: err.response,
          textTouched: false,
          isValid: true,
          textValue: "",
          loginType: "",
          awiatConfirmation: false,
        });
        cookies.remove("passcode", { path: "/my-profile/login" });
        cookies.remove("cand_id");
      });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <React.Fragment>
        <GlobalStyles />

        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="xs">
            <Paper elevation={3} className={classes.paper}>
              <Box
                display="flex"
                justifyContent="center"
                marginBottom={theme.spacing(0.5)}
              >
                <img src={libiLogin} alt="Candidates Login" />
              </Box>
              <Typography
                align="center"
                variant="h4"
                style={{ color: "#363740", marginBottom: theme.spacing(4) }}
              >
                התחברות למערכת ליבי
              </Typography>
              {!this.state.awiatConfirmation ? (
                <React.Fragment>
                  <CandidateTextFieldFilled
                    id="filled-basic"
                    label="הזן מספר נייד או כתובת אימייל"
                    variant="filled"
                    value={this.state.textValue}
                    onChange={this.handleTextChange}
                    disabled={this.state.error}
                    error={!this.state.isValid}
                    helperText={
                      !this.state.isValid ? "הערך המוזן אינו תקין" : ""
                    }
                    autoFocus={true}
                  />
                  <Box my={2}>
                    <CandidatesButton
                      color="primary"
                      disabled={
                        !this.state.isValid ||
                        this.state.textValue.length < 5 ||
                        this.state.isSubmitting ||
                        this.state.error !== null
                      }
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={this.handleLogin}
                      style={{ position: "relative" }}
                    >
                      {this.state.isSubmitting && (
                        <CircularProgress
                          size={30}
                          className={classes.fabProgress}
                        />
                      )}{" "}
                      שלחו לי קוד
                    </CandidatesButton>
                    <div className={classes.alert}>
                      <Collapse in={this.state.error}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                this.setState({ error: null, textValue: "" });
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          לא הצלחנו לאתר אותך
                        </Alert>
                      </Collapse>
                    </div>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <CandidateTextFieldFilled
                    id="filled-basic"
                    label="הזן קוד כניסה זמני"
                    helperText={`קוד כניסה בן 4 ספרות נשלח אליכם ל${
                      this.state.loginType === "email" ? "אימייל" : "נייד"
                    }`}
                    variant="filled"
                    value={this.state.textValue}
                    onChange={this.handleTextChange}
                    autoFocus={true}
                  />
                  <CandidatesButton
                    color="primary"
                    disabled={this.state.textValue.length !== 4}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={this.handleGenerateAuthToken}
                    style={{
                      position: "relative",
                      marginTop: theme.spacing(2),
                    }}
                  >
                    {this.state.isSubmitting && (
                      <CircularProgress
                        size={30}
                        className={classes.fabProgress}
                      />
                    )}{" "}
                    התחברות
                  </CandidatesButton>
                  <div className={classes.alert}>
                    <Collapse in={this.state.error}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({ error: null, textValue: "" });
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        קוד כניסה שגוי. נסה שנית
                      </Alert>
                    </Collapse>
                  </div>
                </React.Fragment>
              )}
            </Paper>
            <Typography
              color="textSecondary"
              variant="body1"
              style={{ marginTop: theme.spacing(2) }}
            >
              עדיין אין לך חשבון?{" "}
              <Link href="/my-profile/signup" color="secondary">
                הירשם עכשיו
              </Link>
            </Typography>
          </Container>
        </Box>
      </React.Fragment>
    );
  }
}
export default withStyles(styles, { withTheme: true })(
  withRouter(CandidateLogin)
);
