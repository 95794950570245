import React from "react";
import { Typography, Box, CircularProgress, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import AxiosErrorHandling from "../AxiosErrorHandling/AxiosErrorHandling";
import QuestionierDistResultsGraph from "../Charts/QuestionierDistResultsGraph";

function arrangeResults(dataArr) {
  const arr = dataArr.reduce((qresults, qres) => {
    const { question_key, question_id, pass_result } = qres;
    const objName =
      question_id > -1 ? `${question_key} (${question_id})` : question_key;

    const elemNameIndex = qresults.findIndex((obj) => obj.name === objName);
    if (elemNameIndex < 0) {
      //create new object
      const newObj = {
        name: objName,
        pass: pass_result === "passed" ? 1 : 0,
        failed: pass_result === "failed" ? 1 : 0,
        partial: pass_result === "partial" ? 1 : 0,
      };

      qresults = [...qresults, newObj];
    } else {
      const objCopy = qresults[elemNameIndex];
      objCopy.pass = pass_result === "passed" ? objCopy.pass + 1 : objCopy.pass;
      objCopy.failed =
        pass_result === "failed" ? objCopy.failed + 1 : objCopy.failed;
      objCopy.partial =
        pass_result === "partial" ? objCopy.partial + 1 : objCopy.partial;

      qresults[elemNameIndex] = objCopy;
    }

    return qresults;
  }, []);
  return arr;
}

const JobQuestionierResultStats = ({ jobId }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/wp-json/api/v2/questionier_dist_stats/${jobId}`)
      .then((res) => {
        setIsLoading(false);
        setData(arrangeResults(res.data));
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response);
      });
  }, [jobId]);
  return (
    <Paper>
      <Box textAlign="center">
        <Typography variant="h5">
          פילוח תשובות מועמדים לפי שאלות סינון
        </Typography>
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : (
          data && <QuestionierDistResultsGraph chartData={data} />
        )}
        {error && <AxiosErrorHandling error={error} />}
      </Box>
    </Paper>
  );
};

JobQuestionierResultStats.propTypes = {
  jobId: PropTypes.number.isRequired,
};
export default JobQuestionierResultStats;
