import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CoordinatorsDropdownMultipleSelection from '../CoordinatorsDropdown/CoordinatorsDropdownMultipleSelection'
import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Switch,
  Collapse,
} from "@material-ui/core";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      minWidth: 120,
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
    },
  },
  svgDuplicateIcon: {
    color: "#FC6E00",
  },
}));

export default function ConfrimNewCandidatesNotification({
  open,
  onClose,
  onConfirm,
  isNewCandNotification,
  jobId,
  forwardNotifications,
}) {
  const [showForwardNotifications, setShowFortwardNotifications] =
    useState(false);
  const [selectedRecruitersForNotifications, setSelectedRecruitersForNotifications] = useState([]);
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <Box
        py={theme.spacing(2)}
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <NotificationsRoundedIcon fontSize="80" />
      </Box>
      <Box p={2} justifyContent="center" display="flex" alignItems="center">
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom={true}
        >
          {`קבלת התראות על מועמדים מתאימים חדשים עבור משרה ${jobId}`}
        </Typography>
      </Box>

      <DialogContent>
        <Box>
          {isNewCandNotification ? (
            <React.Fragment>
              <Typography variant="body1" color="textSecondary">
                {`ביטול קבלת התראות על מועמדים חדשים מתאימים עבור משרה ${jobId}`}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                עם אישור הודעה זו, לא יופצו אימיילים עם איתור מועמדים מתאימים
                חדשים
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant="body1" color="textSecondary">
                {`האם לשלוח אימייל בעת קבלת מועמדים מתאימים חדשים עבור משרה  ${jobId}?`}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                סימון קבלת התראות ישלח מייל לחשבונך מיד עם התווספות מועמד מתאים
                חדש למשרה זו
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={showForwardNotifications}
                    onChange={() =>
                      setShowFortwardNotifications((prev) => !prev)
                    }
                    name="forwardNotifications"
                    color="primary"                    
                  />
                }
                label="העברת התראות"
              />
              <Collapse in={showForwardNotifications}>
                <Box py={2}>
                <CoordinatorsDropdownMultipleSelection
                      label="בחירת רכזות לקבלת התראות"
                      onRecruiterChange={(val)=> setSelectedRecruitersForNotifications(val)}
                      selectedRecruiters={selectedRecruitersForNotifications}
                      fullWidth={true}
                    />
                </Box>
              </Collapse>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onConfirm(!isNewCandNotification, selectedRecruitersForNotifications)}
          color="primary"
        >
          {isNewCandNotification ? "כיבוי" : "אישור"}
        </Button>
        <Button variant="outlined" onClick={onClose} color="primary">
          ביטול פעולה
        </Button>
      </DialogActions>
    </Dialog>
  );
}
