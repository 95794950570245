import React, { PureComponent } from "react";
import { ThemeColorsSet2 } from "../../config/dashboardConfigData";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class PieChartGraph extends PureComponent {
  state = {
    opacity: {
      uv: 0,
      pv: 0,
    },
  };

  handleMouseEnter = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 0.5 },
    });
  };

  handleMouseLeave = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 1 },
    });
  };

  render() {
    return (
      <ResponsiveContainer width={"100%"} height={240}>
        <PieChart fontSize={14}>
          <Tooltip cursor={false} />
          <Pie
            data={this.props.chartData}
            labelLine={false}
            label={renderCustomizedLabel}
            innerRadius={25}
            dataKey={"value"}
            nameKey={"name"}
          >
            {this.props.chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={ThemeColorsSet2[index % ThemeColorsSet2.length]}
              />
            ))}
          </Pie>
          <Tooltip
            labelFormatter={(value, name, props) => {
              console.log("TT", value, name, props);
              return name + " " + parseFloat(value).toFixed(2);
            }}
          />
          <Legend verticalAlign="bottom" height={36} iconSize={14} />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
