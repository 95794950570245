export const DashboardPeriod = [
  { id: 0, label: "היום", value: "today" },
  { id: 1, label: "אתמול", value: "yesterday" },
  { id: 2, label: "7 ימים אחרונים", value: "last7" },
  { id: 3, label: "28 ימים אחרונים", value: "last28" },
  { id: 4, label: "90 ימים אחרונים", value: "last90" },
  { id: 5, label: "השנה", value: "thisYear" },
  { id: 6, label: "מותאם אישית", value: "custom" },
  { id: 7, label: "כל הזמן", value: "allTimes" },
];

export const ThemeColorsSet1 = [
  "#A12CFF",
  "#00B2FF",
  "#0DD9A2",
  "#16C542",
  "#FC6E00",
  "#AC92EC",
  "#E51010",
];
export const ThemeColorsSet2 = [
  "#032B20",
  "#088261",
  "#0DD9A2",
  "#AFFAE6",
  "#ECFEF9",
  "#F5F6F8",
];

export const DetectWelcomeMessage = () => {
  const hour = new Date().getHours();
  let message = "";
  switch (hour) {
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
      message = "בוקר טוב";
      break;
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
      message = "צהריים טובים";
      break;
    case 17:
    case 18:
    case 19:
    case 20:
      message = "ערב טוב";
      break;
    default:
      message = "לילה טוב";
      break;
  }
  return message;
};
