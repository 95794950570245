import React from "react";
import { Button, Box } from "@material-ui/core";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
  },
  buttonYes: {
    color: green[800],
    borderColor: green[800],
    "&:hover": {
      backgroundColor: green[50],
    },
  },
  buttonNo: {
    color: red[900],
  },
}));

const CancelApproveButtons = ({
  positiveCaption,
  negativeCaption,
  onPositiveClick,
  onNegativeClick,
  positiveDisabled = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Button
        className={classes.buttonNo}
        endIcon={<CloseRoundedIcon />}
        onClick={onNegativeClick}
      >
        {negativeCaption}
      </Button>
      <Button
        variant="outlined"
        className={classes.buttonYes}
        endIcon={<DoneRoundedIcon />}
        onClick={onPositiveClick}
        disabled={positiveDisabled}
      >
        {positiveCaption}
      </Button>
    </Box>
  );
};
export default CancelApproveButtons;
