export function rebuildExperienceFQObject(obj) {
  let localExperienceFQObject = [];
  let experienceId = 0;
  let selectedProfession = "";
  let minYears = 0;
  let isAMust = false;
  let addInfo = "";
  let tags = [];

  const expReducedArray = obj.reduce((acc, item) => {
    const { fq_sub_type_id } = item;
    acc[fq_sub_type_id] = acc[fq_sub_type_id]
      ? [...acc[fq_sub_type_id], item]
      : [item];
    return acc;
  }, []);
  expReducedArray.forEach((fqSubType) => {
    selectedProfession = getSingleItemValue(fqSubType, "selectedProfession");
    experienceId = fqSubType.find((fq) => fq.fq_key === "selectedProfession")
      .fq_sub_type_id;
    minYears = parseInt(getSingleItemValue(fqSubType, "minYears"));
    isAMust = parseInt(getSingleItemValue(fqSubType, "isAMust"));
    addInfo = getSingleItemValue(fqSubType, "additionalInformation");
    tags = getRealtedTags(fqSubType);

    localExperienceFQObject = [
      ...localExperienceFQObject,
      {
        experienceId,
        selectedProfession,
        minYears,
        isAMust,
        addInfo,
        tags,
      },
    ];
  });
  return localExperienceFQObject;
}

/*
  Single skills Object: 
  {
      skillId: id,
      skillName: string,
      minYearsSkill: num,
      isSkillAMust: boolean,
      knowledgeLevelRequired: string
  }
  */

export function rebuildTalentsFQObject(obj) {
  let talentsFQObject = [];
  let skillName = "";
  let skillId = -1;
  let isSkillAMust = false;
  let addInfo = "";
  let knowledgeLevel = "";
  let tags = [];
  if (parseInt(Object.keys(obj).length)) {
    //find the count of skills:
    const count = obj.filter((item) => item.fq_key === "skillName").length;
    for (let i = 0; i < count; i++) {
      const single = obj.filter(
        (item) => parseInt(item.fq_sub_type_id) === i + 1
      );
      if (single.length) {
        // const nameIndex = single.findIndex((skl) => skl.fq_key === "skillName");
        // if (nameIndex > -1) skillName = single[nameIndex].fq_value;
        skillName = getSingleItemValue(single, "skillName");
        skillId = parseInt(
          single.find((row) => row.fq_key === "skillName").fq_sub_type_id
        );
        knowledgeLevel = getSingleItemValue(single, "knowledgeLevel");
        isSkillAMust =
          getSingleItemValue(single, "isSkillAMust") === "1" ? true : false;

        addInfo = getSingleItemValue(single, "additionalInformation");
        tags = getRealtedTags(single);
      }

      talentsFQObject = [
        ...talentsFQObject,
        {
          skillId,
          skillName,
          isSkillAMust,
          knowledgeLevelRequired: knowledgeLevel,
          addInfo,
          tags,
        },
      ];
    }
  }
  return talentsFQObject;
}

/*
  Single license Object: 
  {
      licenseId: AutoID
      licenseOn: string: license type,
      minYearsLicense: num,
      isLicenseAMust: boolean,
      isVehicalManual: boolean,
      isNotYouth: boolean
  }
  */
export function rebuildLicensesFQObject(obj) {
  let licensesFQObject = [];
  let licenseOn = "";
  let licenseId = -1;
  let isLicenseAMust = false;
  let minYearsLicense = 0;
  let isVehicalManual = false;
  let isNotYouth = false;
  let addInfo = "";
  let tags = [];

  if (parseInt(Object.keys(obj).length)) {
    //find the count of skills:
    const count = obj.filter((item) => item.fq_key === "licenseOn").length;
    for (let i = 0; i < count; i++) {
      const single = obj.filter(
        (item) => parseInt(item.fq_sub_type_id) === i + 1
      );

      licenseOn = getSingleItemValue(single, "licenseOn");
      licenseId = parseInt(
        single.find((row) => row.fq_key === "licenseOn").fq_sub_type_id
      );
      minYearsLicense = parseInt(getSingleItemValue(single, "minYearsLicense"));
      isLicenseAMust =
        getSingleItemValue(single, "isLicenseAMust") === "true" ||
        getSingleItemValue(single, "isLicenseAMust") === "1"
          ? true
          : false;
      isNotYouth =
        getSingleItemValue(single, "isVehicalManual") === "true" ? true : false;
      addInfo = getSingleItemValue(single, "additionalInformation");
      tags = getRealtedTags(single);
      licensesFQObject = [
        ...licensesFQObject,
        {
          licenseId,
          licenseOn,
          minYearsLicense,
          isLicenseAMust,
          isVehicalManual,
          isNotYouth,
          addInfo,
          tags,
        },
      ];
    }
  }
  return licensesFQObject;
}

/* Academic Degree Object  
  Academic Degree Object:
  degreeId: id
  selectedDegree = string,
  degreeType = string,
  isAMust = boolean
  */
export function rebuildAcademicDegreeFQObject(obj) {
  let academicDegreeFQObject = [];
  let selectedDegree = "";
  let degreeType = "";
  let isDegreeAMust = false;
  let degreeId = 0;
  let addInfo = "";
  let tags = [];

  if (parseInt(Object.keys(obj).length)) {
    //find the count of skills:
    const count = obj.filter((item) => item.fq_key === "selectedDegree").length;
    for (let i = 0; i < count; i++) {
      const single = obj.filter(
        (item) => parseInt(item.fq_sub_type_id) === i + 1
      );
      if (single.length) {
        selectedDegree = getSingleItemValue(single, "selectedDegree");
        degreeId = parseInt(
          single.find((row) => row.fq_key === "selectedDegree").fq_sub_type_id
        );
        degreeType = getSingleItemValue(single, "degreeType");
        isDegreeAMust =
          getSingleItemValue(single, "isDegreeAMust") === "1" ? true : false;

        addInfo = getSingleItemValue(single, "additionalInformation");
        tags = getRealtedTags(single);
      }

      academicDegreeFQObject = [
        ...academicDegreeFQObject,
        {
          degreeId,
          selectedDegree,
          degreeType,
          isDegreeAMust,
          addInfo,
          tags,
        },
      ];
    }
  }
  return academicDegreeFQObject;
}

/* Certificate Object
  selectedCertificate = string,  
  isCertificateAMust = boolean

  */
export function rebuildCertificateFQObject(obj) {
  let cerfificateFQObject = [];
  let selectedCertificate = "";
  let isCertificateAMust = false;
  let certificateId = 0;
  let addInfo = "";
  let tags = [];

  if (parseInt(Object.keys(obj).length)) {
    //find the count of skills:
    const count = obj.filter((item) => item.fq_key === "selectedCertificate")
      .length;
    for (let i = 0; i < count; i++) {
      const single = obj.filter(
        (item) => parseInt(item.fq_sub_type_id) === i + 1
      );
      if (single.length) {
        selectedCertificate = getSingleItemValue(single, "selectedCertificate");
        certificateId = parseInt(
          single.find((row) => row.fq_key === "selectedCertificate")
            .fq_sub_type_id
        );

        isCertificateAMust =
          getSingleItemValue(single, "isCertificateAMust") === "1"
            ? true
            : false;

        addInfo = getSingleItemValue(single, "additionalInformation");
        tags = getRealtedTags(single);
      }

      cerfificateFQObject = [
        ...cerfificateFQObject,
        {
          certificateId,
          selectedCertificate,
          isCertificateAMust,
          addInfo,
          tags,
        },
      ];
    }
  }
  return cerfificateFQObject;
}

/* Languages Object 
  languageId = number,
  languageName = string,
  languageLevel = string,
  languageIsAMust = bool,
  */
export function rebuildLanguagesFQObject(obj) {
  let localLanguagesFQObject = [];
  let languageId = 0;
  let languageName = "";
  let languageLevel = "";
  let languageIsAMust = false;
  let addInfo = "";
  let tags = [];

  const langReducedArray = obj.reduce((acc, item) => {
    const { fq_sub_type_id } = item;
    acc[fq_sub_type_id] = acc[fq_sub_type_id]
      ? [...acc[fq_sub_type_id], item]
      : [item];
    return acc;
  }, []);
  langReducedArray.forEach((single) => {
    languageName = getSingleItemValue(single, "languageName");
    languageId = parseInt(
      single.find((fq) => fq.fq_key === "languageName").fq_sub_type_id
    );
    languageLevel = getSingleItemValue(single, "languageLevel");
    languageIsAMust = parseInt(getSingleItemValue(single, "languageIsAMust"));
    addInfo = getSingleItemValue(single, "additionalInformation");
    tags = getRealtedTags(single);

    localLanguagesFQObject = [
      ...localLanguagesFQObject,
      {
        languageId,
        languageName,
        languageLevel,
        languageIsAMust,
        addInfo,
        tags,
      },
    ];
  });

  return localLanguagesFQObject;
}

export function rebuildDynamicsFQObject(obj) {
  let localDynamicFQObject = [];
  let dynamicId = 0;
  let qType = "";
  let displayQuestion = "";
  let passAnswer = "";
  let isAMust = false;
  obj.forEach((dynamicItem) => {
    displayQuestion = dynamicItem.dfq_display_question;
    dynamicId = parseInt(dynamicItem.dfq_question_subtype_id);
    passAnswer = dynamicItem.dfq_pass_answer;
    isAMust = parseInt(dynamicItem.dfq_questionIsAMust) === 1 ? true : false;
    qType = dynamicItem.dfq_question_type;
    localDynamicFQObject = [
      ...localDynamicFQObject,
      {
        dynamicId,
        displayQuestion,
        qType,
        isAMust,
        passAnswer,
        tags: dynamicItem["tags"],
      },
    ];
  });

  return localDynamicFQObject;
}

export function rebuildSalaryLocationFQObject(obj) {
  let tags = [];
  let additionalInfo = "";
  additionalInfo = getSingleItemValue(obj, "additionalInformation");
  tags = getRealtedTags(obj);
  return {
    additionalInfo,
    tags,
  };
}

const getRealtedTags = (obj) => {
  const tagsArr = obj.filter((item) => item.fq_key === "relatedFQTag");
  return tagsArr.map((item) => parseInt(item.fq_value));
};

const getSingleItemValue = (rows, key) => {
  const keyIndex = rows.findIndex((item) => item.fq_key === key);
  if (keyIndex > -1) return rows[keyIndex].fq_value;

  return false;
};
