import React, { useContext } from "react";
import { Box, Checkbox, TextField, FormControlLabel } from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import ConfigurationListsContext from "../../context/configurationListsContext";

const RecruitersMultipleSelection = ({
  label,
  selectedRecruiters,
  onRecruiterChange,
  fullWidth=false
}) => {
  const usersContext = useContext(ConfigurationListsContext);
  if (!usersContext.users) return <div></div>;
  const userIdAndLabel = usersContext.users
    .sort((a, b) => (a.display_name > b.display_name ? 1 : -1))
    .map((user) => {
      return {
        id: user.id,
        display_name: user.display_name,
      };
    });

  return (
    <Box width={fullWidth ? '100%' : 400}>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={userIdAndLabel}
        fullWidth={fullWidth}
        disableCloseOnSelect
        getOptionLabel={(user) => user.display_name}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(event, value) =>
          onRecruiterChange(value.map((user) => (user.id ? user.id : user)))
        }
        limitTags={3}
        value={selectedRecruiters ? selectedRecruiters.map((ss) => {
          const index = userIdAndLabel.findIndex((usr) => usr.id === ss);
          return index > -1 ? userIdAndLabel[index] : [];
        }) : []}
        renderOption={(props, user, state) => (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedRecruiters && selectedRecruiters.includes(props.id)}
                  color="primary"
                />
              }
              label={props.display_name}
            />
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...params}
            label={label}
            placeholder="רכז.ת"
          />
        )}
      />
    </Box>
  );
};

export default RecruitersMultipleSelection;
