import React from "react";
import { Typography, Box, CircularProgress, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import PieChart from "../Charts/PieChart";
import AxiosErrorHandling from "../AxiosErrorHandling/AxiosErrorHandling";

const JobByGenderGraph = ({ jobId }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/wp-json/api/v2/job_by_gender/${jobId}`)
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response);
      });
  }, [jobId]);
  return (
    <Paper>
      <Box textAlign="center">
        <Typography variant="h5">מועמדים לפי מגדר</Typography>
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : (
          <div style={{ direction: "ltr" }}>
            {data && <PieChart chartData={data} />}
          </div>
        )}
        {error && <AxiosErrorHandling error={error} />}
      </Box>
    </Paper>
  );
};

JobByGenderGraph.propTypes = {
  jobId: PropTypes.number.isRequired,
};
export default JobByGenderGraph;
