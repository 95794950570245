import React, { Fragment } from "react";
import {
  makeStyles,
  Slide,
  Paper,
  Typography,
  Modal,
  Container,
  Box,
  TextField,
  useTheme,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import { ReadOnlyFilterRowByType } from "./LibiExplore";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    padding: theme.spacing(2, 4, 3),
    "& .MuiPaper-root": {
      padding: theme.spacing(3),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      background: pink[50],
      color: theme.palette.primary.main,
      border: "none",
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "inline-block",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SaveSearch = ({ open, close, searchData, onSearch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [searchName, setSearchName] = React.useState("");
  const [saveAsPublic, setSaveAsPublic] = React.useState(false);

  const handleChangePublicState = () => {
    setSaveAsPublic(!saveAsPublic);
  };

  const collectSearchData = () => {
    onSearch({
      name: searchName,
      isPublic: saveAsPublic,
    });
  };

  return (
    <Modal
      open={open}
      onClose={close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="md">
        <div className={classes.root}>
          <Slide
            direction="down"
            in={open}
            mountOnEnter
            unmountOnExit
            onExit={close}
          >
            <Paper elevation={4}>
              <React.Fragment>
                <Typography variant="h4" paragraph>
                  הוספת חיפוש למועדפים
                </Typography>
                <Grid container spacing={2}>
                  {searchData.length > 0 && (
                    <Fragment>
                      <Grid item xs={2}>
                        <Typography variant="body1" color="textSecondary">
                          סוג הסינון
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1" color="textSecondary">
                          פרטי הסינון
                        </Typography>
                      </Grid>
                    </Fragment>
                  )}
                  {searchData.map((searchItem) => (
                    <ReadOnlyFilterRowByType
                      filterRow={searchItem}
                      key={searchItem.id}
                      //onRemove={handleRemoveFilterItem}
                      withActions={false}
                    />
                  ))}
                </Grid>

                <Box my={theme.spacing(4)}>
                  <TextField
                    variant="filled"
                    label="שם החיפוש"
                    value={searchName}
                    fullWidth
                    onChange={(event) => setSearchName(event.target.value)}
                  />
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  my={theme.spacing(4)}
                >
                  <Button variant="outlined" color="primary" onClick={close}>
                    בטל
                  </Button>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveAsPublic}
                          onChange={handleChangePublicState}
                          name="savePublic"
                          color="primary"
                        />
                      }
                      label="הצג כציבורי"
                      style={{ marginLeft: theme.spacing(2) }}
                    />
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={collectSearchData}
                        disabled={searchName === "" || searchData.length === 0}
                      >
                        שמירה
                      </Button>

                      {/* <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      /> */}
                    </div>
                  </div>
                </Box>
              </React.Fragment>
            </Paper>
          </Slide>
        </div>
      </Container>
    </Modal>
  );
};
export default SaveSearch;
