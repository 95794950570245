import React from "react";
import {
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
} from "@material-ui/core";

const JobDetailsViewStep1 = ({
  selectedCompany,
  discreteCompany,
  employmentType,
  jobTitle,
  jobDescription,
  jobReqs,
}) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <TextField margin="normal" value={selectedCompany} label="שם החברה" />
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormControlLabel
          label="חברה דיסקרטית"
          control={
            <Switch
              checked={discreteCompany}
              color="primary"
              name="DiscreteCompany"
            />
          }
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl component="fieldset" disabled>
          <FormLabel>סוג העסקה</FormLabel>
          <RadioGroup
            aria-label="employmentType"
            name="employmentType"
            value={employmentType}
            row
          >
            <FormControlLabel
              value="recruitment"
              control={<Radio color="primary" />}
              label="השמה"
            />
            <FormControlLabel
              value="employment"
              control={<Radio color="primary" />}
              label="העסקה"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          label="שם המשרה"
          id="jobTitle"
          margin="normal"
          fullWidth
          value={jobTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="job-description"
          label="תיאור המשרה"
          InputLabelProps={{
            shrink: jobDescription !== "",
          }}
          multiline
          fullWidth
          rows={5}
          defaultValue={jobDescription}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="job-requirements"
          label="דרישות המשרה"
          multiline
          fullWidth
          rows={5}
          InputLabelProps={{
            shrink: jobReqs !== "",
          }}
          defaultValue={jobReqs}
        />
      </Grid>
    </Grid>
  );
};
export default JobDetailsViewStep1;
