import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withRouter } from "react-router-dom";
import { grey } from "@material-ui/core/colors";
import {
  Drawer,
  Avatar,
  List,
  Button,
  Typography,
  Divider,
  IconButton,
  Container,
  Grid,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MainMenuItems from "../Screens/Dashboard/MainMenuItems";
import Version from "../components/Version/Version";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    "& .MuiAvatar-root": {
      width: 65,
      height: 65,
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  typography: {
    direction: "rtl",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    marginTop: theme.spacing(1),
  },

  menuButton: {
    marginLeft: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const WithMenuContainer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true); //drawer

  const handleDrawerState = () => {
    setOpen(!open);
  };

  const handleLogout = (event) => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("userDisplayName");
    localStorage.removeItem("userImage");
    props.history.push("/login");
  };

  function getProfileImage() {
    return localStorage.getItem("userImage") !== ""
      ? localStorage.getItem("userImage")
      : "";
  }
  function getInitials() {
    if (!localStorage.getItem("userDisplayName")) return;
    const initialsArray = localStorage.getItem("userDisplayName")
      ? localStorage.getItem("userDisplayName").split(" ")
      : "";
    return initialsArray.length > 1
      ? initialsArray[0].charAt(0).toUpperCase() +
          initialsArray[1].charAt(0).toUpperCase()
      : initialsArray[0].charAt(0).toUpperCase();
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        style={{
          boxShadow: "-6px 0px 18px rgba(82, 83, 93, 0.2)",
          zIndex: 101,
        }}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerState}>
            {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <Grid item style={{ textAlign: "center" }}>
            <Avatar
              style={{ cursor: "pointer", margin: "auto" }}
              //onClick={(e) => setAnchorAvatar(e.currentTarget)}
              //onMouseOver={(e) => setAnchorAvatar(e.currentTarget)}
              src={getProfileImage()}
            >
              {localStorage.getItem("userImage") ? "" : getInitials()}
            </Avatar>
            <Typography
              component="p"
              variant="subtitle2"
              noWrap
              className={classes.avaterText}
              style={{ color: grey[700] }}
            >
              שלום {localStorage.getItem("userDisplayName")}{" "}
            </Typography>
            <Button color="primary" onClick={handleLogout}>
              יציאה
            </Button>
          </Grid>
        </Grid>

        <Divider />
        <List>
          <MainMenuItems
            list="main"
            //screen={activeScreen}
            //setActiveScreen={handleChangeScreen}
          />
        </List>
        <Divider />
        <List>
          <MainMenuItems list="secondary" />
        </List>
        <Divider />
        <List>
          <MainMenuItems list="third" />
        </List>
        <div style={{ position: "absolute", bottom: 10, width: "100%" }}>
          <Version />
        </div>
      </Drawer>
      <main className={classes.content}>
        <Container maxWidth="xl" disableGutters>
          {props.children}
        </Container>
      </main>

      {!localStorage.getItem("token") ? props.history.push("/login") : null}
    </div>
  );
};
export default withRouter(WithMenuContainer);
