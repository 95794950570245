import React from "react";
import {
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Checkbox,
  Collapse,
  Box,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-fullWidth": {
      minWidth: 150,
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.grey[500],
    },
  },
}));

const JobDetailsViewStep2 = ({
  authorName,
  coordinatorDisplayName,
  professions,
  jobExtent,
  jobArea,
  jobCity,
  salaryMin,
  salaryMax,
  discreteSalary,
  isOpenSalaryQuestion,
  openSalaryValueBy,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={6}>
        <TextField
          required
          label="משרה בבעלות"
          margin="none"
          fullWidth
          value={authorName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          label="שם לתצוגה"
          id="displayName"
          margin="none"
          fullWidth
          value={coordinatorDisplayName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          label="מקצועות רלוונטיים"
          id="professions"
          margin="normal"
          fullWidth
          value={professions}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          label="היקף המשרה"
          id="jobExtent"
          margin="normal"
          fullWidth
          value={jobExtent}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          label="אזור המשרה"
          id="jobExtent"
          margin="normal"
          fullWidth
          value={jobArea}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="עיר"
          id="jobCity"
          margin="normal"
          fullWidth
          value={jobCity ? jobCity : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationCityRoundedIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="minimumAmount">שכר החל מ</InputLabel>
          <OutlinedInput
            id="minimumAmount"
            value={salaryMin}
            type="number"
            inputProps={{ min: 0, step: 500 }}
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            labelWidth={80}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="maximumAmount">שכר מקסימום</InputLabel>
          <OutlinedInput
            id="maximumAmount"
            value={salaryMax}
            type="number"
            inputProps={{ min: 0, step: 500 }}
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            labelWidth={90}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          label="שכר דיסקרטי"
          control={
            <Switch
              checked={discreteSalary}
              color="primary"
              name="DiscreteJob"
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isOpenSalaryQuestion}
              color="primary"
              name="openSalaryQuestion"
            />
          }
          label="הצג שאלת שכר פתוחה"
        />
      </Grid>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Box display="flex" alignItems="center">
          <Collapse in={isOpenSalaryQuestion}>
            <FormLabel
              component="legend"
              style={{ marginLeft: theme.spacing(2) }}
            >
              חשב את ערך השכר לפי:
            </FormLabel>
            <RadioGroup
              aria-label="openSalaryBy"
              name="openSalaryBy"
              value={openSalaryValueBy}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value="month"
                control={<Radio color="primary" />}
                label="שכר חודשי"
              />
              <FormControlLabel
                value="hour"
                control={<Radio color="primary" />}
                label="שכר שעתי"
              />
            </RadioGroup>
          </Collapse>
        </Box>
      </Grid>
    </Grid>
  );
};
export default JobDetailsViewStep2;
