import {
  Box,
  makeStyles,
  Typography,
  Paper,
  useTheme,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const ScorecardJobItem = ({ label, value, total, totalUnit = "%", color }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Paper
      style={{
        position: "relative",
        overflow: "hidden",
        color: "#333",
        height: 160,
        width: 160,
        borderRadius: 8,
      }}
      elevation={1}
    >
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        height="100%"
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: 0,
            left: 0,
            height: 6,
            background: color ? color : "#FFCE54",
          }}
        ></div>
        <Box mt={theme.spacing(4)} px={theme.spacing(1)}>
          <Typography
            variant="body2"
            align="center"
            style={{
              marginBottom: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
            display="initial"
          >
            {label}
          </Typography>

          <Box textAlign="center" mt={theme.spacing(2)}>
            <Typography
              variant="h4"
              align="center"
              className={classes.value}
              style={{ color: color }}
            >
              {value}
            </Typography>
            {total && total > 0 && (
              <Typography
                variant="body2"
                align="center"
                style={{ fontSize: 16, color: theme.palette.grey[500] }}
              >
                {`${total}${totalUnit}`}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ScorecardJobItem;
