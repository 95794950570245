import React from "react";
import {
  makeStyles,
  useTheme,
  Tabs,
  Box,
  Tab,
  Typography,
} from "@material-ui/core";
import { pink } from "@material-ui/core/colors";

const TabsList = [
  { id: "suitable", label: "מועמדים מתאימים", showBeforeDistribution: false },
  { id: "rejected", label: "מועמדים שנדחו", showBeforeDistribution: false },
  { id: "all", label: "כל המועמדים", showBeforeDistribution: true },
  {
    id: "cvReferrals",
    label: "הועברו קורות חיים",
    showBeforeDistribution: false,
  },
  {
    id: "responseRate",
    label: "נתוני צפייה ותגובה",
    showBeforeDistribution: false,
  },
  // { id: "recruitments", label: "נתוני גיוס", showBeforeDistribution: false },
  // { id: "statistics", label: "סטטיסטיקה מלאה", showBeforeDistribution: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-indicator": {
      height: 4,
      borderRadius: 4,
    },
  },
  totalValue: {
    width: 25,
    height: 25,
    backgroundColor: pink[50],
    color: theme.palette.primary.main,
    borderRadius: "50%",
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const CandidateDashboardTabs = ({
  onChange,
  value,
  isBeforeDistribution,
  totalCandidates,
  totalSuitable,
  totalRejected,
  totalCVReferrals,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const tabRendedFilter = () => {
    return isBeforeDistribution
      ? TabsList.filter((bd) => bd.showBeforeDistribution)
      : TabsList;
  };

  function getTotal(id) {
    switch (id) {
      case "suitable":
        return totalSuitable;
      case "rejected":
        return totalRejected;
      case "cvReferrals":
        return totalCVReferrals;
      case "all":
        return totalCandidates;

      default:
        break;
    }
  }

  return (
    <Box my={theme.spacing(2)}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={onChange}
        className={classes.root}
      >
        {tabRendedFilter().map((tab, index) => {
          return (
            <Tab
              label={<CustomTab total={getTotal(tab.id)} label={tab.label} />}
              key={tab.id}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default CandidateDashboardTabs;

export const CustomTab = ({ total, label }) => {
  const classes = useStyles();
  return total ? (
    <Box justifyContent="center" alignItems="center" display="flex">
      <Typography variant="body1" style={{ fontWeight: 500 }}>
        {label}
      </Typography>
      <div className={classes.totalValue}>{total}</div>
    </Box>
  ) : (
    <Typography variant="body1" style={{ fontWeight: 500 }}>
      {label}
    </Typography>
  );
};
