import React, { Component } from "react";
import { CSVReader } from "react-papaparse";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Spinner from "../../UI/Spinner/Spinner";
import {
  Typography,
  Paper,
  Grid,
  Container,
  Button,
  Fade,
  FormControlLabel,
  Switch,
  LinearProgress,
  Icon,
  Snackbar,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

// function parseArea(area) {
//   let id = -1;
//   id = Object.keys(AreasList).find((ar) => {
//     if (AreasList[ar] === area) return parseInt(ar);
//   });

//   return id;
// }

// function parseAcademicDegree(academic) {
//   if (academic === null || academic === "") return "";
//   const academicDegreeArr = academic.split(".");
//   let academicDegreeList = [];
//   academicDegreeArr.map((ad) => {
//     if (ad.includes("תואר ראשון"))
//       return [
//         ...academicDegreeList,
//         {
//           degreeId: academicDegreeList.length,
//           degreeName: "firstDegree",
//           degreeType: "",
//           degreeOn: "",
//         },
//       ];
//     if (ad.includes("תואר שני"))
//       return [
//         ...academicDegreeList,
//         {
//           degreeId: academicDegreeList.length,
//           degreeName: "secondDegree",
//           degreeType: "",
//           degreeOn: "",
//         },
//       ];
//     if (ad.includes("סטודנט"))
//       return [
//         ...academicDegreeList,
//         {
//           degreeId: academicDegreeList.length,
//           degreeName: "",
//           degreeType: "",
//           degreeOn: "Student",
//         },
//       ];
//     if (ad.includes("טכנאי"))
//       return [
//         ...academicDegreeList,
//         {
//           degreeId: academicDegreeList.length,
//           degreeName: "",
//           degreeType: "",
//           degreeOn: "technician",
//         },
//       ];
//     if (ad.includes("הנדסאי"))
//       return [
//         ...academicDegreeList,
//         {
//           degreeId: academicDegreeList.length,
//           degreeName: "",
//           degreeType: "",
//           degreeOn: "practicalEngineer",
//         },
//       ];
//   });
//   return academicDegreeList;
// }

// function parseProfession(professionStr, professionsListObject) {
//   if (
//     professionStr === "" ||
//     professionStr === undefined ||
//     professionStr === null
//   )
//     return;

//   let professionsArr = professionStr.split(".").join("-").split("-");
//   //new loop through final loop:
//   let professionsList = [];
//   professionsList = professionsArr.map((item) => {
//     const str = item.trim();
//     if (professionsList.indexOf((str) => str) < 0)
//       //  dont exist: add
//       return [...professionsList, str];
//   });

//   //loop over to find the profession Id:
//   let professionIdArray = [];
//   professionsList.map((prof) => {
//     const foundProf = professionsListObject.filter(
//       (plo) => plo.name === prof[0]
//     )[0];
//     if (foundProf) {
//       if (
//         professionIdArray.findIndex((id) => id === parseInt(foundProf.id)) < 0
//       )
//         //return [...professionIdArray, foundProf.id];
//         professionIdArray = [...professionIdArray, foundProf.id];
//     }
//   });
//   return professionIdArray;
// }

// function parseLanguages(languagesStr) {
//   if (languagesStr === null || languagesStr === "") return "";

//   const languagesArr = languagesStr.split(".");
//   //check if languages has "(אם)" to set motherTounge
//   let languagesList = [];
//   languagesArr.map((lang) => {
//     let str = lang.trim();
//     const isMotherToung = str.includes("(אם)");
//     if (isMotherToung) str = str.substring(0, str.length - 4).trim();
//     languagesList = [
//       ...languagesList,
//       {
//         languageId: languagesList.length + 1,
//         languageName: str,
//         languageLevel: isMotherToung ? "motherTounge" : "",
//       },
//     ];
//   });
//   return languagesList;
// }

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  cancel: {
    marginLeft: theme.spacing(1),
  },
});

class CSVReader4 extends Component {
  state = {
    usersUpload: null,
    usersUploadTransform: null,
    isError: false,
    showLog: false,
    uploading: false,
    isLoading: false,
    usersError: [],
    duplicateUsers: [],
    error: null,
    showAlert: false,
  };

  handleOnDrop = (data) => {
    //before savign, check we can read the data:
    let sanitizedData = [];
    data.forEach((row) => {
      if (!row.errors && !row.errors.length) {
        return row;
      }
      sanitizedData.push(row.data);
    });
    if (!sanitizedData || sanitizedData.length === 0) {
      this.setState({ isError: true });
      return;
    }

    this.setState({ usersUpload: sanitizedData, isLoading: true });
    const verifyDuplicates = data;
    let localDuplicates = [];
    localDuplicates = verifyDuplicates.map((user) => {
      const keys = Object.keys(user.data);
      const loop = Object.values(user.data);
      //locate: email, mobile, id, fullname, externalId
      const emailIndex = keys.findIndex((cell) => cell === "אימייל");
      let mobileIndex = keys.findIndex((cell) => cell === "טלפון");
      if (mobileIndex > -1 && loop[mobileIndex === ""])
        //check for other phones
        mobileIndex = keys.findIndex((cell) => cell === "טלפון נוסף");
      const identifierNumber = keys.findIndex((cell) => cell === "מספר ת.ז");
      const firstNameIndex = keys.findIndex((cell) => cell === "שם פרטי");
      const lastNameIndex = keys.findIndex((cell) => cell === "שם משפחה");
      const firstNamePart = firstNameIndex > -1 ? loop[firstNameIndex] : "";
      const lastNamePart = lastNameIndex > -1 ? loop[lastNameIndex] : "";
      const externalIdIndex = keys.findIndex((cell) => cell === "מספר מועמד");
      return {
        mobile: mobileIndex > -1 ? loop[mobileIndex] : "",
        id: identifierNumber > -1 ? loop[identifierNumber] : "",
        email: emailIndex > -1 ? loop[emailIndex] : "",
        fullname: `${firstNamePart} ${lastNamePart}`,
        externalId: externalIdIndex > -1 ? loop[externalIdIndex] : "",
      };
    });
    axios
      .post(
        "/wp-json/api/v2/validate_duplicatation",
        {
          candidates: localDuplicates,
        },
        { timeout: 400000 }
      )
      .then((res) =>
        this.setState({ duplicateUsers: res.data, isLoading: false })
      )
      .catch((err) => {
        this.setState({ error: err, isLoading: false });
      });
  };

  handleOnError = (err, file, inputElem, reason) => {
    this.setState({
      isError: true,
      error: err,
      uploading: false,
      usersUploadTransform: null,
    });
  };

  handleOnRemoveFile = (data) => {
    this.setState({
      usersUpload: [],
      duplicateUsers: [],
      showAlert: false,
      usersUploadTransform: null,
    }); //null
  };

  startDataTransform = () => {
    this.setState({ uploading: true });
    const usersToCandidates = this.state.usersUpload;
    const verifyDuplicates = this.state.duplicateUsers;
    let candidateIds = [];
    let usersToUpload = [];
    //const progressStep = parseFloat(1 / usersToCandidates.length) * 100;
    //let candidatesList = [];
    usersToCandidates.forEach((user) => {
      const loop = Object.values(user);
      const keys = Object.keys(user);
      const emailIndex = keys.findIndex((cell) => cell === "אימייל");
      let mobileIndex = keys.findIndex((cell) => cell === "טלפון");
      if (mobileIndex > -1 && loop[mobileIndex === ""])
        //check for other phones
        mobileIndex = keys.findIndex((cell) => cell === "טלפון נוסף");
      const identifierNumberIndex = keys.findIndex(
        (cell) => cell === "מספר ת.ז"
      );
      const firstNameIndex = keys.findIndex((cell) => cell === "שם פרטי");
      const lastNameIndex = keys.findIndex((cell) => cell === "שם משפחה");
      const externalIdIndex = keys.findIndex((cell) => cell === "מספר מועמד");
      const yearOfBirthIndex = keys.findIndex((cell) => cell === "תאריך לידה");
      const cityIndex = keys.findIndex((cell) => cell === "ישוב");
      let yearOfBirth =
        yearOfBirthIndex > -1
          ? new Date(loop[yearOfBirthIndex]).getFullYear()
          : "";
      if (yearOfBirth === "") {
        //try the age:
        const ageIndex = keys.findIndex((cell) => cell === "גיל");
        if (ageIndex > -1)
          yearOfBirth = new Date().getFullYear() - parseInt(loop[ageIndex]);
      }
      const genderIndex = keys.findIndex((cell) => cell === "מגדר");
      let genderValue = "";
      if (genderIndex > -1 && loop[genderIndex].trim() === "נ") {
        genderValue = "female";
      }
      if (genderIndex > -1 && loop[genderIndex].trim() === "ז") {
        genderValue = "male";
      }

      const familyStatusIndex = keys.findIndex((cell) => cell === "מצב משפחתי");
      let familyStatusValue = "";
      if (familyStatusIndex > -1 && loop[familyStatusIndex].trim() === "נ") {
        familyStatusValue = "married";
      }
      if (familyStatusIndex > -1 && loop[familyStatusIndex].trim() === "ר") {
        familyStatusValue = "single";
      }

      const availabilityIndex = keys.findIndex((cell) => cell === "זמין");
      let availabilityValue = 0;
      if (availabilityIndex > -1 && loop[availabilityIndex] === "כ")
        availabilityValue = 1;
      const sourceIndex = keys.findIndex((cell) => cell === "גורם מפנה אחרון");
      const employeeNumberIndex = keys.findIndex(
        (cell) => cell === "מספר עובד בחברה"
      );
      let newCandidate = {};
      newCandidate = {
        firstName: firstNameIndex > -1 ? loop[firstNameIndex] : "",
        lastName: lastNameIndex > -1 ? loop[lastNameIndex] : "",
        mobile: mobileIndex > -1 ? loop[mobileIndex] : "",
        city: cityIndex > -1 ? loop[cityIndex] : "",
        yearOfBirth: yearOfBirth,
        gender: genderValue,
        familyStatus: familyStatusValue,
        availability: availabilityValue,
        identifierNumber:
          identifierNumberIndex > -1 ? loop[identifierNumberIndex] : "",
        externalNumber: externalIdIndex > -1 ? loop[externalIdIndex] : "",
        source: sourceIndex > -1 ? loop[sourceIndex] : "",
        email:
          emailIndex > -1 && loop[emailIndex] ? loop[emailIndex].trim() : "",
        employeeNumber:
          employeeNumberIndex > -1 ? loop[employeeNumberIndex] : "",
      };
      const isDuplicated = verifyDuplicates.find(
        (dup) =>
          dup.field_value === newCandidate.mobile ||
          parseInt(dup.field_value) === newCandidate.identifierNumber ||
          dup.field_value === newCandidate.email ||
          dup.field_value === newCandidate.externalNumber
      );
      if (!isDuplicated) {
        const candidateData = {
          title: `${newCandidate.firstName} ${newCandidate.lastName}`,
          fields: {
            first_name: newCandidate.firstName,
            last_name: newCandidate.lastName,
            email: newCandidate.email,
            //work_in_area: newCandidate.area,
            candidate_external_number: newCandidate.externalNumber,
            identifier_number: newCandidate.identifierNumber,
            year_of_birth: newCandidate.yearOfBirth,
            address: "",
            family_status: newCandidate.familyStatus,
            telephone_main: newCandidate.mobile,
            is_active: "active",
            city: newCandidate.city,
            source: newCandidate.source,
            gender: newCandidate.gender,
            rating: -1,
          },
          //"jobs-sectors": newCandidate.profession,
          //content: newCandidate.description,
          status: "publish",
        };
        const additionalInformation = {
          aiExperienceObjectData: newCandidate.lastJob
            ? [
                {
                  experienceId: 1,
                  experienceName: newCandidate.lastJob,
                  yearsExperience: 0,
                  isManagementRole: false,
                },
              ]
            : [],
          aiAcademicDegreesObjectData: newCandidate.academicDegree,
          aiLanguagesObjectData: newCandidate.languages,
          correlateToJobId: this.props.match.params.jobid, //
          author: localStorage.getItem("userEmail"), //used to get current user id
        };
        usersToUpload.push({
          candidateData,
          additionalInformation,
        });
      }
    });
    if (usersToUpload.length) {
      axios
        .post("/wp-json/api/v2/upload_candidates", {
          usersToUpload: usersToUpload,
        })
        .then((res) => {
          candidateIds = res.data.map((newCand) => newCand.candidateId);
          this.setState({
            uploading: false,
            showAlert: true,
          });
          //link the duplicates:
          this.state.duplicateUsers.forEach((cand) => {
            candidateIds.push(cand.candidate_id);
          });
          return axios
            .post(
              `/wp-json/api/v2/link_multiples/${this.props.match.params.jobid}`,
              {
                candidateIds: candidateIds,
                authorId: localStorage.getItem("userId"),
              }
            )
            .then((res) => {
              this.setState({
                usersUploadTransform: res.data,
                uploading: false,
                showAlert: true,
              });
            })
            .catch((err) => {
              this.setState({ error: err, uploading: false });
            });
        })
        .catch((err) => this.setState({ error: err, uploading: false }));
    } else {
      //just link the dupicates:
      //link the duplicates:
      candidateIds = this.state.duplicateUsers.map((cand) => {
        return cand.candidate_id;
      });
      axios
        .post(
          `/wp-json/api/v2/link_multiples/${this.props.match.params.jobid}`,
          {
            candidateIds: candidateIds,
            authorId: localStorage.getItem("userId"),
          }
        )
        .then((res) => {
          this.setState({
            usersUploadTransform: res.data,
            uploading: false,
            showAlert: true,
          });
        })
        .catch((err) => {
          this.setState({ error: err, uploading: false });
        });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showAlert: false });
  };

  render() {
    const { classes } = this.props;
    const displayDuplicates = this.state.duplicateUsers.length ? (
      <Fade in={this.state.duplicateUsers.length > 0}>
        <Paper className={classes.paper}>
          <div>
            <Typography variant="body1">
              <strong>מועמדים שלא ייובאו - כבר קיימים במערכת </strong> (
              {this.state.duplicateUsers.length
                ? this.state.duplicateUsers.length
                : 0}{" "}
              מועמדים)
            </Typography>
          </div>
          <div className={classes.alert}>
            {this.state.duplicateUsers.map((candidate, index) => {
              return (
                <Alert
                  severity="warning"
                  variant="outlined"
                  key={candidate.candidate_id + "_" + index.toString()}
                >
                  <AlertTitle>{candidate.candidate_name}</AlertTitle>
                  {`זוהה לפי: ${candidate.identified_field}`}{" "}
                  {`ערך זיהוי: ${candidate.field_value}`}
                  <br />
                  {`מזהה מועמד: ${candidate.candidate_id}`}
                </Alert>
              );
            })}
          </div>
        </Paper>
      </Fade>
    ) : null;
    const reportView =
      this.state.showLog && this.state.usersUploadTransform ? (
        <Fade in={this.state.showLog}>
          <Paper className={classes.paper}>
            <div>
              <Typography variant="h5">יומן ייבוא למשרה</Typography>
            </div>
            <div className={classes.alert}>
              {this.state.usersUploadTransform.map((candidate, index) => {
                return (
                  <Alert
                    severity={candidate.type}
                    key={
                      candidate.candidateId
                        ? candidate.candidateId
                        : `${candidate.candidate.title}_${index}`
                    }
                  >
                    <AlertTitle>{candidate.fullname}</AlertTitle>
                    {`שנת לידה ${candidate.year_birth} מ${candidate.city}`}
                  </Alert>
                );
              })}
            </div>
          </Paper>
        </Fade>
      ) : null;
    return (
      <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xl={12}>
            <Paper className={classes.paper}>
              <Typography variant="h5" gutterBottom>
                העלאת מועמדים למשרה {this.props.match.params.jobid}
              </Typography>
              <CSVReader
                onDrop={this.handleOnDrop}
                onError={this.handleOnError}
                addRemoveButton={true}
                onRemoveFile={this.handleOnRemoveFile}
                progressBarColor={blueGrey[500]}
                config={{
                  delimiter: "", // auto-detect
                  newline: "", // auto-detect
                  quoteChar: "'",
                  escapeChar: '"',
                  header: true,
                  dynamicTyping: true,
                  encoding: "ISO-8859-8",
                  error: undefined,
                  skipEmptyLines: true,
                  delimitersToGuess: [",", "	", "|", ";"],
                }}
              >
                <span>לחץ או גרור קובץ csv</span>
              </CSVReader>
              {this.state.usersUpload ? (
                <React.Fragment>
                  <div className={classes.container}>
                    <div className={classes.alert}>
                      <Alert severity="info">
                        {`${this.state.usersUpload.length} נקראו בהצלחה`}
                      </Alert>
                    </div>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginTop: 16 }}
                      startIcon={<CloudUploadIcon />}
                      onClick={this.startDataTransform}
                      disabled={
                        this.state.usersUpload === null ||
                        !this.state.usersUpload.length ||
                        this.state.usersUploadTransform !== null ||
                        this.state.uploading ||
                        this.state.error
                      }
                    >
                      התחל יבוא למשרה
                    </Button>
                  </div>
                </React.Fragment>
              ) : null}
            </Paper>
            <div className={classes.container}>
              <Paper className={classes.paper}>
                {this.state.uploading ? (
                  <Typography variant="body1">
                    בהתאם לכמות המידע, ייבוא המועמדים יכול לארוך מספר דקות
                  </Typography>
                ) : null}
                <LinearProgress
                  color="secondary"
                  variant={
                    this.state.uploading ? "indeterminate" : "determinate"
                  }
                  value={this.state.uploading ? null : 0}
                  style={{
                    marginBottom: 16,
                    marginTop: 16,
                  }}
                />
              </Paper>
            </div>
            {this.state.duplicateUsers ? displayDuplicates : null}
            {this.state.usersUploadTransform ? (
              <React.Fragment>
                <div className={classes.container}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.showLog}
                        onChange={(event) =>
                          this.setState({
                            showLog: event.target.checked,
                          })
                        }
                        name="viewReport"
                        color="secondary"
                      />
                    }
                    label="הצג דוח ייבוא"
                  />
                </div>
                {reportView}
                <div className={classes.container}>
                  <Paper className={classes.paper}></Paper>
                </div>
              </React.Fragment>
            ) : null}
            {this.state.isLoading ? (
              <Spinner open={this.state.isLoading} />
            ) : null}
            {this.state.showAlert ? (
              <Snackbar
                open={this.state.showAlert}
                autoHideDuration={6000}
                onClose={this.handleClose}
              >
                <Alert
                  variant="filled"
                  onClose={this.handleClose}
                  severity="success"
                >
                  {this.state.usersUploadTransform &&
                    this.state.usersUploadTransform.length}{" "}
                  מועמדים ייובאו בהצלחה
                </Alert>
              </Snackbar>
            ) : null}
            <div className={classes.container}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<Icon>add</Icon>}
                //onClick={this.props.endUpload}
                onClick={() =>
                  this.props.history.push(
                    `/jobs/dashboard/${this.props.match.params.jobid}`
                  )
                }
              >
                סיום
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.cancel}
                onClick={() => this.props.history.push("/jobs/")}
              >
                ביטול
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withRouter(withStyles(styles)(CSVReader4));
