import React, { Component } from "react";
import compose from "recompose/compose";
import {
  Container,
  Grid,
  Button,
  Paper,
  Typography,
  IconButton,
  Divider,
  Box,
  Collapse,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import SingleCandidateFullDetails from "./SingleCandidateFull/SingleCandidateFullDetails";
import MyAlert from "../../UI/MyAlert/MyAlert";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import MySearch from "../../components/MySearch/MySearch";
import AddCandidate from "./AddCandidate/AddCandidate.js";
import AddCancelButton from "../../components/AddCancelButtons/AddCancelButton";
import SearchJob from "../../components/SearchJob/SearchJob";
//import CustomTab from "../../components/CustomTabs/CustomTab";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import SingleCandidate from "./SingleCandidate";
import { AreasList } from "../../config/jobsConfigData";
import { FamilyStatusList } from "../../config/candidatesConfigData";
import axios from "axios";
import { TranformAdditionalInformationData } from "../../config/candidatesConfigData";
import Spinner from "../../UI/Spinner/Spinner";
import CustomDialog from "../../UI/CustomDialog/CustomDialog";
import CandidatesSkeleton from "../../UI/Skeletons/CandidatesSkeleton";
import Pagination from "../../UI/Pagination/Pagination";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import AxiosErrorHandling from "../../components/AxiosErrorHandling/AxiosErrorHandling";
import { AddEventHandler } from "../../config/jobsConfigData";
const DRAWER_WIDTH = 240;
const styles = (theme) => ({
  root: {
    "& .MuiPaper-root": {
      padding: theme.spacing(2),
    },
  },
  topSearchBar: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    position: "fixed",
    top: 0,
    zIndex: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    width: "calc(100% - " + DRAWER_WIDTH + "px)",
  },
  search: {
    padding: theme.spacing(2),
  },
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    minHeight: 240,
  },
  typography: {
    direction: "rtl",
  },
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  leftRoot: {
    padding: theme.spacing(2),
    minHeight: "70vh",
    "& .MuiIconButton-root": {
      marginLeft: theme.spacing(2),
    },
  },
  leftHeaderWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  leftHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  tabs: {
    flexGrow: 1,
  },
  mutiplesMenu: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  topMargin: {
    marginTop: theme.spacing(1),
  },
});

function getTabIdByIndex(index) {
  const listIndex = CandidateTabs.findIndex((tab) => tab.index === index);
  if (listIndex > -1) {
    return CandidateTabs[listIndex].id;
  }
}

const CandidateTabs = [
  { index: 0, id: "all", label: "כל המועמדים" },
  { index: 1, id: "blocked", label: "חסומים" },
  { index: 2, id: "unsubscribed", label: "הסרה מדיוור" },
];

class Candidates extends Component {
  constructor(props) {
    super(props);
    //this.handleSearchCandidate = this.handleSearchCandidate.bind(this);
    this._timeout = null;
    this.state = {
      addMode: false,
      searchCandidate: "",
      familyStatusList: [],
      showAlert: false,
      editMode: -1, // will hold job id in case of edit mode
      multipleSelectedIds: [],
      selectedCandidateId: -1, //in none selected
      totalPages: 0,
      totalResults: 0,
      filteredTotalResults: 0,
      currentPage: 1,
      candidates: [],
      filteredCandidates: [],
      isLoading: false,
      eventsLoading: false,
      errorMessage: "",
      candidatesAdditionalInformation: [],
      linkMultiplesToJob: false,
      serverLoading: false,
      showLinkMultipleToJob: false, //show/hide the popup for multiple link job selection after selection multiple candidates
      linkToJobId: -1,
      error: null,
      eventsOffset: 0,
      jobId: -1, //-1 brings all candidates, otherwise bring candidates for specific job,
      currentTab: 0,
      externalMultipleLinkToJobAction: false,
      jobAutoClosed: {
        closed: false,
        jobId: -1,
        candidateId: -1,
      },
    };
  }

  //Props we are expecting to get, in order to prepare our view:
  //jobId: if greater then 0, this mode is candidates for specific job. otherwise - bring all candidates.
  //useGutter - if true, we have right and left padding
  //noHeader - if true, we are removing the title and add candidate button

  handleEventAdded = async (event, candidateId) => {
    const eventHandler = await AddEventHandler(
      event.eventKey,
      event.eventValue,
      event.additionalInformation,
      event.isUpdateMode,
      candidateId,
      parseInt(localStorage.getItem("userId"))
    );

    //check eventKey
    if (
      event.eventKey === "recruitmentSucceeded" &&
      eventHandler.auto_closed === true
    ) {
      this.setState({
        jobAutoClosed: {
          closed: true,
          jobId: parseInt(event.eventValue),
          candidateId: candidateId,
        },
      });
    }

    this.handleSelectCandidateId(null, candidateId);
    if (this.props.onEventAction) {
      this.props.onEventAction(event, candidateId);
    }
  };
  handleEditCandidate = () =>
    this.setState({ editMode: this.state.selectedCandidateId, addMode: true });
  handleCloseMode = () => this.setState({ addMode: false, editMode: -1 });
  handleCloseOnError = (error, errorInfo) => {
    this.setState({
      error: error,
      addMode: false,
      editMode: -1,
    });
  };

  handleAddedSuccessfully = () => {
    //Now I need to update to candidate,
    if (this.state.editMode < 0) {
      this.setState({
        showAlert: true,
        addMode: false,
        editMode: -1,
      });
      return;
    }
    this.setState({
      showAlert: true,
      addMode: false,
      editMode: -1,
      selectedCandidateId: -1,
    });
    //refresh the list
    this.getCandidates();
  };

  getFilteredCandidatesByIds = () => {
    this.setState({
      isLoading: true,
      serverLoading: true,
    });
    axios
      .get("wp-json/api/v2/candidates_summary_ids", {
        params: {
          ids: this.props.filterCandidatesByIds,
          showFailureReasons: this.props.showFailureReasons
            ? this.props.showFailureReasons
            : undefined,
        },
      })
      .then((res) => {
        this.setState({
          isLoading: false,
          serverLoading: false,
          filteredCandidates: res.status === 204 ? [] : res.data,
          filteredTtotalResults: parseInt(res.headers["x-wp-total"]),
          //totalPages: parseInt(res.headers["x-wp-totalpages"]),
          selectedCandidateId: -1,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          serverLoading: false,
          currentPage: 1,
          showAlert: true,
          addMode: false,
          editMode: -1,
          filteredCandidates: [],
          errorMessage: err.message,
          error: err.response,
          totalResults: 0,
          typingTimeout: 0,
        });
      });
  };

  getFilteredCandidates = (page = 1, searchValue = "") => {
    this.setState({
      isLoading: true,
      serverLoading: true,
    });
    axios
      .get("wp-json/api/v2/filter_candidates", {
        params: {
          filter: searchValue === "" ? this.state.searchCandidate : searchValue,
          paged: page,
          job_id: this.props.job ? this.props.job.id : -1,
        },
      })
      .then((res) => {
        this.setState({
          isLoading: false,
          serverLoading: false,
          //status 404 - no results founds
          filteredCandidates: res.data,
          filteredTotalResults: parseInt(res.headers["x-wp-total"]),
          totalPages: parseInt(res.headers["x-wp-totalpages"]),
          selectedCandidateId: -1,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          serverLoading: false,
          currentPage: 1,
          showAlert: true,
          addMode: false,
          editMode: -1,
          filteredCandidates: [],
          errorMessage: err.message,
          error: err.response,
          totalResults: 0,
        });
      });
  };

  getCandidates = (page, tab = "all") => {
    this.setState({
      isLoading: true,
      selectedCandidateId: -1,
      multipleSelectedIds: [],
      candidatesAdditionalInformation: [],
    });
    let url = "/wp-json/api/v2/candidates_summary";
    if (this.props.job) url += `/${this.props.job.id}`;
    if (tab === "unsubscribed") {
      url = "/wp-json/api/v2/candidates_unsubscribed_summary";
      if (this.props.job) url += `/${this.props.job.id}`;
    }
    axios
      .get(url, {
        params: {
          per_page: 20,
          page: page,
        },
      })
      .then((res) => {
        this.setState({
          isLoading: false,
          serverLoading: false,
          candidates: res.data,
          totalResults: parseInt(res.headers["x-wp-total"]),
          totalPages: parseInt(res.headers["x-wp-totalpages"]),
        });
        if (this.props.job) this.props.candidatesList(res.data);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          currentTab: 0,
          serverLoading: false,
          currentPage: 1,
          showAlert: true,
          addMode: false,
          editMode: -1,
          errorMessage: err.message,
          error: err.response,
          totalResults: 0,
        });
      });
  };

  handleCloseMyAlert = (event, reason) => {
    //if (reason === "clickaway") return;
    this.setState({
      showAlert: false,
      errorMessage: "",
      jobAutoClosed: {
        jobId: -1,
        closed: false,
        candidateId: -1,
      },
    });
  };

  handlePageChange = (event, page) => {
    this.setState({ currentPage: page });
    if (this.state.searchCandidate !== "") {
      this.getFilteredCandidates(page);
      return;
    }
    this.getCandidates(page, this.state.qualifiedTab);
  };

  handleEventsOffest = async (event, id) => {
    const currentOffset = this.state.eventsOffset;
    this.setState({ eventsLoading: true });
    const moreEvents = await axios.get(
      `/wp-json/api/v2/candidate_events/${id}`,
      {
        params: {
          offset: currentOffset + 1,
        },
      }
    );
    //update the additional events to the candidate id:

    let localCandidates = this.state.candidates;
    const index = localCandidates.findIndex((cand) => cand.id === id);
    localCandidates[index].candidateEvents = [
      ...localCandidates[index].candidateEvents,
      ...moreEvents.data,
    ];
    this.setState({
      eventsOffset: currentOffset + 1,
      eventsLoading: false,
    });
  };

  handleSelectCandidateId = async (event, id) => {
    let index = -1;
    let filteredIndex = -1;

    this.setState({ eventsLoading: true });
    const aiDataRequest = axios.get(`/wp-json/api/v2/candidateAI/${id}`);
    const candidateEventsRequest = axios.get(
      `/wp-json/api/v2/candidate_events/${id}`,
      {
        params: {
          offset: 0, //this.state.eventsOffset - As this candidate was selected now, we should start from 0
        },
      }
    );
    Promise.all([aiDataRequest, candidateEventsRequest])
      .then((responses) => {
        let candidatesAdditionalInformationUpdatedData = null;
        candidatesAdditionalInformationUpdatedData = TranformAdditionalInformationData(
          responses[0].data
        ).map((aiType) => {
          return candidatesAdditionalInformationUpdatedData === null
            ? aiType
            : { ...candidatesAdditionalInformationUpdatedData, aiType };
        });
        let candidateEvents = responses[1].data;
        index = this.state.candidates.findIndex((cand) => cand.id === id);
        if (index >= 0) {
          let localCandidate = { ...this.state.candidates[index] };
          localCandidate = {
            ...localCandidate,
            candidatesAdditionalInformationUpdatedData,
            candidateEvents,
            totalEvents: responses[1].headers["x-wp-total"],
          };
          const localCandidatesList = [...this.state.candidates];
          localCandidatesList[index] = localCandidate;
          this.setState({
            candidates: localCandidatesList,
            totalEvents: responses[1].headers["total-events"],
          });
        }
        //also search in filteredCandidate
        filteredIndex = this.state.filteredCandidates.findIndex(
          (cand) => cand.id === id
        );
        if (filteredIndex >= 0) {
          let localCandidate = {
            ...this.state.filteredCandidates[filteredIndex],
          };
          localCandidate = {
            ...localCandidate,
            candidatesAdditionalInformationUpdatedData,
            candidateEvents,
          };
          let localCandidatesList = [...this.state.filteredCandidates];
          localCandidatesList[filteredIndex] = localCandidate;
          //if the user doesn't exists in the candidates list, add to this list as well:
          this.setState({
            filteredCandidates: localCandidatesList,
            candidates:
              index < 0
                ? [...this.state.candidates, localCandidate]
                : [...this.state.candidates],
          });
        }
        this.setState({
          selectedCandidateId: id,
          eventsLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ error: err.response });
      });
  };

  componentDidMount() {
    this.setState({
      familyStatusList: Object.keys(FamilyStatusList).map((item) => {
        return { id: item, value: FamilyStatusList[item] };
      }),
      areasList: Object.keys(AreasList).map((item) => {
        return { id: item, value: AreasList[item] };
      }),
    });
    if (this.props.filterCandidatesByIds)
      this.getFilteredCandidatesByIds(this.props.filterCandidatesByIds);
    else if (this.props.candidates) {
      this.setState({
        candidates: this.props.candidates.data,
        // totalResults: this.props.candidates.total,
        // totalPages: this.props.candidates.totalPages,
      });
    } else if (this.props.searchFilter && this.props.searchFilter.length > 2) {
      this.setState({ searchCandidate: this.props.searchFilter });
      this.getFilteredCandidates(1, this.props.searchFilter);
    } else this.getCandidates(1);
  }

  getSelectedCandidate = () => {
    return this.state.candidates.filter(
      (cid) => cid.id === this.state.selectedCandidateId
    )[0];
  };

  handleTagSearch = (tag) => {
    this.setState({ searchCandidate: tag });
  };

  handleClearCandidate = () => {
    this.setState({ searchCandidate: "", filteredCandidates: [] });
  };

  handleReloadPage = () => {
    this.setState({
      currentPage: 1,
      candidates: [],
      searchCandidate: "",
      filteredCandidates: [],
    });
    this.getCandidates(1);
  };

  handleSearchCandidate = (searchTerm) => {
    //if in candidates module - search on server
    //const searchTerm = e.target.value;
    if (searchTerm === "") {
      //clear filter
      this.setState({ filteredCandidates: [], searchCandidate: "" });
      return;
    }
    if (this._timeout) {
      clearTimeout(this._timeout);
    }

    this._timeout = setTimeout(() => {
      if (searchTerm.length > 2) {
        this.setState({ searchCandidate: searchTerm });
        this.getFilteredCandidates(1, searchTerm);
      }
    }, 1000);
  };

  handleLinkToJobId = (id) => {
    this.setState({ linkToJobId: id });
  };

  handleLinkMultipeToJob = () => {
    this.setState({ isLoading: true });
    axios
      .post(`/wp-json/api/v2/link_multiples/${this.state.linkToJobId}`, {
        candidateIds: this.state.multipleSelectedIds,
        authorId: localStorage.getItem("userId"),
      })
      .then((res) => {
        this.setState({
          multipleSelectedIds: [],
          linkToJobId: -1,
          isLoading: false,
          linkMultiplesToJob: false,
          showLinkMultipleToJob: false,
        });
        if (this.props.onCloseMultipleLinkToJob)
          this.props.onCloseMultipleLinkToJob();
        //this.handleCloseMultipleLinkToJob();
      })
      .catch((err) => {
        this.setState({
          multipleSelectedIds: [],
          linkToJobId: -1,
          isLoading: false,
          linkMultiplesToJob: false,
          showLinkMultipleToJob: false,
          errorMessage: err.response.data.message,
        });
      });
  };

  handleCheckCandidate = (event, id) => {
    //first - check if already selected
    let multipleIds = this.state.multipleSelectedIds;
    const index = multipleIds.findIndex((mid) => mid === id);

    if (index === -1) {
      //not found - check if we need to add or remove from list
      if (event.target.checked) {
        //add candidate id to the list
        multipleIds = [...multipleIds, id];
      }
    } else {
      //we found a candidate. checed - nothing to do - already selected
      if (!event.target.checked) multipleIds.splice(index, 1);
    }
    this.setState({ multipleSelectedIds: multipleIds });
    this.props.getSelectedCount &&
      this.props.getSelectedCount(multipleIds.length);
  };

  handleCheckAllCandidates = (event) => {
    const candIds = event.target.checked
      ? this.state.candidates.map((candidate) => candidate.id)
      : [];
    this.setState({ multipleSelectedIds: event.target.checked ? candIds : [] });
    this.props.getSelectedCount && this.props.getSelectedCount(candIds.length);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.filterCandidatesByIds &&
      prevProps.filterCandidatesByIds !== this.props.filterCandidatesByIds &&
      this.props.filterCandidatesByIds.length > 0
    ) {
      this.getFilteredCandidatesByIds();
    }
    if (
      this.props.searchFilter !== this.state.searchCandidate &&
      this.props.searchFilter !== prevProps.searchFilter
    ) {
      const searchTerm = this.props.searchFilter;
      if (searchTerm === "") {
        //clear filter
        this.setState({ filteredCandidates: [], searchCandidate: "" });
        //Special Case: If we initiated this component with a filter, the getCandidates was never called. On clear, we need to get all candidates
        if (this.state.candidates && this.state.candidates.length === 0) {
          this.getCandidates(1);
        }
        return;
      }
      if (this._timeout) {
        clearTimeout(this._timeout);
      }

      this._timeout = setTimeout(() => {
        if (searchTerm.length > 2) {
          this.getFilteredCandidates(1, searchTerm);
        }
      }, 1000);

      this.setState({ searchCandidate: searchTerm });
    }
    if (
      prevProps.linkMultipleSelectedClick !==
        this.props.linkMultipleSelectedClick &&
      this.props.linkMultipleSelectedClick &&
      !this.state.externalMultipleLinkToJobAction
    ) {
      this.setState({
        externalMultipleLinkToJobAction: false,
        showLinkMultipleToJob: true,
      });
    }
    if (this.props.candidates !== prevProps.candidates) {
      this.setState({
        candidates: this.props.candidates.data,
      });
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
    this.getCandidates(1, getTabIdByIndex(newValue));
  };

  handleCloseMultipleLinkToJob = () => {
    this.setState(
      { showLinkMultipleToJob: false, multipleSelectedIds: [] },
      () => {
        if (this.props.onCloseMultipleLinkToJob)
          this.props.onCloseMultipleLinkToJob();
      }
    );
  };

  render() {
    const { classes, theme } = this.props;
    const {
      searchCandidate,
      addMode,
      selectedCandidateId,
      candidates,
      totalPages,
      currentPage,
      filteredCandidates,
      isLoading,
      totalResults,
      jobAutoClosed,
    } = this.state;

    let showPagination =
      !isLoading && !this.props.candidates ? (
        <Pagination
          onChange={this.handlePageChange}
          totalPages={totalPages}
          total={
            this.props.filterCandidatesByIds &&
            this.props.filterCandidatesByIds.length >= 1
              ? this.props.filterCandidatesByIds.length
              : totalResults
          }
          disablePaging={
            this.props.filterCandidatesByIds &&
            this.props.filterCandidatesByIds.length > 0
          }
          totalLabel={'סה"כ מועמדים:'}
          currentPage={currentPage}
          reload={this.handleReloadPage}
        />
      ) : null;

    let candidateItems = [];
    //During textual search on tob bar
    if (searchCandidate !== "" && searchCandidate.length > 2) {
      candidateItems = filteredCandidates.map((cand, index) => {
        return (
          <SingleCandidate
            key={cand.id}
            isFirst={index === 0}
            candidateData={cand}
            check={this.handleCheckCandidate}
            isActive={
              selectedCandidateId === cand.id ||
              this.state.multipleSelectedIds.includes(cand.id)
            }
            selectId={this.handleSelectCandidateId}
            ratingPrecisionHalf
            shortVersion={selectedCandidateId > -1}
            eventAdded={this.handleEventAdded}
            underJobDashboard={this.props.job ? this.props.job.id : false}
          />
        );
      });
    } else if (this.props.filterCandidatesByIds) {
      //filter by ids
      if (this.props.filterCandidatesByIds.length === 0) candidateItems = [];
      else {
        candidateItems = filteredCandidates.map((cand, index) => {
          return (
            <SingleCandidate
              key={cand.id}
              isFirst={index === 0}
              candidateData={cand}
              isActive={selectedCandidateId === cand.id}
              selectId={this.handleSelectCandidateId}
              check={this.handleCheckCandidate}
              ratingPrecisionHalf
              shortVersion={selectedCandidateId > -1}
              eventAdded={this.handleEventAdded}
              underJobDashboard={this.props.job ? this.props.job.id : false}
            />
          );
        });
      }
    } else {
      //no filter applied. get all candidates
      candidateItems = candidates.map((cand, index) => {
        return (
          <SingleCandidate
            key={cand.id}
            isFirst={index === 0}
            candidateData={cand}
            isActive={
              selectedCandidateId === cand.id ||
              this.state.multipleSelectedIds.includes(cand.id)
            }
            selectId={this.handleSelectCandidateId}
            check={this.handleCheckCandidate}
            ratingPrecisionHalf
            shortVersion={selectedCandidateId > -1}
            eventAdded={this.handleEventAdded}
            underJobDashboard={this.props.job ? this.props.job.id : false}
          />
        );
      });
    }

    let singleCandidateLocalData = null;
    if (selectedCandidateId > 0 && searchCandidate === "") {
      singleCandidateLocalData = candidates.find(
        (cand) => cand.id === selectedCandidateId
      );
    } else if (selectedCandidateId > 0) {
      //must be filtered candidate
      singleCandidateLocalData = filteredCandidates.find(
        (cand) => cand.id === selectedCandidateId
      );
    }
    return (
      <React.Fragment>
        {!this.props.disableDefaultSearch && (
          <Paper
            width={1}
            elevation={0}
            square
            className={classes.topSearchBar}
          >
            <div className={classes.topHeader}>
              <Grid
                container
                spacing={3}
                justifyContent="flex-start"
                alignItems="center"
                style={{ margin: 0 }}
              >
                <Grid item xs={4} style={{ paddingRight: 0 }}>
                  <MySearch
                    //searchTerm={searchCandidate}
                    // changeSearchTerm={(searchTerm) =>
                    //   this.setState({ searchCandidate: searchTerm })
                    // }
                    changeSearchTerm={this.handleSearchCandidate}
                    searchLabel="אתר משרה"
                    disabled={addMode}
                    helper={"אתר לפי שם, מספר משרה, שם חברה, מספר חיצוני"}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    aria-label="refresh"
                    className={classes.refreshButton}
                    color="primary"
                    //onClick={() => this.setState({ refreshList: true })}
                  >
                    <CachedRoundedIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    aria-label="add"
                    color="primary"
                    endIcon={<AddIcon />}
                    disabled={addMode}
                    onClick={() => this.setState({ addMode: true })}
                  >
                    הוסף מועמד
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        )}

        <Container
          maxWidth={"xl"}
          style={
            this.props.disableDefaultSearch
              ? { maringTop: 0, paddingLeft: 0, paddingRight: 0 }
              : { marginTop: 150 }
          }
          className={classes.root}
        >
          {this.state.error && (
            <AxiosErrorHandling
              title="אירעה שגיאה בעת עדכון או הוספת מועמד"
              error={this.state.error.response}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box my={theme.spacing(2)}>
                <Collapse
                  in={
                    this.state.multipleSelectedIds.length > 1 &&
                    !this.props.hideMultipleLinkToJobButton
                  }
                  className={classes.mutiplesMenu}
                >
                  <Tooltip title="שייך מועמדים מסומנים למשרה" arrow>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        this.setState({
                          showLinkMultipleToJob: true,
                        })
                      }
                      startIcon={<LinkRoundedIcon />}
                      style={{ marginBottom: 8 }}
                    >
                      שייך למשרה
                    </Button>
                  </Tooltip>
                  {this.state.showLinkMultipleToJob && (
                    <CustomDialog
                      fullWidth
                      maxWidth={"sm"}
                      open={this.state.showLinkMultipleToJob}
                      close={() => this.handleCloseMultipleLinkToJob()}
                      title={"שיוך מועמדים למשרה"}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <SearchJob
                          searchMode
                          linkToJobId={this.handleLinkToJobId}
                          className={classes.topMargin}
                        />
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <AddCancelButton
                            addCaption="שייך מועמדים"
                            cancelCaption="בטל"
                            cancelClick={this.handleCloseMultipleLinkToJob}
                            addClick={this.handleLinkMultipeToJob}
                          />
                        </Box>
                      </Box>
                    </CustomDialog>
                  )}
                </Collapse>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              {showPagination}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xl={12}>
              {this.state.addMode ? (
                <ErrorBoundary>
                  <AddCandidate
                    editMode={this.state.editMode}
                    addedSuccessfully={this.handleAddedSuccessfully}
                    cancelNewCandidate={this.handleCloseMode}
                    closeOnError={this.handleCloseOnError}
                    familyStatusList={this.state.familyStatusList}
                    candidateData={this.state.candidates.filter(
                      (cand) => cand.id === this.state.selectedCandidateId
                    )}
                    candidateAIData={this.state.candidatesAdditionalInformation}
                  />
                </ErrorBoundary>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={selectedCandidateId === -1 ? 12 : 2}>
                    <Paper className={classes.paper}>
                      {this.state.isLoading ? (
                        <CandidatesSkeleton open={this.state.isLoading} />
                      ) : (
                        <React.Fragment>
                          <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{ paddingBottom: theme.spacing(1) }}
                            spacing={2}
                          >
                            <Grid item xs={selectedCandidateId === -1 ? 2 : 12}>
                              <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Checkbox
                                  //checked={props.isActive}
                                  name="checkAll"
                                  onClick={this.handleCheckAllCandidates}
                                  color="primary"
                                />
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  שם מלא
                                </Typography>{" "}
                              </Box>
                            </Grid>
                            {selectedCandidateId === -1 && (
                              <React.Fragment>
                                <Grid item xs={1}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    אזורי עבודה
                                  </Typography>{" "}
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    מקצוע
                                  </Typography>{" "}
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    מספר חיצוני
                                  </Typography>{" "}
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    מספר טלפון
                                  </Typography>{" "}
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    מקור גיוס
                                  </Typography>{" "}
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    {this.props.showFailureReasons
                                      ? "סיבות דחיה"
                                      : "דירוג"}
                                  </Typography>{" "}
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    נוסף/עודכן
                                  </Typography>{" "}
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    align="right"
                                    style={{ marginLeft: theme.spacing(4) }}
                                  >
                                    פעולות
                                  </Typography>{" "}
                                </Grid>
                              </React.Fragment>
                            )}
                          </Grid>

                          <Divider />
                          <div>{candidateItems}</div>
                        </React.Fragment>
                      )}
                    </Paper>
                  </Grid>

                  {this.state.eventsLoading && (
                    <Spinner open={this.state.eventsLoading} />
                  )}
                  {selectedCandidateId > -1 && (
                    <Grid item xs={selectedCandidateId > -1 ? 10 : false}>
                      <SingleCandidateFullDetails
                        tagSearch={this.handleTagSearch}
                        editCandidate={this.handleEditCandidate}
                        eventAdded={this.handleEventAdded}
                        candidateData={singleCandidateLocalData}
                        eventsOffset={this.handleEventsOffest}
                        currentOffset={this.eventsOffset}
                        onClose={() =>
                          this.setState({ selectedCandidateId: -1 })
                        }
                        onEdit={this.handleEditCandidate}
                      />
                    </Grid>
                  )}
                  {showPagination}
                </Grid>
              )}
            </Grid>
          </Grid>
          {
            <MyAlert
              showAlert={this.state.showAlert}
              title={
                this.state.errorMessage === ""
                  ? ""
                  : "אירעה שגיאה בעת ביצוע הפעולה"
              }
              message={
                this.state.errorMessage !== ""
                  ? this.state.errorMessage
                  : "מועמד התווסף בהצלחה!"
              }
              severity={this.state.errorMessage !== "" ? "error" : "success"}
              closeAlert={this.handleCloseMyAlert}
              errorInfo={this.state.errorMessage}
            />
          }
          {
            <MyAlert
              showAlert={jobAutoClosed.closed && jobAutoClosed.jobId > 0}
              title="סגירת משרה"
              message={`עם סימון גיום מוצלח למועמד מספר ${jobAutoClosed.candidateId} משרה מספר ${jobAutoClosed.jobId} השלימה את יעד הגיוס ונסגרה`}
              severity="success"
              closeAlert={this.handleCloseMyAlert}
            />
          }
        </Container>

        {showPagination}
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(Candidates);
