import React from "react";
import {
  Box,
  IconButton,
  FormControl,
  FilledInput,
  InputAdornment,
  useTheme,
  InputLabel,
} from "@material-ui/core";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";

const JobLink = ({ jobId, align = "left" }) => {
  const theme = useTheme();
  return (
    <Box style={{ textAlign: align }}>
      <FormControl variant="filled" style={{ maxWidth: 250, minWidth: 200 }}>
        <InputLabel htmlFor="job-url">כתובת המשרה</InputLabel>
        <FilledInput
          readOnly
          id="job-url"
          defaultValue={`/publishJob/${jobId}`}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="copy"
                style={{ marginLeft: theme.spacing(1) }}
                color="primary"
              >
                <FileCopyRoundedIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
};
export default JobLink;
