import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { Icon, Button, Box } from "@material-ui/core";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
  FormControlLabel,
  Checkbox,
  Slider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import MyTooltip from "../../../../../UI/Tooltip/Tooltip";
import FilterQuestionRelationsTag from "../../../../Jobs/AddJob/FilterQuestions/FilterQuestionRelationsTag";
import { AI_SALARY_EXPECTATIONS } from "../../../../../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));

function salaryExpectationsLabel(value) {
  return `&#8362; ${value}`;
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const AISalaryExpectations = (props) => {
  const classes = useStyles();
  const [seForRole, setSEForRole] = useState("");
  const [useRange, setUseRange] = useState(true);
  const [useForAllRoles, setUseForAllRoles] = useState(false);
  const [updatedChip, setUpdatedChip] = useState(-1);
  const [salaryRange, setSalaryRange] = useState([6000, 8000]);
  const [tags, setTags] = useState([]);
  const [salaryMeasure, setSalaryMeasure] = useState("global");
  const [salaryExpectationsAIObject, setSalaryExpectationsAIObject] = useState(
    []
  );
  const theme = useTheme();

  useEffect(() => {
    if (props.aIValue.data && props.aIValue.data.length) {
      const seTempAIObject = props.aIValue.data.map((aiItem) => {
        return (
          [...salaryExpectationsAIObject],
          {
            key: aiItem.seId,
            seId: aiItem.seId,
            seForRole: aiItem.seForRole,
            seIsRange: aiItem.seIsRange,
            seRange: aiItem.seIsRange
              ? aiItem.seRange.map((x) => parseFloat(x))
              : parseFloat(aiItem.seRange),
            seMeasure: aiItem.seMeasure,
          }
        );
      });
      setSalaryExpectationsAIObject(seTempAIObject);
      setTags(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.aIValue]);

  const handleChangeSalaryMeasure = (event) => {
    setSalaryMeasure(event.target.value);
    //set default values for global or hour:
    if (event.target.value === "hour") {
      setSalaryRange(38);
      setUseRange(false);
    } else {
      setSalaryRange([6000, 8000]);
      setUseRange(true);
    }
  };

  const handleForRoleChange = (event) => {
    setSEForRole(event.target.value);
  };

  const buildAISalaryExpectations = (event) => {
    props.addAI({ data: salaryExpectationsAIObject, tags });
  };

  /*
  Single SalaryExpectations Object: 
  {
      seId: id,
      seForRole: string,
      seIsRange: bool,      
      seRange: array,      
      seMeasure: string, default global. other options: hour
  }
  */
  const handleAddSingleSalaryExpectationAI = () => {
    if (salaryExpectationsAIObject.length <= 0 || useForAllRoles) {
      setSalaryExpectationsAIObject([
        {
          seId: 1,
          seForRole: useForAllRoles ? "" : seForRole,
          seIsRange: useRange,
          seRange: salaryRange,
          seMeasure: salaryMeasure,
        },
      ]);
    } else {
      setSalaryExpectationsAIObject([
        ...salaryExpectationsAIObject,
        {
          seId: salaryExpectationsAIObject.length + 1,
          seForRole: useForAllRoles ? "" : seForRole,
          seIsRange: useRange,
          seRange: salaryRange,
          seMeasure: salaryMeasure,
        },
      ]);
    }

    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = salaryExpectationsAIObject.find(
      (chip) => chip.seId === updatedChip
    );

    //remove the selected chip from the array:
    const tempSEAIObject = salaryExpectationsAIObject.filter(
      (se) => se.seId !== tempUpdatedChip.seId
    );

    //update the form:
    setSalaryExpectationsAIObject([
      ...tempSEAIObject,
      {
        seId: tempUpdatedChip.seId,
        seForRole: seForRole,
        seIsRange: useRange,
        seRange: salaryRange,
        seMeasure: salaryMeasure,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setUpdatedChip(-1);
    setSEForRole("");
    setUseRange(true);
    setSalaryRange([6000, 8000]);
    setUseForAllRoles(false);
    setSalaryMeasure("global");
  };

  const handleRemoveSalaryExpectation = (seId) => {
    //locate index id
    const newSalaryExpectationObject = salaryExpectationsAIObject.filter(
      (seid) => seid.seId !== seId
    );
    setSalaryExpectationsAIObject(newSalaryExpectationObject);
    clearForm();
  };

  const handleChangeSliderRange = (event) => {
    setUseRange(!event.target.checked);
    event.target.checked ? setSalaryRange(2000) : setSalaryRange([6000, 8000]);
  };

  const handleReadChip = (id) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleSEObj = salaryExpectationsAIObject.find(
        (se) => se.seId === id
      );
      setUpdatedChip(id);
      setSEForRole(singleSEObj.seForRole);
      singleSEObj.seForRole === ""
        ? setUseForAllRoles(true)
        : setUseForAllRoles(false);
      setUseRange(singleSEObj.seIsRange);
      setSalaryRange(singleSEObj.seRange);
      setSalaryMeasure(singleSEObj.seMeasure);
    }
  };

  const handleChangeForAllRoles = (event) => {
    setUseForAllRoles(event.target.checked);
    setSEForRole("");
  };
  const handleSalaryRangeChange = (event, newValue) => {
    useRange ? setSalaryRange(newValue) : setSalaryRange(newValue);
  };

  const displayChips =
    salaryExpectationsAIObject.length > 0
      ? salaryExpectationsAIObject.map((se) => {
          return (
            <Chip
              key={se.seId}
              icon={<FaceIcon />}
              label={se.seForRole === "" ? "כל המשרות" : se.seForRole}
              clickable
              color="primary"
              onClick={() => handleReadChip(se.seId)}
              onDelete={() => handleRemoveSalaryExpectation(se.seId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== se.seId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <StylesProvider jss={jss}>
      <Container maxWidth="lg" className={classes.root}>
        <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
        <Grid container>
          <Grid item style={{ width: "100%" }}>
            <Typography
              align="center"
              color="primary"
              variant="h5"
              className={classes.modalTitle}
            >
              {props.candidateFullName !== "undefined" &&
              props.candidateFullName.length > 1
                ? `ציפיות השכר של  ${props.candidateFullName}`
                : "ציפיות שכר"}
            </Typography>
            <Divider />
            <div>
              <Collapse in={salaryExpectationsAIObject !== null}>
                <Typography variant="h5" className={classes.modalTitle}>
                  ציפיות שכר
                </Typography>
                {displayChips}
                <Divider />
              </Collapse>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: theme.spacing(1),
              }}
            >
              <Typography
                style={{ fontWeight: 700 }}
                color="textPrimary"
                variant="subtitle1"
              >
                הוסף ציפיות שכר:
              </Typography>
              <MyTooltip
                marginRight
                arrow
                caption="יתכנו ציפיות שכר שונות לתפקידים שונים. אם לא רלוונטי, הזן הכל"
              />
            </div>
            <TextField
              id="forRole"
              label={useForAllRoles ? "כל התפקידים" : "לתפקיד"}
              variant="outlined"
              disabled={useForAllRoles}
              value={seForRole}
              onChange={handleForRoleChange}
              style={{ width: "100%" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useForAllRoles}
                  onChange={handleChangeForAllRoles}
                  name="forAllrole"
                  color="secondary"
                />
              }
              label="ציפיות שכר אלה הן עבור כל סוגי התפקידים"
            />
            <div
              style={{
                marginRight: theme.spacing(1),
                marginTop: theme.spacing(2),
              }}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend">סוג התשלום:</FormLabel>
                <RadioGroup
                  aria-label="useSliderOption"
                  name="useSlider"
                  value={salaryMeasure}
                  onChange={handleChangeSalaryMeasure}
                >
                  <FormControlLabel
                    value={"global"}
                    control={<Radio />}
                    label="שכר גלובלי"
                  />
                  <FormControlLabel
                    value={"hour"}
                    control={<Radio />}
                    label="שכר שעתי"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div
              style={{
                marginRight: theme.spacing(1),
                marginTop: theme.spacing(2),
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!useRange}
                    onChange={handleChangeSliderRange}
                    name="useRangeCheck"
                    color="secondary"
                  />
                }
                disabled={salaryMeasure === "hour"}
                label="שכר החל מ:"
              />
              <MyTooltip
                marginRight
                arrow
                caption="בחר אופציה זו אם ברצונך להחליף טווח במינימום (החל מ...)"
              />
            </div>
            <div className={classes.root}>
              <Typography id="range-slider" gutterBottom>
                הזן את טווח ציפיות השכר
              </Typography>
              <Slider
                value={salaryRange}
                onChange={handleSalaryRangeChange}
                min={salaryMeasure === "global" ? 2000 : 30}
                max={salaryMeasure === "global" ? 20000 : 250}
                step={salaryMeasure === "global" ? 500 : 0.5}
                aria-labelledby="range-slider"
                getAriaValueText={salaryExpectationsLabel}
                valueLabelDisplay="on"
              />
            </div>
            <Box my={2}>
              <FilterQuestionRelationsTag
                category={AI_SALARY_EXPECTATIONS}
                value={tags}
                onSelect={(value) => setTags(value)}
                readOnly
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                disabled={salaryRange.length === 0}
                className={classes.button}
                endIcon={<Icon>add</Icon>}
                onClick={
                  updatedChip <= 0
                    ? handleAddSingleSalaryExpectationAI
                    : updateSelectedChip
                } //add new Object with all values of this Component
              >
                {updatedChip > 0 ? "עדכן ציפיות שכר" : "הוסף ציפיות שכר"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  Object.keys(salaryExpectationsAIObject).length <= 0 ||
                  salaryExpectationsAIObject.length <= 0
                }
                className={classes.button}
                endIcon={<Icon>done</Icon>}
                onClick={buildAISalaryExpectations}
              >
                סיימתי
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StylesProvider>
  );
};
export default React.memo(AISalaryExpectations);
