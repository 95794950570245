import {
  Modal,
  Typography,
  Slide,
  Box,
  Paper,
  TextField,
  Button,
  Container,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    "& .MuiPaper-root": {
      padding: theme.spacing(2),
    },
    "& .MuiButton-text": {
      color: "#636575",
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(4),
  },
}));

const EditExploreSearch = ({ open, close, searchData, onSave }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [localSearchName, setLocalSearchName] = React.useState(null);

  React.useEffect(() => {
    setLocalSearchName(searchData.name);
  }, [searchData]);

  const handleOnSave = () => {
    const localSearchData = searchData;
    localSearchData.name = localSearchName;
    onSave(localSearchData);
  };

  return (
    <Modal
      open={open}
      onClose={close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="sm">
        <Slide
          direction="down"
          in={open}
          mountOnEnter
          unmountOnExit
          onExit={close}
        >
          <div className={classes.root}>
            <Paper elevation={4} className={classes.paper}>
              <React.Fragment>
                <Typography variant="h4" paragraph>
                  עריכת חיפוש
                </Typography>
                <Box my={theme.spacing(4)}>
                  <TextField
                    variant="filled"
                    value={localSearchName}
                    onChange={(event) => setLocalSearchName(event.target.value)}
                    fullWidth
                  />
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    style={{ float: "left", marginLeft: theme.spacing(2) }}
                    onClick={close}
                  >
                    בטל
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "left" }}
                    onClick={handleOnSave}
                  >
                    שמור
                  </Button>
                </Box>
              </React.Fragment>
            </Paper>
          </div>
        </Slide>
      </Container>
    </Modal>
  );
};

export default EditExploreSearch;
