import axios from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../reactQuery/rqConstants";

export const addTag = async (tag) => {
  try {
    return await axios.post("/wp-json/api/v2/fqTag", {
      tag,
    });
  } catch (err) {
    return err;
  }
};

export const editTag = async (tag) => {
  if (tag.id && parseInt(tag.id) > 0)
    return await axios.post(`/wp-json/api/v2/fqTag/${tag.id}`, tag);
};

export const removeTag = async (tagId) => {
  if (tagId > 0) return await axios.delete(`/wp-json/api/v2/fqTag/${tagId}`);
};

export const getTags = async (
  page,
  limit,
  contains = "",
  categories = [],
  sortBy = "id"
) => {
  try {
    const tags = await axios.get("/wp-json/api/v2/fqtags", {
      params: {
        page,
        limit,
        contains: contains !== "" ? contains : undefined,
        categories,
        sortBy,
      },
    });
    return tags.data;
  } catch (err) {
    return err;
  }
};

const useTags = (
  page = 1,
  limit = 50,
  contains = undefined,
  categories,
  sortBy = "id"
) => {
  const data = useQuery(
    [queryKeys.tags, page, categories, sortBy],
    () => getTags(page, limit, contains, categories, sortBy),
    {
      cacheTime: 600000,
      retry: 2,
      staleTime: 60000,
      keepPreviousData: true,
    }
  );
  return data;
};
export default useTags;
