import React from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { pink } from "@material-ui/core/colors";

const filterBy = [
  { id: "all", label: "כל המועמדים" },
  { id: "watched", label: "צפו" },
  { id: "notSent", label: "לא נשלחו" },
  { id: "invitedNotResponded", label: "זומנו ולא הגיבו" },
  { id: "watchedNotResponded", label: "צפו ולא הגיבו" },
  { id: "interested", label: "מעוניינים" },
  { id: "suitable", label: "מתאימים" },
  { id: "notInterested", label: "לא מעוניינים" },
  { id: "autoRejected", label: "נדחו אוטומטית" },
  { id: "cvReferrals", label: "הופנו קורות חיים" },
  { id: "newSuitables", label: "מתאימים חדשים" },
  { id: "sendToInterview", label: "נשלחו לראיון" },
  { id: "recruited", label: "גוייסו למשרה" },
  { id: "blocked", label: "חסומים" },
  { id: "unsubscribed", label: "הוסרו מדיוור" },
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    "& .MuiSelect-root": {
      backgroundColor: pink[50],
      color: theme.palette.primary.main,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FilterCandidates = ({ value, onSelect }) => {
  const classes = useStyles();
  return (
    <Box>
      <FormControl className={classes.formControl}>
        <Select
          labelId="filter-candidates"
          id="filter-candidates"
          variant="outlined"
          value={value ? value : "all"}
          onChange={onSelect}
        >
          {filterBy.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
export default FilterCandidates;
