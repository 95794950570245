import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Collapse,
  useTheme,
  Button,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import DeleteEditActionButtons from "../DeleteEditActionButtons/DeleteEditActionButtons";
import FQExperience from "../../Screens/Jobs/AddJob/FilterQuestions/FQExpirience/FQExperience";
import FQTalents from "../../Screens/Jobs/AddJob/FilterQuestions/FQTalents/FQTalents";
import FQLicense from "../../Screens/Jobs/AddJob/FilterQuestions/FQLicense/FQLicense";
import AcademicDegree from "../../Screens/Jobs/AddJob/FilterQuestions/AcademicDegree/AcademicDegree";
import FQCertificate from "../../Screens/Jobs/AddJob/FilterQuestions/FQCertificate/FQCertificate";
import FQLanguages from "../../Screens/Jobs/AddJob/FilterQuestions/FQLanguages/FQLanguages";
import FQDynamic from "../../Screens/Jobs/AddJob/FilterQuestions/FQDynamic/FQDynamic";
import {
  AI_EXPERIENCES,
  AI_SKILLS,
  AI_LICENSES,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LANGUAGES,
  AI_DYNAMICS,
} from "../../config/constants";
import ConfigurationListsContext from "../../context/configurationListsContext";
import YesNoDialog from "../../components/DialogYesNo/DialogYesNo";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import {
  translateLanguageLevel,
  translateDegreeAndType,
  translateSkillLevel,
} from "../../config/jobsConfigData";

const DisabledCheckBox = withStyles({
  root: {
    color: pink[100],
    "&$checked": {
      color: pink[100],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #E4E4EA",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 4,
  },
  fqCount: {
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-root": {
      marginRight: theme.spacing(2),
      width: 110,
    },
  },
  columnTitle: {
    fontSize: 14,
  },
  columnValue: {
    fontWeight: 700,
  },
  rowGrid: {
    "& .MuiGrid-item": {
      marginBottom: 0,
    },
  },
}));

const MyBadge = ({ value, invisble }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: 25,
        height: 25,
        borderRadius: 25,
        display: invisble ? "none" : "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        color: "#fff",
        lineHeight: "25px",
      }}
    >
      {value}
    </div>
  );
};

function uniqueIdNameByType(type) {
  switch (type) {
    case AI_EXPERIENCES:
      return "experienceId";
    case AI_ACADEMIC_DGREE:
      return "degreeId";
    case AI_CERTIFICATES:
      return "certificateId";
    case AI_DYNAMICS:
      return "dynamicId";
    case AI_LANGUAGES:
      return "languageId";
    case AI_LICENSES:
      return "licenseId";
    case AI_SKILLS:
      return "skillId";

    default:
      break;
  }
}

const FQDropdownBox = ({
  fqName,
  fqValue,
  type,
  addFQ,
  locked,
  readOnlyMode = false,
  textColor = "textSecondary",
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [fqItemEdit, setFqItemEdit] = React.useState(null);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [fqItemRemove, setFqItemRemove] = React.useState(null);

  const detectFQType = () => {
    switch (type) {
      case AI_EXPERIENCES:
        return (
          <FQExperience
            onCancel={handleOnCancel}
            onCompletion={(value) => handleOnCompletion(value, "experienceId")}
            fqEdit={isEdit && fqItemEdit ? fqItemEdit : null}
            isActive={isCollapsed}
            isActiveReadOnly={locked}
          />
        );
      case AI_SKILLS:
        return (
          <FQTalents
            onCancel={handleOnCancel}
            onCompletion={(value) => handleOnCompletion(value, "skillId")}
            fqEdit={isEdit && fqItemEdit ? fqItemEdit : null}
            isActive={isCollapsed}
            isActiveReadOnly={locked}
          />
        );
      case AI_LICENSES:
        return (
          <FQLicense
            onCancel={handleOnCancel}
            onCompletion={(value) => handleOnCompletion(value, "licenseId")}
            fqEdit={isEdit && fqItemEdit ? fqItemEdit : null}
            isActive={isCollapsed}
            isActiveReadOnly={locked}
          />
        );
      case AI_LANGUAGES:
        return (
          <FQLanguages
            onCancel={handleOnCancel}
            onCompletion={(value) => handleOnCompletion(value, "languageId")}
            fqEdit={isEdit && fqItemEdit ? fqItemEdit : null}
            isActive={isCollapsed}
            isActiveReadOnly={locked}
          />
        );
      case AI_ACADEMIC_DGREE:
        return (
          <AcademicDegree
            onCancel={handleOnCancel}
            onCompletion={(value) => handleOnCompletion(value, "degreeId")}
            fqEdit={isEdit && fqItemEdit ? fqItemEdit : null}
            isActive={isCollapsed}
            isActiveReadOnly={locked}
          />
        );
      case AI_CERTIFICATES:
        return (
          <FQCertificate
            onCancel={handleOnCancel}
            onCompletion={(value) => handleOnCompletion(value, "certificateId")}
            fqEdit={isEdit && fqItemEdit ? fqItemEdit : null}
            isActive={isCollapsed}
            isActiveReadOnly={locked}
          />
        );
      case AI_DYNAMICS:
        return (
          <FQDynamic
            onCancel={handleOnCancel}
            onCompletion={(value) => handleOnCompletion(value, "dynamicId")}
            fqEdit={isEdit && fqItemEdit ? fqItemEdit : null}
            isActive={isCollapsed}
            isActiveReadOnly={locked}
          />
        );
      default:
        break;
    }
  };

  const handleOnCompletion = (fqNewObject, idName) => {
    handleOnCancel(); //cleanup
    //check if this is an edit or new Item, by checking if the item has an id. if Id exists, edit - else, new Item
    let fqLists = fqValue;
    if (fqNewObject[idName]) {
      //find the index to update:
      const index = fqValue.findIndex(
        (item) => item[idName] === parseInt(fqNewObject[idName])
      );
      if (index >= 0) {
        //update the object:
        fqLists[index] = fqNewObject;
      }
    } else {
      const nextId =
        fqValue.length === 0
          ? 1
          : Math.max.apply(
              Math,
              fqValue.map(function (o) {
                return parseInt(o[idName]) + 1;
              })
            );
      const localNewFQObject = { ...fqNewObject, [idName]: nextId };
      fqLists = [...fqValue, localNewFQObject];
    }

    addFQ(fqLists, type);
  };

  const handleOnCancel = () => {
    setIsCollapsed(false);
    if (isEdit) {
      setIsEdit(false);
      setFqItemEdit(null);
    }
  };

  const handleOnRemove = (item) => {
    setConfirmDelete(true);
    setFqItemRemove(item);
  };

  const handleCancelRemove = () => {
    setConfirmDelete(false);
    setFqItemRemove(null);
  };

  const handleConfirmDelete = () => {
    setConfirmDelete(false);
    setFqItemRemove(null);
    const fqItemsCopy = fqValue;
    const removeIndex = fqItemsCopy.findIndex(
      (singleFQItem) =>
        parseInt(singleFQItem[uniqueIdNameByType(type)]) ===
        parseInt(fqItemRemove[uniqueIdNameByType(type)])
    );
    if (removeIndex >= 0) {
      fqItemsCopy.splice(removeIndex, 1);
    }
    addFQ(fqItemsCopy);
  };

  const handleOnEdit = (fqItem) => {
    setIsCollapsed(true);
    setIsEdit(true);
    setFqItemEdit(fqItem);
  };

  const handleAddUpdateClick = () => {
    setIsCollapsed((prev) => !prev);
    if (isEdit) {
      setIsEdit(false);
      setFqItemEdit(null);
    }
  };

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flex={1}
      >
        <div className={classes.fqCount}>
          <Typography variant="body1" color={textColor}>
            {fqName}
          </Typography>
          <MyBadge
            backgroundColor={theme.palette.secondary.main}
            value={fqValue.length}
            invisble={!fqValue || fqValue.length === 0}
          />
        </div>
        <div>
          {locked && (
            <IconButton aria-label="lock">
              <LockRoundedIcon fontSize="small" />
            </IconButton>
          )}
          {!readOnlyMode && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddUpdateClick}
              disabled={locked}
            >
              {isEdit ? "עדכון" : "הוספה"}
            </Button>
          )}
        </div>
      </Box>
      <Collapse in={isCollapsed}>
        <Box py={theme.spacing(2)}>
          <Divider
            style={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
            }}
          />
          {detectFQType()}
        </Box>
      </Collapse>
      {fqValue &&
        fqValue.map((fqItem) => (
          <Box mt={theme.spacing(1)} key={fqItem[uniqueIdNameByType(type)]}>
            <Divider style={{ marginBottom: theme.spacing(1) }} />
            <FQRow
              fqItem={fqItem}
              fqType={type}
              onEdit={handleOnEdit}
              onRemove={handleOnRemove}
              readOnlyMode={readOnlyMode || locked}
            />
          </Box>
        ))}
      {confirmDelete && (
        <YesNoDialog
          open={confirmDelete}
          onClose={handleCancelRemove}
          title={"הסרת שאלת סינון"}
          description="האם להסיר שאלת סינון?"
          positiveButtonLabel="בטוח"
          onConfirm={handleConfirmDelete}
          negativeButtonLabel="בטל"
        />
      )}
    </div>
  );
};
export default FQDropdownBox;

const FQRow = ({ fqItem, fqType, onEdit, onRemove, readOnlyMode = false }) => {
  const classes = useStyles();

  const handleOnEdit = () => {
    onEdit(fqItem);
  };

  const handleOnRemove = () => {
    onRemove(fqItem);
  };

  switch (fqType) {
    case AI_EXPERIENCES:
      return (
        <Grid container spacing={2} className={classes.rowGrid}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                מקצוע
              </Typography>
              <ConfigurationListsContext.Consumer>
                {(context) => (
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.columnValue}
                  >
                    {
                      context.professionsList.find(
                        (prof) =>
                          prof.id === parseInt(fqItem.selectedProfession)
                      ).name
                    }
                  </Typography>
                )}
              </ConfigurationListsContext.Consumer>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                שנות ניסיון
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {fqItem.minYears}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                תנאי חובה
              </Typography>
              <FormControlLabel
                disabled
                control={
                  <DisabledCheckBox
                    name="isAMust"
                    checked={
                      fqItem.isAMust === 1 || fqItem.isAMust ? true : false
                    }
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="flex-end"
              mt={"20px"}
            >
              <DeleteEditActionButtons
                onEdit={handleOnEdit}
                onRemove={handleOnRemove}
                readOnlyMode={readOnlyMode}
              />
            </Box>
          </Grid>
        </Grid>
      );
    case AI_SKILLS:
      return (
        <Grid container spacing={2} className={classes.rowGrid}>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                מיומנות
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {fqItem.skillName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                רמת מיומנות נדרשת
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {translateSkillLevel(fqItem.knowledgeLevelRequired)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                תנאי חובה
              </Typography>
              <FormControlLabel
                disabled
                control={
                  <DisabledCheckBox
                    name="isAMust"
                    checked={fqItem.isSkillIsMust || fqItem.isSkillAMust} //Unfortunate error where this property is being called otherwise in two related components.
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="flex-end"
              mt={"20px"}
            >
              <DeleteEditActionButtons
                onEdit={handleOnEdit}
                onRemove={handleOnRemove}
                readOnlyMode={readOnlyMode}
              />
            </Box>
          </Grid>
        </Grid>
      );
    case AI_LICENSES:
      return (
        <Grid container spacing={2} className={classes.rowGrid}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                רישיון
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {fqItem.licenseOn}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                שנות החזקה ברשיון
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {fqItem.minYearsLicense}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                תנאי חובה
              </Typography>
              <FormControlLabel
                disabled
                control={
                  <DisabledCheckBox
                    name="isAMust"
                    checked={fqItem.isLicenseAMust}
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="flex-end"
              mt={"20px"}
            >
              <DeleteEditActionButtons
                onEdit={handleOnEdit}
                onRemove={handleOnRemove}
                readOnlyMode={readOnlyMode}
              />
            </Box>
          </Grid>
        </Grid>
      );
    case AI_LANGUAGES:
      return (
        <Grid container spacing={2} className={classes.rowGrid}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                השפה
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {fqItem.languageName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                רמת הידע הנדרשת
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {translateLanguageLevel(fqItem.languageLevel)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                תנאי חובה
              </Typography>
              <FormControlLabel
                disabled
                control={
                  <DisabledCheckBox
                    name="isAMust"
                    checked={fqItem.languageIsAMust ? true : false}
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="flex-end"
              mt={"20px"}
            >
              <DeleteEditActionButtons
                onEdit={handleOnEdit}
                onRemove={handleOnRemove}
                readOnlyMode={readOnlyMode}
              />
            </Box>
          </Grid>
        </Grid>
      );
    case AI_ACADEMIC_DGREE:
      return (
        <Grid container spacing={2} className={classes.rowGrid}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                התואר
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {translateDegreeAndType(fqItem.selectedDegree)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                סוג התואר
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {fqItem.degreeType === "general"
                  ? "תואר כללי"
                  : fqItem.degreeType}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                תנאי חובה
              </Typography>
              <FormControlLabel
                disabled
                control={
                  <DisabledCheckBox
                    name="isAMust"
                    checked={fqItem.isDegreeAMust}
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="flex-end"
              mt={"20px"}
            >
              <DeleteEditActionButtons
                onEdit={handleOnEdit}
                onRemove={handleOnRemove}
                readOnlyMode={readOnlyMode}
              />
            </Box>
          </Grid>
        </Grid>
      );
    case AI_CERTIFICATES:
      return (
        <Grid container spacing={2} className={classes.rowGrid}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                התעודה
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {fqItem.selectedCertificate}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                תנאי חובה
              </Typography>
              <FormControlLabel
                disabled
                control={
                  <DisabledCheckBox
                    name="isAMust"
                    checked={fqItem.isCertificateAMust}
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="flex-end"
              mt={"20px"}
            >
              <DeleteEditActionButtons
                onEdit={handleOnEdit}
                onRemove={handleOnRemove}
                readOnlyMode={readOnlyMode}
              />
            </Box>
          </Grid>
        </Grid>
      );
    case AI_DYNAMICS:
      return (
        <Grid container spacing={2} className={classes.rowGrid}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                השאלה
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {fqItem.displayQuestion}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                תשובה עוברת
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.columnValue}
              >
                {fqItem.passAnswer === "yes" ? "כן" : "לא"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.columnTitle}
              >
                תנאי חובה
              </Typography>
              <FormControlLabel
                disabled
                control={
                  <DisabledCheckBox name="isAMust" checked={fqItem.isAMust} />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="flex-end"
              mt={"20px"}
            >
              <DeleteEditActionButtons
                onEdit={handleOnEdit}
                onRemove={handleOnRemove}
                readOnlyMode={readOnlyMode}
              />
            </Box>
          </Grid>
        </Grid>
      );
    default:
      return <div></div>;
  }
};
