import React from "react";
import { TextField } from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

const GeneralAutocompleteSelect = ({
  onSelect,
  list,
  label,
  placeholder,
  styling = null,
}) => {
  return (
    <Autocomplete
      multiple
      id="tags-cities"
      options={list}
      onChange={(e, value) => {
        onSelect(value.map((v) => v.name));
      }}
      getOptionLabel={(option) => option.label}
      style={styling}
      getOptionSelected={(option, value) => option.name === value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          placeholder={placeholder}
          margin="normal"
        />
      )}
    />
  );
};
export default GeneralAutocompleteSelect;
