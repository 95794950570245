import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, Box, Typography } from "@material-ui/core";
import DuplicateIcon from "../../Assets/CustomIcons/DuplicateIcon";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      minWidth: 120,
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
    },
  },
  svgDuplicateIcon: {
    color: "#FC6E00",
  },
}));

export default function ConfirmationAutoDistribution({
  open,
  onClose,
  onConfirm,
  isAutoDistribute,
  jobId,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <Box
        py={theme.spacing(2)}
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <DuplicateIcon fontSize="80" />
      </Box>
      <Box justifyContent="center" display="flex" alignItems="center">
        <Typography variant="h5" color="textPrimary" gutterBottom={true}>
          {`הפצה אוטומטית למשרה מספר ${jobId}`}
        </Typography>
      </Box>

      <DialogContent>
        <Box>
          {isAutoDistribute ? (
            <React.Fragment>
              <Typography variant="body1" color="textSecondary">
                {`האם להסיר את משרה מספר ${jobId} מהמשך הפצה אוטומטית?`}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                עם אישור הודעה זו, לא יופצו שאלונים למועמדים שייובאו למשרה זו
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant="body1" color="textSecondary">
                {`האם להפוך את משרה מספר  ${jobId} להפצה אוטומטית?`}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                סימון הפצה אוטומטית ישלח שאלון משרה לכל מועמד שייובא למשרה זו
              </Typography>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onConfirm(!isAutoDistribute)}
          color="primary"
        >
          אישור
        </Button>
        <Button variant="outlined" onClick={onClose} color="primary">
          ביטול פעולה
        </Button>
      </DialogActions>
    </Dialog>
  );
}
