import React, { useState } from "react";
import {
  Checkbox,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Box,
  FormControlLabel,
} from "@material-ui/core";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import FilterQuestionDisplayPrevew from "../FilterQuestionDisplayPreview";
import { languagePreviewQuestion } from "../../../../../config/jobsConfigData";
import FilterQuestionRelationsTag from "../FilterQuestionRelationsTag";
import { AI_LANGUAGES } from "../../../../../config/constants";

const FQLanguages = ({
  onCancel,
  onCompletion,
  fqEdit = null,
  isActive,
  isActiveReadOnly,
}) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState("");
  const [languageLevel, setLanguageLevel] = React.useState("average");
  const [languageIsAMust, setLanguageIsAMust] = React.useState(false);
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [tags, setTags] = useState([]);

  React.useEffect(() => {
    if (fqEdit !== null) {
      setSelectedLanguage(fqEdit.languageName);
      setLanguageLevel(fqEdit.languageLevel);
      setLanguageIsAMust(
        parseInt(fqEdit.languageIsAMust) === 1 || fqEdit.languageIsAMust
          ? true
          : false
      );
      setAdditionalInfo(fqEdit.addInfo);
      setTags(fqEdit.tags);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  const handleCheckIsAMust = (event) => {
    setLanguageIsAMust(event.target.checked);
  };

  const handleSelectedLanguage = (event) => {
    setSelectedLanguage(event.target.value);
  };

  /*
  Single Language Object: 
  {
      langueageId: id,
      langueageName: string, 
      languageLevel: string,     
      langueageIsAMust: bool
  }
  */

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["langueageName"] = selectedLanguage;
      existingObject["languageLevel"] = languageLevel;
      existingObject["languageIsAMust"] = languageIsAMust;
      existingObject["addInfo"] = additionalInfo;
      existingObject["tags"] = tags;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      languageName: selectedLanguage,
      languageLevel: languageLevel,
      languageIsAMust,
      addInfo: additionalInfo,
      tags,
    });
  };

  const clearForm = () => {
    //clean the form values:
    setSelectedLanguage("");
    setLanguageLevel("average");
    setLanguageIsAMust(false);
    setAdditionalInfo("");
    setTags([]);
  };

  const handleKnowledgeLevelChange = (event) => {
    setLanguageLevel(event.target.value);
  };

  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          הוספת שפה
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="language"
            label="השפה"
            variant="filled"
            value={selectedLanguage}
            onChange={handleSelectedLanguage}
            fullWidth
            disabled={isActiveReadOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="additionalInfo"
            label="מידע נוסף למועמד"
            variant="filled"
            value={additionalInfo}
            onChange={(event) => setAdditionalInfo(event.target.value)}
            fullWidth
            helperText="לדוגמה: קריאה, דיבור וכתיבה"
            disabled={isActiveReadOnly}
          />
        </Grid>
        <Grid item xs={6}>
          {isActive && (
            <FilterQuestionRelationsTag
              category={AI_LANGUAGES}
              term={selectedLanguage}
              onSelect={(value) => setTags(value)}
              value={tags}
              readOnly={isActiveReadOnly}
            />
          )}
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" disabled={isActiveReadOnly}>
            <FormLabel component="legend">רמת השליטה הנדרשת בשפה זו</FormLabel>
            <RadioGroup
              aria-label="knowledgeLevel"
              name="knowledgeLevel"
              value={languageLevel}
              onChange={handleKnowledgeLevelChange}
            >
              <FormControlLabel
                value={"motherTounge"}
                control={
                  <Radio
                    checked={languageLevel === "motherTounge"}
                    color="primary"
                  />
                }
                label="שפת אם"
              />
              <FormControlLabel
                value={"basic"}
                control={
                  <Radio checked={languageLevel === "basic"} color="primary" />
                }
                label="בסיסית"
              />
              <FormControlLabel
                value={"average"}
                control={
                  <Radio
                    checked={languageLevel === "average"}
                    color="primary"
                  />
                }
                label="ממוצעת"
              />
              <FormControlLabel
                value={"good"}
                control={
                  <Radio checked={languageLevel === "good"} color="primary" />
                }
                label="טובה"
              />
              <FormControlLabel
                value={"veryGood"}
                control={
                  <Radio
                    checked={languageLevel === "veryGood"}
                    color="primary"
                  />
                }
                label="טובה מאוד"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={isActiveReadOnly}
            control={
              <Checkbox
                checked={languageIsAMust ? true : false}
                onClick={handleCheckIsAMust}
                name="requiredQ"
                color="primary"
              />
            }
            label="דרישה זו היא חובה"
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FilterQuestionDisplayPrevew
              previewQuestion={languagePreviewQuestion(
                selectedLanguage ? selectedLanguage.trim() : "",
                additionalInfo
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CancelApproveButtons
            positiveCaption="סיימתי"
            negativeCaption="ביטול"
            positiveDisabled={
              selectedLanguage === "" || !tags || tags.length === 0
            }
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default React.memo(FQLanguages);
