import React from "react";

const ConfigurationListsContext = React.createContext({
  professionsList: [],
  customersList: [],
  jobExtentList: [],
  areasList: [],
  users: [],
});

export default ConfigurationListsContext;
