import React from "react";
import { Divider, Tooltip, Typography, useTheme } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTooltip-root": {
      backgroundColor: "rgba(220, 0, 78, 0.8)",
    },
  },
}));

const ResponseRateTooltip = withStyles({
  tooltip: {
    backgroundColor: "#363740",
  },
})(Tooltip);

const CustomTooltip = ({ title, color, total, totalPercent, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <ResponseRateTooltip
      arrow
      className={classes.root}
      title={
        <div>
          <Typography variant="body1">{title}</Typography>
          <Divider
            style={{
              backgroundColor: theme.palette.grey[100],
              marginBottom: theme.spacing(1),
              marginTop: theme.spacing(1),
            }}
          />
          <Typography variant="body1" style={{ color }}>
            {total} מועמדים /{totalPercent}
            {"%"}
          </Typography>
        </div>
      }
    >
      {props.children}
    </ResponseRateTooltip>
  );
};

const ResponseRateProgress = ({
  totalWatched,
  totalWatchedPercent,
  totalInterested,
  totalInterestedPercent,
  totalWatchedNotResponded,
  totalWatchedNotRespondedPercent,
  totalNotInterested,
  totalNotInterestedPercent,
  ...props
}) => {
  const classes = {
    root: {
      width: "100%",
      maxWidth: props.maxWidth ? props.maxWidth : "100%",
      height: 25,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      border: "1px solid #F5F6F8",
      backgroundColor: "#F5F6F8",
      borderTopRightRadius: 50,
      borderTopLeftRadius: 50,
      borderBottomRightRadius: 50,
      borderBottomLeftRadius: 50,
      overflow: "hidden",
    },
    interested: {
      background: "#0BD03B",
      height: "100%",
      width: totalInterestedPercent + "%",
      margin: 0,
    },
    watchedNotResponded: {
      background: "#FC6E00",
      height: "100%",
      width: totalWatchedNotRespondedPercent + "%",
      margin: 0,
    },
    notInterested: {
      background: "#E51010",
      height: "100%",
      width: totalNotInterestedPercent + "%",
      margin: 0,
    },
    watched: {
      height: "100%",
      margin: 0,
      width: 30,
    },
  };
  return (
    <div style={classes.root}>
      <CustomTooltip
        title="מועמדים מעוניינים"
        color={classes.interested.background}
        total={totalInterested}
        totalPercent={totalInterestedPercent}
      >
        <div style={classes.interested}></div>
      </CustomTooltip>
      <CustomTooltip
        title="מועמדים שצפו אך לא הגיבו"
        color={classes.watchedNotResponded.background}
        total={totalWatchedNotResponded}
        totalPercent={totalWatchedNotRespondedPercent}
      >
        <div style={classes.watchedNotResponded}></div>
      </CustomTooltip>
      <CustomTooltip
        title="לא מעוניינים"
        color={classes.notInterested.background}
        total={totalNotInterested}
        totalPercent={totalNotInterestedPercent}
      >
        <div style={classes.notInterested}></div>
      </CustomTooltip>

      <CustomTooltip
        title="צפו במשרה"
        color={classes.watched.background}
        total={totalWatched}
        totalPercent={totalWatchedPercent}
      >
        <div style={classes.watched}></div>
      </CustomTooltip>
    </div>
  );
};

export default ResponseRateProgress;
