import React from "react";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {
  Grid,
  Paper,
  Button,
  makeStyles,
  Container,
  Typography,
  Box,
  Snackbar,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Pagination from "../../UI/Pagination/Pagination";
import {
  LinkToJobIcon,
  SaveIcon,
} from "../../Assets/CustomIcons/CustomIconsList";
import Candidates from "../Candidates/Candidates";
import SaveSerach from "./SaveSearch";
import axios from "axios";
const DRAWER_WIDTH = 240;
const useStyles = makeStyles((theme) => ({
  root: {},
  topSearchBar: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: "fixed",
    top: 0,
    zIndex: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    width: "calc(100% - " + DRAWER_WIDTH + "px)",
  },
  toolbarButtons: {
    "& .MuiButton-root": {
      marginRight: theme.spacing(2),
      background: "#F5F6F8",
      color: "#636575",
      "&:hover": {
        background: "#d5d5d5",
      },
    },
    "& .MuiIconButton-root": {
      marginRight: theme.spacing(2),
      background: "#F5F6F8",
      color: "#636575",
      "&:hover": {
        background: "#d5d5d5",
      },
    },
  },
}));

const ExploreResults = ({
  candidates,
  total = 1,
  pages = 1,
  searchData,
  disableSave,
  onSaveSearch,
  onBack,
  onPageChange,
  currentPage,
}) => {
  const classes = useStyles();
  const [showSaveSearch, setShowSaveSearch] = React.useState(false);
  const [savedSuccessfully, setSavedSuccessfully] = React.useState(false);
  const [linkToJob, setLinkToJob] = React.useState(false);
  const [multipleSelectedCount, setMultipleSelectedCount] = React.useState(0);
  const handleOnSearch = async (data) => {
    setShowSaveSearch(false);
    const search = await axios.post("/wp-json/api/v2/save_explore_search", {
      name: data.name,
      isPublic: data.isPublic,
      searchData,
    });
    setSavedSuccessfully(true);
    onSaveSearch(search.data);
  };

  const showPagination = (
    <Pagination
      onChange={onPageChange}
      totalPages={pages}
      total={total}
      totalLabel={'סה"כ מועמדים:'}
      currentPage={currentPage}
      //reload={this.handleReloadPage}
    />
  );

  const handleCandidateSelected = (count) => {
    setMultipleSelectedCount(count);
  };

  const handleMultipleLinkToJob = () => setLinkToJob(true);

  const handleCloseMultipleLinkToJob = () => {
    setLinkToJob(false);
    setMultipleSelectedCount(0);
  };

  return (
    <React.Fragment>
      <Paper width={1} elevation={0} square className={classes.topSearchBar}>
        <div className={classes.topHeader}>
          <Grid container spacing={3} justify="flex-start" alignItems="center">
            <Grid item xs={9}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                className={classes.toolbarButtons}
              >
                <Tooltip title="חזרה לניהול חיפושים" aria-label="back">
                  <span>
                    <IconButton aria-label="back" onClick={onBack}>
                      <ChevronRight fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
                {/* <Button
                  variant="contained"
                  aria-label="filter"
                  endIcon={<UploadIcon />}
                  disableElevation
                >
                  בחירה מהחיפושים שלי
                </Button> */}
                <Button
                  variant="contained"
                  aria-label="filter"
                  endIcon={<SaveIcon />}
                  disableElevation
                  onClick={() => setShowSaveSearch(true)}
                  disabled={disableSave}
                >
                  הוסף לחיפושים שלי
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                aria-label="add"
                color="primary"
                endIcon={<LinkToJobIcon />}
                disabled={multipleSelectedCount === 0}
                onClick={handleMultipleLinkToJob}
              >
                שייך למשרה
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>

      <Container maxWidth={"xl"} style={{ marginTop: 90 }}>
        <Typography variant="h5">{`נמצאו ${total.toLocaleString("he-IL", {
          minimumFractionDigits: 0,
        })} מועמדים `}</Typography>
        {multipleSelectedCount > 0 && (
          <Typography variant="body1">{`נבחרו ${multipleSelectedCount.toLocaleString(
            "he-IL",
            {
              minimumFractionDigits: 0,
            }
          )} מועמדים `}</Typography>
        )}

        {candidates.length > 0 && (
          <React.Fragment>
            {showPagination}
            <Candidates
              disableDefaultSearch
              hideMultipleLinkToJobButton
              linkMultipleSelectedClick={linkToJob}
              onCloseMultipleLinkToJob={handleCloseMultipleLinkToJob}
              getSelectedCount={handleCandidateSelected}
              candidates={{
                data: candidates,
              }}
            />
            {showPagination}
          </React.Fragment>
        )}
      </Container>
      {showSaveSearch && (
        <SaveSerach
          open={showSaveSearch}
          close={() => setShowSaveSearch(false)}
          searchData={searchData}
          onSearch={handleOnSearch}
        />
      )}
      {savedSuccessfully && (
        <Snackbar
          open={savedSuccessfully}
          autoHideDuration={3000}
          onClose={() => setSavedSuccessfully(false)}
        >
          <Alert severity="success">החיפוש נשמר בהצלחה</Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

ExploreResults.propTypes = {
  searchData: PropTypes.array.isRequired,
};
export default ExploreResults;
