import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { Icon, Button, Box } from "@material-ui/core";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import FilterQuestionRelationsTag from "../../../../Jobs/AddJob/FilterQuestions/FilterQuestionRelationsTag";
import { AI_CERTIFICATES } from "../../../../../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const AICertificates = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedCertificate, setSelectedCertificate] = useState("");
  const [updatedChip, setUpdatedChip] = useState(-1);
  const [tags, setTags] = useState([]);

  const [certificatesAIObject, setCertificatesAIObject] = useState([]);

  useEffect(() => {
    if (props.aIValue.data && Object.keys(props.aIValue.data).length) {
      const aiTempObject = props.aIValue.data.map((aiItem) => {
        return (
          [...certificatesAIObject],
          {
            key: aiItem.certificateId,
            certificateId: aiItem.certificateId,
            certificateName: aiItem.certificateName,
          }
        );
      });
      setTags(props.aIValue.tags);
      setCertificatesAIObject(aiTempObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const handleSelectedCertificate = (event) => {
    setSelectedCertificate(event.target.value);
  };

  const buildAICertificateObject = () => {
    props.addAI({ data: certificatesAIObject, tags });
  };

  /*
  Single Certificate Object: 
  {
      certificateId: id,
      certificateName: string,    
  }
  */
  const handleAddSingleCertificateAI = () => {
    certificatesAIObject === null
      ? setCertificatesAIObject([
          {
            certificateId:
              certificatesAIObject === null
                ? 1
                : certificatesAIObject.length + 1,
            certificateName: selectedCertificate,
          },
        ])
      : setCertificatesAIObject([
          ...certificatesAIObject,
          {
            certificateId: certificatesAIObject.length + 1,
            certificateName: selectedCertificate,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = certificatesAIObject.find(
      (chip) => chip.certificateId === updatedChip
    );

    //remove the selected chip from the array:
    const tempCertificateAIObject = certificatesAIObject.filter(
      (ai) => ai.certificateId !== tempUpdatedChip.certificateId
    );

    //update the form:
    setCertificatesAIObject([
      ...tempCertificateAIObject,
      {
        certificateId: tempUpdatedChip.certificateId,
        certificateName: selectedCertificate,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setUpdatedChip(-1);
    setSelectedCertificate("");
  };

  const handleRemoveCertificate = (crtId) => {
    //locate index id
    const newCertificateAIbject = certificatesAIObject.filter(
      (crt) => crt.certificateId !== crtId
    );
    setCertificatesAIObject(newCertificateAIbject);
    clearForm();
  };

  const handleReadChip = (crtId) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleCrtObj = certificatesAIObject.find(
        (crt) => crt.certificateId === crtId
      );
      setSelectedCertificate(singleCrtObj.certificateName);
      setUpdatedChip(crtId);
    }
  };

  const displayChips =
    certificatesAIObject !== null
      ? certificatesAIObject.map((crt) => {
          return (
            <Chip
              key={crt.certificateId}
              icon={<FaceIcon />}
              label={crt.certificateName}
              clickable
              color="primary"
              onClick={() => handleReadChip(crt.certificateId)}
              onDelete={() => handleRemoveCertificate(crt.certificateId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== crt.certificateId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <StylesProvider jss={jss}>
      <Container maxWidth="lg" className={classes.root}>
        <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
        <Grid container>
          <Grid item style={{ width: "100%" }}>
            <Typography
              align="center"
              color="primary"
              variant="h5"
              className={classes.modalTitle}
            >
              {props.candidateFullName !== "undefined" &&
              props.candidateFullName.length > 1
                ? `התעודות של  ${props.candidateFullName}`
                : "תעודות"}
            </Typography>
            <Divider />
            <div>
              <Collapse in={certificatesAIObject !== null}>
                <Typography variant="h5" className={classes.modalTitle}>
                  תעודות המועמד
                </Typography>
                {displayChips}
                <Divider />
              </Collapse>
            </div>
            <Typography
              style={{ fontWeight: 700, marginBottom: 15 }}
              color="textPrimary"
              variant="subtitle1"
            >
              הוסף תעודה:
            </Typography>
            <TextField
              id="certificateId"
              label="תעודה"
              variant="outlined"
              value={selectedCertificate}
              onChange={handleSelectedCertificate}
              style={{ width: "100%", marginBottom: theme.spacing(2) }}
            />
            <Box my={2}>
              <FilterQuestionRelationsTag
                category={AI_CERTIFICATES}
                value={tags}
                onSelect={(value) => setTags(value)}
                readOnly
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                disabled={selectedCertificate === ""}
                className={classes.button}
                endIcon={<Icon>add</Icon>}
                onClick={
                  updatedChip <= 0
                    ? handleAddSingleCertificateAI
                    : updateSelectedChip
                } //add new Object with all values of this Component
              >
                {updatedChip > 0 ? "עדכן תעודה" : "הוסף תעודה"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  certificatesAIObject === null ||
                  certificatesAIObject === "undefined" ||
                  Object.keys(certificatesAIObject).length <= 0 ||
                  certificatesAIObject.length <= 0
                }
                className={classes.button}
                endIcon={<Icon>done</Icon>}
                onClick={buildAICertificateObject}
              >
                סיימתי
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StylesProvider>
  );
};
export default React.memo(AICertificates);
