import React from "react";
import {
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import { DashboardPeriod } from "../../config/dashboardConfigData";

const menuList = DashboardPeriod.map((item) => {
  return (
    <MenuItem key={item.value} value={item.value}>
      {item.label}
    </MenuItem>
  );
});

const PeriodSelection = ({
  label,
  selectedPeriod,
  onPeriodChange,
  noMargin = false,
  fullWidth = false,
  filled = false,
}) => {
  const theme = useTheme();
  return (
    <Box
      my={noMargin ? 0 : theme.spacing(2)}
      maxWidth={fullWidth ? "100%" : 200}
      width={"100%"}
    >
      <FormControl
        fullWidth
        margin={noMargin ? "dense" : "none"}
        variant={filled ? "filled" : "standard"}
      >
        <InputLabel id="demo-simple-select-label">
          {label ? label : "תקופת זמן"}
        </InputLabel>
        <Select
          labelId="select-period"
          id="select_period"
          value={selectedPeriod}
          onChange={onPeriodChange}
        >
          {menuList}
        </Select>
      </FormControl>
    </Box>
  );
};

export default PeriodSelection;
