import React from "react";
import { Typography, Box } from "@material-ui/core";
import StyledRating from "../StyledRating/StyledRating";
import StarIcon from "../../Assets/CustomIcons/StarIcon";
import { useTheme } from "@material-ui/core";

const SelectRating = ({ onSelect }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems={"center"}
      height={"100%"}
      justifyContent="flex-start"
    >
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginLeft: theme.spacing(2) }}
      >
        דירוג גבוה או שווה ל:
      </Typography>
      <StyledRating
        precision={0.5}
        name={"CandidateRating"}
        icon={<StarIcon strokeColor={"#8593FF"} />}
        onChange={(event, value) => onSelect(value)}
      />
    </Box>
  );
};
export default SelectRating;
