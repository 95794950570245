import React from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  customPaper: {
    opacity: 0.7,
    background: theme.palette.grey[700],
    padding: theme.spacing(2),
    color: "#fff",
  },
}));

export default function CustomPopover(props) {
  const classes = useStyles();

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.customPaper,
        }}
        open={props.anchorEl ? true : false}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={props.onClosePoper}
        disableRestoreFocus
      >
        {props.children}
      </Popover>
    </div>
  );
}
