import React from "react";
import {
  Paper,
  Typography,
  Collapse,
  Box,
  Avatar,
  Grid,
  IconButton,
  Tooltip,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import { grey, yellow, red } from "@material-ui/core/colors";
import JobAcceptedQuestionierSummary from "../../../../../components/JobAcceptedQuestionierSummary/JobAcceptedQuestionierSummary";
import { profileAgeGenderNew } from "../../../../Candidates/AgeGender";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import CandidateEvents from "../../../../Candidates/SingleCandidateFull/CandidateEvents/CandidateEvents";
import EventRow from "../../../../Candidates/SingleCandidateFull/CandidateEvents/EventRow";
import axios from "axios";
import EntityMoreActions from "../../../../../components/EntityMoreActions/EntitysMoreActions";
import CandidateNameLink from "../../../../Candidates/CandidateNameLink";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import MyAlert from "../../../../../UI/MyAlert/MyAlert";
import { MenuMoreOptions } from "../../../../../config/candidatesConfigData";
import { formatDistanceToNow } from "date-fns";
import { he } from "date-fns/esm/locale";
import {
  ReportIcon,
  PhoneIcon,
  ReadyIcon,
} from "../../../../../Assets/CustomIcons/CustomIconsList";
import StyledRating from "../../../../../components/StyledRating/StyledRating";
import StarIcon from "../../../../../Assets/CustomIcons/StarIcon";
import SendFill from "../../../../../Assets/CustomIcons/SendFill";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
//import SettingsBackupRestoreRoundedIcon from "@material-ui/icons/SettingsBackupRestoreRounded";
import AxiosErrorHandling from "../../../../../components/AxiosErrorHandling/AxiosErrorHandling";
import { AddEventHandler } from "../../../../../config/jobsConfigData";
import CandidateQuickView from "../../../../../components/CandidateQuickView/CandidateQuickView";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
    },
    "& .MuiIconButton-root.Mui-disabled": {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.main,
    },
    position: "relative",
  },
  disabledPaper: {
    backgroundColor: theme.palette.grey[300],
  },
  dot: {
    width: 8,
    height: 8,
    display: "flex",
    alignItems: "center",
    //backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    marginRight: theme.spacing(2),
  },
  rejected: {
    "& .MuiIconButton-root": {
      color: red[300],
    },
  },
  grey: {
    color: grey[700],
  },
  system3: {
    "& .MuiTypography-root": {
      color: theme.palette.grey["A200"],
    },
  },
  minHeight: {
    minHeight: 56,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },
  buttonCircled: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonCircledDisabled: {
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
  },
}));

const GreenDot = styled(TimelineDot)({
  backgroundColor: "#0BD03B",
  width: 4,
  height: 4,
  padding: 2,
});

const YellowDot = styled(TimelineDot)({
  backgroundColor: yellow[500],
  width: 4,
  height: 4,
  padding: 2,
});

const SuitbaleCandidateItem = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [expandCandidate, setExpandCandidate] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [eventsOffset, setEventsOffset] = React.useState(0);
  const [activeMenuItem, setActiveMenuItem] = React.useState("");
  const [totalEvents, setTotalEvents] = React.useState(5);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showQuestionier, setShowQuestionier] = React.useState(false);
  const [jobAutoClosed, setJobAutoClosed] = React.useState(false);
  const [showQuickView, setShowQuickView] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleReadCandidate = (e, id) => {
    setExpandCandidate(!expandCandidate);
    if (!expandCandidate) {
      handleExpandEvents();
      props.readCandidateClick(id);
    }
  };

  const handleGetCV = () => {
    axios
      .post(
        `https://services.adamtotal.co.il/ADMBI/RequestFiles/clientno/1021/user/lynadmin/password/9PiuGwyPzQPAoCjQHdwO/candno/${parseInt(
          props.candidateData.acf.externalNumber
        )}`,
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        const cvsRaw = res.data.map((file) => {
          return {
            fileContent: file.file_content,
            fileType: file.file_ext,
            fileName: file.file_name,
          };
        });
        const base64 = cvsRaw[0].fileContent;
        const link = document.createElement("a");
        link.href =
          "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," +
          base64;
        link.download = cvsRaw[0].fileName;
        link.click();
      })
      .catch((err) => {
        setError(err.response);
      });
  };

  const handleMoreActionsMenuClick = (event, action) => {
    setActiveMenuItem(action);
  };

  const handleExpandEvents = async () => {
    if (!expandCandidate) {
      await handleGetEvents();
    }

    setExpandCandidate(!expandCandidate);
  };

  const handleAddEvent = async (
    type,
    value,
    additionalInfo = "",
    isUpdateMode = false
  ) => {
    const eventHandler = await AddEventHandler(
      type,
      value,
      additionalInfo,
      isUpdateMode,
      parseInt(props.candidateData.id),
      parseInt(localStorage.getItem("userId"))
    );
    if (type === "recruitmentSucceeded" && eventHandler.auto_closed === true) {
      setJobAutoClosed(true);
    }
    setActiveMenuItem("");
    handleGetEvents();
  };

  const handleShowQuestionierReport = () => {
    setShowQuestionier(true);
  };

  const handleGetEvents = (offsetClick = false) => {
    const offset = eventsOffset;
    setIsLoading(true);
    axios
      .get(`/wp-json/api/v2/candidate_events/${props.candidateData.id}`, {
        params: {
          offset: offsetClick ? offset + 1 : offset,
        },
      })
      .then((res) => {
        if (res.data.length > 0 && offsetClick) {
          setEvents([...events, res.data[0]]);
          setEventsOffset(offset + 1);
        } else {
          setEvents(res.data);
        }
        setExpandCandidate(true);
        setIsLoading(false);
        setTotalEvents(res.headers["x-wp-total"]);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("I have an error", err);
      });
  };

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.root}>
        <Paper
          className={
            props.candidateData.read_reject &&
            props.candidateData.read_reject.rejected
              ? classes.disabledPaper
              : ""
          }
        >
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={250}
            >
              <Box display="flex" alignItems="center">
                {props.candidateData.read_reject &&
                props.candidateData.read_reject.read ? (
                  <div
                    className={classes.dot}
                    style={{ backgroundColor: grey[200] }}
                  ></div>
                ) : (
                  <div
                    className={classes.dot}
                    style={{ backgroundColor: theme.palette.secondary.main }}
                  ></div>
                )}

                <div>
                  <Avatar
                    alt={props.candidateData.name}
                    src={
                      props.candidateData.profile_image
                        ? props.candidateData.profile_image
                        : ""
                    }
                  />
                </div>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
                onClick={(e) => handleReadCandidate(e, props.candidateData.id)}
                className={classes.minHeight}
              >
                <div style={{ display: "flex" }}>
                  <CandidateNameLink
                    name={props.candidateData.name}
                    onNameClick={(e) =>
                      handleReadCandidate(e, props.candidateData.id)
                    }
                  />
                  <IconButton
                    aria-label="expandCollapse"
                    onClick={handleExpandEvents}
                    size="small"
                    style={{ marginRight: theme.spacing() }}
                  >
                    {expandCandidate ? (
                      <KeyboardArrowUpRoundedIcon />
                    ) : (
                      <KeyboardArrowDownRoundedIcon />
                    )}
                  </IconButton>
                </div>

                <Typography color="textPrimary" variant="body1">
                  {Object.keys(props.candidateData.professions).length > 0
                    ? props.candidateData.professions[0].name
                    : ""}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-start"
                  alignItems="center"
                  className={classes.system3}
                >
                  <Typography variant="smallText">
                    {props.candidateData.acf.city}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    style={{
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),
                      minHeight: 20,
                    }}
                  />
                  <Typography variant="smallText">
                    {profileAgeGenderNew(
                      props.candidateData.acf.gender,
                      props.candidateData.acf.yearOfBirth
                    )}
                  </Typography>
                </Box>

                <div className={classes.tags}>
                  <Typography variant="body1">
                    {props.candidateData.tags.map(
                      (tag) => "#" + tag.tag_name + " "
                    )}
                  </Typography>
                </div>
              </Box>
            </Box>

            <div>
              <div className={classes.minHeight}>
                <Typography
                  variant="body1"
                  color="primary"
                  onClick={handleShowQuestionierReport}
                  style={{
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  הצג דו"ח שאלון{" "}
                  {
                    <ReportIcon
                      color="primary"
                      style={{ marginRight: theme.spacing(2) }}
                    />
                  }
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {props.candidateData.questionier_result.result ===
                  "passed" ? (
                    <GreenDot />
                  ) : (
                    <YellowDot />
                  )}
                  <Typography
                    variant="smallText"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: theme.palette.grey["A200"],
                      marginRight: theme.spacing(1),
                    }}
                  >
                    לפני{" "}
                    {formatDistanceToNow(
                      new Date(
                        props.candidateData.questionier_result.timestamp
                      ),
                      {
                        locale: he,
                      }
                    )}
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.minHeight}>
                <div style={{ display: "flex" }}>
                  <CandidateNameLink
                    name={"הצג אירועים"}
                    onNameClick={(e) =>
                      handleReadCandidate(e, props.candidateData.id)
                    }
                  />

                  {
                    <IconButton
                      aria-label="expandCollapse"
                      onClick={handleExpandEvents}
                      size="small"
                      style={{ marginRight: theme.spacing() }}
                    >
                      {expandCandidate ? (
                        <KeyboardArrowUpRoundedIcon />
                      ) : (
                        <KeyboardArrowDownRoundedIcon />
                      )}
                    </IconButton>
                  }
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    variant="smallText"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: theme.palette.grey["A200"],
                    }}
                  >
                    ציר זמן מלא
                  </Typography>
                </div>
              </div>
              {/* <Button color="primary" onClick={(e) => handleGetEvents()}>
                הצג אירועים
              </Button> */}
            </div>

            <div className={classes.minHeight}>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {<PhoneIcon style={{ marginLeft: theme.spacing(2) }} />}
                {props.candidateData.acf.mobile}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  variant="smallText"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.grey["A200"],
                  }}
                >
                  חיוג מהיר
                </Typography>
              </div>
            </div>

            <div className={classes.minHeight}>
              <StyledRating
                name="candidateRating"
                value={
                  props.candidateData.acf.rating
                    ? parseFloat(props.candidateData.acf.rating)
                    : -1
                }
                precision={props.ratingPrecisionHalf ? 0.5 : 1}
                icon={<StarIcon strokeColor={"#8593FF"} />}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  variant="smallText"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.grey["A200"],
                  }}
                >
                  דירוג מועמד
                </Typography>
              </div>
            </div>
            {/* <Button onClick={handleCVReferals} color="primary">
                הפניית קורות חיים
              </Button> */}

            <div style={{ cursor: "pointer" }} onClick={handleGetCV}>
              <div className={classes.minHeight}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {<ReadyIcon style={{ marginLeft: theme.spacing(2) }} />}
                  הצג קורות חיים
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    variant="smallText"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: theme.palette.grey["A200"],
                    }}
                  >
                    קורות חיים
                  </Typography>
                </div>
              </div>
            </div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: 200 }}
            >
              <Tooltip title="העבר קורות חיים ללקוח">
                <div>
                  <IconButton
                    className={
                      props.candidateData.read_reject &&
                      props.candidateData.read_reject.rejected
                        ? classes.buttonCircledDisabled
                        : classes.buttonCircled
                    }
                    onClick={() => props.onCVReferrals(props.candidateData.id)}
                    disabled={props.candidateData.cv_referral}
                  >
                    {!props.candidateData.cv_referral ? (
                      <SendFill />
                    ) : (
                      <CheckRoundedIcon />
                    )}
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip
                title={
                  props.candidateData.read_reject &&
                  props.candidateData.read_reject.rejected
                    ? "החזר למתאימים"
                    : "העבר למתאימים שנידחו"
                }
              >
                <IconButton
                  onClick={() =>
                    props.candidateData.read_reject &&
                    props.candidateData.read_reject.rejected
                      ? props.rejectCandidateClick(
                          props.candidateData.id,
                          false
                        )
                      : props.rejectCandidateClick(props.candidateData.id, true)
                  }
                >
                  {props.candidateData.read_reject &&
                  props.candidateData.read_reject.rejected ? (
                    <SettingsBackupRestoreIcon
                      style={{ color: theme.palette.success.main }}
                    />
                  ) : (
                    <CloseRoundedIcon style={{ color: red[500] }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="צפיה מהירה בפרופיל מועמד">
                <IconButton onClick={() => setShowQuickView(true)}>
                  <VisibilityRoundedIcon />
                </IconButton>
              </Tooltip>
              <EntityMoreActions
                style={{ marginLeft: 0 }}
                options={MenuMoreOptions}
                menuItemClick={handleMoreActionsMenuClick}
                disabled={!props.candidateData}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              style={{ width: 125 }}
            >
              <Typography
                variant="smallText"
                align="right"
                style={{
                  marginRight: theme.spacing(4),
                  color: theme.palette.grey["A200"],
                }}
              >
                ID: {props.candidateData.id}
                <br />
                Ext Id:{" "}
                {props.candidateData.acf.externalNumber
                  ? props.candidateData.acf.externalNumber
                  : ""}
              </Typography>
            </Box>
          </Box>
          {isLoading && (
            <Box
              position="absolute"
              left="calc(50% - 20px)"
              top="calc(50% - 20px)"
            >
              <CircularProgress color="primary" />
            </Box>
          )}
          {showQuestionier && (
            <JobAcceptedQuestionierSummary
              candidateId={props.candidateData.id}
              jobId={props.jobId}
              initClosed={false}
              onClose={() => setShowQuestionier(false)}
            />
          )}
          {activeMenuItem && (
            <Collapse in={true}>
              <Box p={theme.spacing(2)}>
                <EventRow
                  eventType={activeMenuItem}
                  draftMode
                  authorImage={localStorage.getItem("userImage")}
                  disableLastModified
                  onAddEvent={handleAddEvent}
                  cancelEvent={() => setActiveMenuItem("")}
                />
              </Box>
            </Collapse>
          )}
          {expandCandidate && events.length > 0 ? (
            <Collapse in={true}>
              <Box mt={theme.spacing(1)}>
                <Divider />
              </Box>
              <CandidateEvents
                candidateData={events}
                showCurrentJobOnly={false}
                eventsOffset={(e) => handleGetEvents(true)}
                totalEvents={totalEvents}
              />
            </Collapse>
          ) : null}
        </Paper>
      </Grid>
      {showQuickView && (
        <CandidateQuickView
          onClose={() => setShowQuickView(false)}
          open={showQuickView}
          data={props.candidateData}
        />
      )}
      {error && <AxiosErrorHandling error={error} />}
      {
        <MyAlert
          showAlert={jobAutoClosed}
          title="סגירת משרה"
          message={`עם סימון גיום מוצלח למועמד מספר ${props.candidateData.id}, משרה מספר ${props.jobId} השלימה את יעד הגיוס ונסגרה`}
          severity="success"
          closeAlert={() => setJobAutoClosed(false)}
        />
      }
    </React.Fragment>
  );
};

export default SuitbaleCandidateItem;
