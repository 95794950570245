import React from "react";
import pure from "recompose/pure";
import { SvgIcon } from "@material-ui/core";

let SendFill = (props) => (
  <SvgIcon {...props}>
    <path d="M21 10.9999H15L15 12.9999H21V22.1539C21 22.2408 20.9773 22.3262 20.9343 22.4016C20.8912 22.4771 20.8292 22.54 20.7544 22.5842C20.6796 22.6284 20.5946 22.6524 20.5077 22.6537C20.4209 22.6551 20.3351 22.6338 20.259 22.5919L1.797 12.4379C1.71861 12.3947 1.65323 12.3313 1.6077 12.2543C1.56217 12.1772 1.53815 12.0894 1.53815 11.9999C1.53815 11.9104 1.56217 11.8226 1.6077 11.7455C1.65323 11.6685 1.71861 11.6051 1.797 11.5619L20.259 1.4079C20.3351 1.36602 20.4209 1.3447 20.5077 1.34604C20.5946 1.34738 20.6796 1.37134 20.7544 1.41555C20.8292 1.45976 20.8912 1.5227 20.9343 1.59817C20.9773 1.67363 21 1.75901 21 1.8459L21 10.9999Z" />
  </SvgIcon>
);
SendFill = pure(SendFill);
SendFill.displayName = "SendFill";
SendFill.muiName = "SvgIcon";

export default SendFill;
