import React, { useEffect, useState } from "react";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import {
  Box,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  FormControl,
  Radio,
} from "@material-ui/core";
import FilterQuestionRelationsTag from "../FilterQuestionRelationsTag";
import { AI_DYNAMICS } from "../../../../../config/constants";

const FQDynamic = ({
  onCancel,
  onCompletion,
  fqEdit = null,
  isActive,
  isActiveReadOnly,
}) => {
  const [qType, setQType] = useState("YesNo");
  const [displayQuestion, setDisplayQuestion] = useState("");
  const [charsLeft, setCharsLeft] = useState(255);
  const [passAnswer, setPassAnswer] = useState("yes");
  const [isAMust, setIsAMust] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (fqEdit !== null) {
      setQType(fqEdit.qType);
      setDisplayQuestion(fqEdit.displayQuestion);
      setPassAnswer(fqEdit.passAnswer);
      setIsAMust(fqEdit.isAMust);
      setTags(fqEdit.tags);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  /*
  Single Dynamic Object: 
  {
      dynamicId: AutoID
      qType: string: Question Type, default: YesNo
      displayQuestion: string, the full question to display
      passAnswer: string, the correct answer to pass 
      chars Left: num, number of characters left up to 255 of display question characters      
      isAMust: if true, answer must pass
  }
  */

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["displayQuestion"] = displayQuestion;
      existingObject["passAnswer"] = passAnswer;
      existingObject["isAMust"] = isAMust;
      existingObject["tags"] = tags;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      qType: "YesNo",
      displayQuestion,
      passAnswer,
      isAMust,
      tags,
    });
  };

  const clearForm = () => {
    //clean the form values:
    setDisplayQuestion("");
    setQType("YesNo");
    setPassAnswer("yes");
    setIsAMust(false);
    setCharsLeft(255);
    setTags([]);
  };

  const handleIsDynamicIsMust = (event) => setIsAMust(event.target.checked);
  const handleDisplayQuestionChange = (event) => {
    setDisplayQuestion(event.target.value);
    setCharsLeft(255 - event.target.value.length);
  };
  const handlePassAnswerChange = (event) => {
    setPassAnswer(event.target.value);
  };

  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          שאלה דינאמית
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset" disabled={isActiveReadOnly}>
            <FormLabel component="legend">סוג השאלה</FormLabel>
            <RadioGroup aria-label="qType" name="qType" value={qType}>
              <FormControlLabel
                value="YesNo"
                control={<Radio color="primary" />}
                label="שאלת כן/לא"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ fontWeight: 700, marginBottom: 15 }}
            color="textPrimary"
            variant="subtitle1"
          >
            הזן את השאלה שתוצג למועמד
          </Typography>
          <Typography color="error">
            *השימוש בשאלה דינמית הוא למטרת שאלות בנושאי זמינות בלבד
          </Typography>
          <TextField
            id="openQuestion"
            label="הזן את השאלה שתוצג למועמד"
            variant="filled"
            value={displayQuestion}
            onChange={handleDisplayQuestionChange}
            helperText={`מספר תווים שנותרו: ${charsLeft}`}
            error={charsLeft <= 0}
            fullWidth
            disabled={isActiveReadOnly}
          />
        </Grid>
        <Grid item xs={6}>
          {isActive && (
            <FilterQuestionRelationsTag
              category={AI_DYNAMICS}
              value={tags}
              onSelect={(value) => setTags(value)}
              readOnly={isActiveReadOnly}
            />
          )}
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <FormControl
            component="fieldset"
            onChange={handlePassAnswerChange}
            disabled={isActiveReadOnly}
          >
            <FormLabel component="legend">התשובה הנכונה לשאלה זו:</FormLabel>
            <RadioGroup
              aria-label="passAnswer"
              name="passAnswer"
              value={passAnswer}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="כן"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="לא"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAMust}
                onClick={handleIsDynamicIsMust}
                name="requiredFQ"
                color="primary"
                disabled={isActiveReadOnly}
              />
            }
            label="תנאי זה הוא חובה"
          />
        </Grid>
        <Grid xs={12} item>
          <CancelApproveButtons
            positiveCaption="סיימתי"
            negativeCaption="ביטול"
            positiveDisabled={
              displayQuestion === "" ||
              charsLeft <= 0 ||
              !tags ||
              tags.length === 0
            }
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default FQDynamic;
