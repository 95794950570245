import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
//import ProfessionsListAsync from "../../../../../components/ProfessionsListAsync/ProfessionsListAsync";
import rtl from "jss-rtl";
import { Icon, Button, Box } from "@material-ui/core";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import MyTooltip from "../../../../../UI/Tooltip/Tooltip";
import FilterQuestionRelationsTag from "../../../../Jobs/AddJob/FilterQuestions/FilterQuestionRelationsTag";
import { AI_LICENSES } from "../../../../../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const AILicenses = (props) => {
  const classes = useStyles();
  const [selectedLicense, setSelectedLicense] = useState("");
  const [licenseYears, setLicenseYears] = useState(0);
  const [updatedChip, setUpdatedChip] = useState(-1);
  const [tags, setTags] = useState([]);
  const [licensesAIObject, setLicensesAIObject] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    if (props.aIValue.data && Object.keys(props.aIValue.data).length) {
      const aiTempObject = props.aIValue.data.map((aiItem) => {
        return (
          [...licensesAIObject],
          {
            key: aiItem.licenseId,
            licenseId: aiItem.licenseId,
            licenseName: aiItem.licenseName,
            licenseYears: parseInt(aiItem.licenseYears),
          }
        );
      });
      props.aIValue.tags && setTags(props.aIValue.tags);
      setLicensesAIObject(aiTempObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [props.aIValue]);

  const handleSelectedLicense = (event) => {
    setSelectedLicense(event.target.value);
  };

  const buildAILicensesObject = (event) => {
    props.addAI({ data: licensesAIObject, tags });
  };

  const handleLicenseYears = (event) => setLicenseYears(event.target.value);

  const handleCheckEnableQ = (value) => {
    if (!value) setLicenseYears(0);
  };

  /*
  Single License Object: 
  {
      licenseId: id,
      licenseName: string,
      licenseYears: num,      
  }
  */
  const handleAddSingleLicenseAI = () => {
    licensesAIObject === null
      ? setLicensesAIObject([
          {
            licenseId:
              licensesAIObject === null ? 1 : licensesAIObject.length + 1,
            licenseName: selectedLicense,
            licenseYears,
          },
        ])
      : setLicensesAIObject([
          ...licensesAIObject,
          {
            licenseId: licensesAIObject.length + 1,
            licenseName: selectedLicense,
            licenseYears,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = licensesAIObject.find(
      (chip) => chip.licenseId === updatedChip
    );

    //remove the selected chip from the array:
    const tempLicenseAIObject = licensesAIObject.filter(
      (ai) => ai.licenseId !== tempUpdatedChip.licenseId
    );

    //update the form:
    setLicensesAIObject([
      ...tempLicenseAIObject,
      {
        licenseId: tempUpdatedChip.licenseId,
        licenseName: selectedLicense,
        licenseYears,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setLicenseYears(0);
    setUpdatedChip(-1);
    setSelectedLicense("");
  };

  const handleRemoveLicense = (licenseId) => {
    //locate index id
    const newLicensesFQObject = licensesAIObject.filter(
      (license) => license.licenseId !== licenseId
    );
    setLicensesAIObject(newLicensesFQObject);
    clearForm();
  };

  const handleReadChip = (id) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleLicenseObj = licensesAIObject.find(
        (lc) => lc.licenseId === id
      );
      setLicenseYears(singleLicenseObj.licenseYears);
      setSelectedLicense(singleLicenseObj.licenseName);
      setUpdatedChip(id);
    }
  };

  const displayChips =
    licensesAIObject !== null
      ? licensesAIObject.map((lcn) => {
          return (
            <Chip
              key={lcn.licenseId}
              icon={<FaceIcon />}
              label={lcn.licenseName}
              clickable
              color="primary"
              onClick={() => handleReadChip(lcn.licenseId)}
              onDelete={() => handleRemoveLicense(lcn.licenseId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== lcn.licenseId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <StylesProvider jss={jss}>
      <Container maxWidth="lg" className={classes.root}>
        <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
        <Grid container>
          <Grid item style={{ width: "100%" }}>
            <Typography
              align="center"
              color="primary"
              variant="h5"
              className={classes.modalTitle}
            >
              {props.candidateFullName !== "undefined" &&
              props.candidateFullName.length > 1
                ? `הרישיונות של  ${props.candidateFullName}`
                : "רישיונות"}
            </Typography>
            <Divider />
            <div>
              <Collapse in={licensesAIObject !== null}>
                <Typography variant="h5" className={classes.modalTitle}>
                  רישיונות המועמד
                </Typography>
                {displayChips}
                <Divider />
              </Collapse>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: theme.spacing(1),
              }}
            >
              <Typography
                style={{ fontWeight: 700 }}
                color="textPrimary"
                variant="subtitle1"
              >
                הזן את הרשיון
              </Typography>
              <MyTooltip marginRight arrow caption="הזן את רשיון המועמד" />
            </div>

            <TextField
              id="lincense"
              label="רישיון"
              variant="outlined"
              value={selectedLicense}
              onChange={handleSelectedLicense}
              style={{ width: "100%" }}
              helperText='הזן "רכב" להגדרות רישיון רכב'
            />
            <QuestionValueInput
              disabled={selectedLicense === ""}
              question="שנות וותק עבור רשיון זה:"
              minYearsValue={handleLicenseYears}
              disableVerificationQuestion
              value={licenseYears}
              checkEnalbeQ={handleCheckEnableQ}
            />
            <Box my={2}>
              <FilterQuestionRelationsTag
                category={AI_LICENSES}
                value={tags}
                onSelect={(value) => setTags(value)}
                readOnly
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                disabled={selectedLicense === ""}
                className={classes.button}
                endIcon={<Icon>add</Icon>}
                onClick={
                  updatedChip <= 0
                    ? handleAddSingleLicenseAI
                    : updateSelectedChip
                } //add new Object with all values of this Component
              >
                {updatedChip > 0 ? "עדכן מידע" : "הוסף מידע"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  licensesAIObject === null ||
                  licensesAIObject === "undefined" ||
                  Object.keys(licensesAIObject).length <= 0 ||
                  licensesAIObject.length <= 0
                }
                className={classes.button}
                endIcon={<Icon>done</Icon>}
                onClick={buildAILicensesObject}
              >
                סיימתי
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StylesProvider>
  );
};
export default React.memo(AILicenses);
