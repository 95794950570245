import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import ArrowUpDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
}));

const generateAgeList = (from, to) => {
  const ageList = [];
  for (let i = from; i <= to; i++) {
    ageList.push(
      <MenuItem value={i} key={i.toString()}>
        {i.toString()}
      </MenuItem>
    );
  }
  return ageList;
};

const AgeFromToSelection = ({ onSelect }) => {
  const [ageFrom, setAgeFrom] = useState(16);
  const [ageTo, setAgeTo] = useState(67);
  const classes = useStyles();

  useEffect(() => {
    onSelect({
      ageFrom,
      ageTo,
    });
  }, [ageFrom, ageTo]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
      width={"100%"}
    >
      <FormControl variant="filled" fullWidth margin="normal">
        <InputLabel htmlFor="ageFrom">מגיל</InputLabel>
        <Select
          value={ageFrom}
          onChange={(event) => setAgeFrom(event.target.value)}
          inputProps={{
            name: "age",
            id: "filled-age",
          }}
          IconComponent={(_props) => (
            <div
              className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled"
              style={{ top: 24 }}
            >
              <ArrowUpDownIcon />
            </div>
          )}
        >
          {generateAgeList(16, ageTo)}
        </Select>
      </FormControl>
      <FormControl variant="filled" fullWidth margin="normal">
        <InputLabel htmlFor="ageFrom">עד גיל</InputLabel>
        <Select
          value={ageTo}
          onChange={(event) => setAgeTo(event.target.value)}
          inputProps={{
            name: "age",
            id: "filled-age-to",
          }}
          IconComponent={(_props) => (
            <div
              className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled"
              style={{ top: 24 }}
            >
              <ArrowUpDownIcon />
            </div>
          )}
        >
          {generateAgeList(ageFrom, 67)}
        </Select>
      </FormControl>
    </Box>
  );
};
export default AgeFromToSelection;
