import React from "react";

import {
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Search from "@material-ui/icons/Search";

const ENTER_KEY = 13;

export default function InputWithIcon(props) {
  //const [tempSearch, setTempSearch] = React.useState("");

  const handleDelayChange = (event) => {
    //setTempSearch(event.target.value);
    props.changeSearchTerm(event.target.value);
  };

  const handleReturnOnEnter = (e) => {
    if (e.keyCode === ENTER_KEY) {
      props.changeSearchTerm(e.target.value);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <Box>
        <FormControl variant="filled" disabled={props.disabled} fullWidth>
          <InputLabel htmlFor="filled-adornment-search">
            {props.helper}
          </InputLabel>
          <FilledInput
            id="filled-adornment-serch"
            //value={tempSearch}
            disableUnderline
            margin="dense"
            onChange={handleDelayChange}
            onKeyDown={handleReturnOnEnter}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleReturnOnEnter}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </div>
  );
}
