import { Typography } from "@material-ui/core";
import React from "react";
import packageJSON from "../../../package.json";

const Version = () => {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      align="center"
      display="block"
    >
      Version: {packageJSON.version}
    </Typography>
  );
};

export default Version;
