import React from "react";
import { Typography, Box, useTheme } from "@material-ui/core";

const FilterQuestionDisplayPrevew = ({ previewQuestion }) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Typography variant="body1" style={{ fontWeight: 700 }}>
        תצוגה מקדימה:
      </Typography>

      <Box
        minHeight={94}
        padding={theme.spacing(2)}
        style={{
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.spacing(1),
        }}
      >
        <Typography variant="body1">
          <span
            dangerouslySetInnerHTML={{
              __html: previewQuestion,
            }}
          ></span>
        </Typography>
      </Box>
    </React.Fragment>
  );
};
export default FilterQuestionDisplayPrevew;
