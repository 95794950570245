import React from "react";
import { MenuMoreOptions } from "../../config/candidatesConfigData";
import {
  Box,
  Fade,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import CustomDialog from "../../UI/CustomDialog/CustomDialog";
import EventRow from "../../Screens/Candidates/SingleCandidateFull/CandidateEvents/EventRow";
import { RawlIcon } from "../../Assets/CustomIcons/CustomIconsList";

const EventsList = ({ onAddEvent, close, open }) => {
  const [selectedEvent, setSelectedEvent] = React.useState("");

  const handleAddEvent = (type, value, additionalInfo = "") => {
    setSelectedEvent("");
    onAddEvent(type, value, additionalInfo);
  };
  const detectEventTitle = () => {
    const menuSelectedIndex = MenuMoreOptions.findIndex(
      (menuItem) => menuItem.id === selectedEvent
    );
    if (menuSelectedIndex > -1) {
      return MenuMoreOptions[menuSelectedIndex].label;
    }
  };
  return (
    <CustomDialog
      fullWidth
      maxWidth={selectedEvent === "" ? "md" : "sm"}
      open={open}
      close={close}
      title={selectedEvent === "" ? "הוספת אירוע למועמד" : detectEventTitle()}
    >
      <Box maxWidth={750} margin="auto">
        {selectedEvent === "" && (
          <Grid container spacing={4}>
            {MenuMoreOptions.filter(
              (menuForView) => menuForView.viewable === true
            ).map((menuItem) => {
              return (
                <Fade in={true} key={menuItem.id}>
                  <Grid item xs={4}>
                    <EventItem
                      eventLabel={menuItem.label}
                      eventId={menuItem.id}
                      onClick={() => setSelectedEvent(menuItem.id)}
                      disabled={menuItem.disabled}
                      eventIcon={
                        <RawlIcon color="primary" viewBox={menuItem.viewBox}>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d={menuItem.icon}
                          />
                        </RawlIcon>
                      }
                    />
                  </Grid>
                </Fade>
              );
            })}
          </Grid>
        )}
        {selectedEvent !== "" && (
          <Fade in={true}>
            <Grid item xs={12}>
              <EventRow
                eventType={selectedEvent}
                draftMode
                authorImage={localStorage.getItem("userImage")}
                disableLastModified
                onAddEvent={handleAddEvent}
                cancelEvent={() => setSelectedEvent("")}
                columnView
              />
            </Grid>
          </Fade>
        )}
      </Box>
    </CustomDialog>
  );
};
export default EventsList;

const useEventItemStyle = makeStyles((theme) => ({
  root: {
    maxWidth: 240,
    height: 180,
    borderRadius: 8,
    boxShadow: " 0px 0px 8px rgba(99, 101, 117, 0.5)",
    "& .MuiSvgIcon-root": {
      marginBottom: theme.spacing(2),
    },
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
  },
}));

const EventItem = ({ eventLabel, eventIcon, onClick, disabled }) => {
  const theme = useTheme();
  const classes = useEventItemStyle();
  return (
    <Box
      className={classes.root}
      onClick={disabled ? null : onClick}
      style={{
        backgroundColor: disabled
          ? theme.palette.grey[300]
          : theme.palette.background.paper,
        cursor: disabled ? "default" : "pointer",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        p={theme.spacing(2)}
      >
        {eventIcon}
        <Typography className={classes.title} variant="body1" align="center">
          {eventLabel}
        </Typography>
      </Box>
    </Box>
  );
};
