import {withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core'
import {pink} from '@material-ui/core/colors'

const CandidatesButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[500],
      '&:hover': {
        backgroundColor: pink[700],
      }
      
    },
  }))(Button);

export default CandidatesButton;

