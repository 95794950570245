import {
  Avatar,
  Badge,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import {
  TranformAdditionalInformationData,
  getInitials,
} from "../../config/candidatesConfigData";
import { GeneralDetailsKeyValue } from "../../Screens/Candidates/SingleCandidateFull/SingleCandidateFullDetails";
import AxiosErrorHandling from "../AxiosErrorHandling/AxiosErrorHandling";
import {
  AI_EXPERIENCES,
  AI_SKILLS,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LICENSES,
  AI_LANGUAGES,
  AI_SALARY_EXPECTATIONS,
} from "../../config/constants";
import {
  translateDegreeAndType,
  translateLanguageLevel,
  translateSkillLevel,
} from "../../config/jobsConfigData";

const CandidateQuickView = ({ open, onClose, data }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    axios
      .get("/wp-json/api/v2/candidateAI/" + data.id)
      .then((res) => {
        setIsLoading(false);
        let candidatesAdditionalInformationUpdatedData = null;
        candidatesAdditionalInformationUpdatedData =
          TranformAdditionalInformationData(res.data).map((aiType) => {
            return candidatesAdditionalInformationUpdatedData === null
              ? aiType
              : { ...candidatesAdditionalInformationUpdatedData, aiType };
          });
        let localProfileData = [];

        candidatesAdditionalInformationUpdatedData.forEach((aiData) => {
          const keys = Object.keys(aiData)[0];
          const values = Object.values(aiData)[0];
          switch (keys) {
            case AI_EXPERIENCES: {
              let localAIObject = Object.values(values.typeBySubType).map(
                (exp) => {
                  //build Experince Object:
                  let sId = 0;
                  let sName = "";
                  let years = 0;
                  let isManagementRole = false;
                  let tags = [];
                  exp.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "experienceName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "yearsExperience":
                        years = parseInt(singleExp.ai_value);
                        break;
                      case "isManagementRole":
                        isManagementRole =
                          parseInt(singleExp.ai_value) === 1 ? true : false;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    experienceId: sId,
                    experienceName: sName,
                    yearsExperience: years,
                    isManagementRole: isManagementRole,
                  };
                }
              );

              localProfileData = [
                ...localProfileData,
                {
                  AI_EXPERIENCES: localAIObject.filter(
                    (aiItem) => aiItem.experienceId !== "-1"
                  ),
                },
              ];
              break;
            }
            case AI_SKILLS: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let years = 0;
                  let level = false;
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "skillName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "yearsSkill":
                        years = parseInt(singleExp.ai_value);
                        break;
                      case "knowledgeLevel":
                        level = singleExp.ai_value;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    skillId: sId,
                    skillName: sName,
                    yearsSkill: years,
                    knowledgeLevel: level,
                    tags,
                  };
                }
              );
              localProfileData = [
                ...localProfileData,
                {
                  AI_SKILLS: localAIObject.filter(
                    (aiItem) => aiItem.skillId !== "-1"
                  ),
                },
              ];

              break;
            }
            case AI_ACADEMIC_DGREE: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let type = "";
                  let dOn = "";
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "degreeName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "degreeType":
                        type = singleExp.ai_value;
                        break;
                      case "degreeOn":
                        dOn = singleExp.ai_value;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    degreeId: sId,
                    degreeName: sName,
                    degreeType: type,
                    degreeOn: dOn,
                    tags,
                  };
                }
              );
              localProfileData = [
                ...localProfileData,
                {
                  AI_ACADEMIC_DGREE: localAIObject.filter(
                    (aiItem) => aiItem.degreeId !== "-1"
                  ),
                },
              ];
              break;
            }
            case AI_CERTIFICATES: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "certificateName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    certificateId: sId,
                    certificateName: sName,
                    tags,
                  };
                }
              );

              localProfileData = [
                ...localProfileData,
                {
                  AI_CERTIFICATES: localAIObject.filter(
                    (aiItem) => aiItem.certificateId !== "-1"
                  ),
                },
              ];
              break;
            }

            case AI_LICENSES: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let years = 0;
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "licenseName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "licenseYears":
                        years = parseInt(singleExp.ai_value);
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    licenseId: sId,
                    licenseName: sName,
                    licenseYears: years,
                    tags,
                  };
                }
              );

              localProfileData = [
                ...localProfileData,
                {
                  AI_LICENSES: localAIObject.filter(
                    (aiItem) => aiItem.licenseId !== "-1"
                  ),
                },
              ];
              break;
            }
            case AI_LANGUAGES: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let level = false;
                  let tags = [];

                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "languageName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "languageLevel":
                        level = singleExp.ai_value;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    languageId: sId,
                    languageName: sName,
                    languageLevel: level,
                    tags,
                  };
                }
              );
              localProfileData = [
                ...localProfileData,
                {
                  AI_LANGUAGES: localAIObject.filter(
                    (aiItem) => aiItem.languageId !== "-1"
                  ),
                },
              ];

              break;
            }
            case AI_SALARY_EXPECTATIONS: {
              const localAIObject = Object.values(values.typeBySubType).map(
                (aiItem) => {
                  let sId = 0;
                  let sRole = "";
                  let sMeasure = "";
                  let min = 0;
                  let max = 0;
                  let tags = [];
                  aiItem.forEach((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "seForRole":
                        sRole = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "seSalaryMinimum":
                        min = parseFloat(singleExp.ai_value);
                        break;
                      case "seSalaryMaximum":
                        max = parseFloat(singleExp.ai_value);
                        break;
                      case "seMeasure":
                        sMeasure = singleExp.ai_value;
                        break;
                      case "relatedFQTag":
                        sId = singleExp.ai_sub_type_id;
                        tags = [...tags, parseInt(singleExp.ai_value)];
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    seId: sId,
                    seForRole: sRole,
                    seIsRange: min !== max,
                    seRange: min === max ? min : [min, max],
                    seMeasure: sMeasure,
                    tags,
                  };
                }
              );

              localProfileData = [
                ...localProfileData,
                {
                  AI_SALARY_EXPECTATIONS: localAIObject.filter(
                    (aiItem) => aiItem.seId !== "-1"
                  ),
                },
              ];
              break;
            }
            default:
              break;
          }
        });
        setProfileData(localProfileData);
      })
      .catch((err) => {
        setError(err.response);
        setIsLoading(false);
        setProfileData(null);
      });
  }, [data.id]);

  const renderFilterQuestions = () => {
    if (!profileData) return;
    let renderedFQ = null;
    renderedFQ = profileData.map((aiItem) => {
      console.log("aiitem", aiItem);
      const key = Object.keys(aiItem)[0];
      const values = Object.values(aiItem)[0];
      switch (key) {
        case "AI_EXPERIENCES":
          return (
            <Box my={2}>
              <Typography
                style={{ marginBottom: theme.spacing(1) }}
                variant="subtitle1"
              >
                ניסיון תעסוקתי
              </Typography>
              <Box
                my={2}
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                {values.map((exp) => {
                  return (
                    <Badge
                      key={exp.experienceName}
                      badgeContent={exp.yearsExperience}
                      showZero
                      color="primary"
                      style={{
                        marginLeft: theme.spacing(2),
                        marginBottom: theme.spacing(1),
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Chip label={exp.experienceName} />
                    </Badge>
                  );
                })}
              </Box>
            </Box>
          );

        case "AI_SKILLS":
          return (
            <Box my={2}>
              <Typography
                style={{ marginBottom: theme.spacing(1) }}
                variant="subtitle1"
              >
                כישורים
              </Typography>
              <Box my={1}>
                {values.map((skl) => {
                  return (
                    <Box display={"flex"} alignItems={"center"} mb={1}>
                      <Typography variant="body1">{skl.skillName}</Typography>
                      <Chip
                        label={translateSkillLevel(skl.knowledgeLevel)}
                        style={{ marginRight: 1 }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        case "AI_ACADEMIC_DGREE":
          return (
            <Box my={2}>
              <Typography
                style={{ marginBottom: theme.spacing(1) }}
                variant="subtitle1"
              >
                תואר אקדמי
              </Typography>
              <Box my={1}>
                {values.map((degree) => {
                  return (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      mb={1}
                      key={degree.degreeId}
                    >
                      <Typography variant="body1">
                        {`${translateDegreeAndType(degree.degreeName)}  ${
                          degree.degreeOn
                        }`}{" "}
                      </Typography>
                      {degree.degreeType && (
                        <Chip
                          label={degree.degreeType}
                          style={{ marginRight: 1 }}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        case "AI_CERTIFICATES":
          return (
            <Box my={2}>
              <Typography
                style={{ marginBottom: theme.spacing(1) }}
                variant="subtitle1"
              >
                תעודות
              </Typography>
              <Box my={1}>
                {values.map((cert) => {
                  return (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      mb={1}
                      flexWrap={"wrap"}
                      key={cert.certificateId}
                    >
                      <Chip label={cert.certificateName} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        case "AI_LICENSES":
          return (
            <Box my={2}>
              <Typography
                style={{ marginBottom: theme.spacing(1) }}
                variant="subtitle1"
              >
                רישיונות
              </Typography>
              <Box my={1}>
                {values.map((license) => {
                  return (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      mb={1}
                      flexWrap={"wrap"}
                      key={license.licenseId}
                    >
                      <Badge
                        key={license.licenseId}
                        badgeContent={license.licenseYears}
                        showZero
                        color="primary"
                        style={{
                          marginLeft: theme.spacing(2),
                          marginBottom: theme.spacing(1),
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Chip label={license.licenseName} />
                      </Badge>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        case "AI_LANGUAGES":
          return (
            <Box my={2}>
              <Typography
                style={{ marginBottom: theme.spacing(1) }}
                variant="subtitle1"
              >
                שפות
              </Typography>
              <Box my={1}>
                {values.map((lang) => {
                  return (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      mb={1}
                      key={lang.languageId}
                    >
                      <Typography variant="body1">
                        {lang.languageName}
                      </Typography>

                      <Chip
                        label={translateLanguageLevel(lang.languageLevel)}
                        style={{ marginRight: 1 }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        case "AI_SALARY_EXPECTATIONS":
          return (
            <Box my={2}>
              <Typography
                style={{ marginBottom: theme.spacing(1) }}
                variant="subtitle1"
              >
                ציפיות שכר
              </Typography>
              <Box my={1}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  mb={1}
                  key={values[0].seId}
                >
                  <Typography variant="body1">{`בין ₪${values[0].seRange[0]} ל ₪${values[0].seRange[1]} `}</Typography>
                </Box>
              </Box>
            </Box>
          );
        default:
          return null;
      }
    });
    return renderedFQ;
  };

  return (
    <Fragment>
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={500} p={2}>
        <div>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        </div>
        <Box display={"flex"} alignItems={"center"}>
          <Avatar
            style={{
              marginLeft: theme.spacing(1),
              width: theme.spacing(4),
              height: theme.spacing(4),
            }}
            alt={data.name}
            src={data && data.profile_image ? data.profile_image : ""}
          >
            {getInitials(data.name)}
          </Avatar>
          <Typography variant="subtitle1">{data.name}</Typography>
        </Box>
        <Box mt={theme.spacing(2)}>
          <GeneralDetailsKeyValue label="מספר מועמד" value={data.id} />
          <GeneralDetailsKeyValue
            label="מזהה חיצוני"
            value={data.acf.externalNumber}
          />
          <GeneralDetailsKeyValue
            label="שנת לידה"
            value={data.acf.yearOfBirth}
          />
        </Box>
        <Divider />

        {data.professions && (
          <Box my={2}>
            <Typography variant="subtitle1">מקצועות</Typography>
            {data.professions.map((p) => (
              <Chip
                style={{
                  marginLeft: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                }}
                label={p.name}
                key={p.id}
              />
            ))}
          </Box>
        )}
        {data.professions && <Divider />}
        {isLoading ? (
          <Box textAlign={"center"} my={5}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Fragment>{renderFilterQuestions()}</Fragment>
        )}
        {error && (
          <AxiosErrorHandling
            title="אירעה שגיאה בעת ניסיון קריאת מידע של מועמד"
            error={error}
          />
        )}
      </Box>
    </Drawer>
    </Fragment>
  );
};
export default CandidateQuickView;
