import React from "react";
import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  Select,
  Checkbox,
  Collapse,
  TextField,
} from "@material-ui/core";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import {
  AI_ACADEMIC_DGREE,
  DEGREE_TYPES,
} from "../../../../../config/constants";
import { academicDegreePreviewQuestion } from "../../../../../config/jobsConfigData";
import FilterQuestionDisplayPrevew from "../FilterQuestionDisplayPreview";
import { useState } from "react";
import FilterQuestionRelationsTag from "../FilterQuestionRelationsTag";

const AcademicDegree = ({
  onCancel,
  onCompletion,
  fqEdit = null,
  isActive,
  isActiveReadOnly,
}) => {
  const [selectedDegree, setSelectedDegree] = React.useState("");
  const [degreeType, setDegreeType] = React.useState("");
  const [isDegreeIsMust, setIsDegreeIsMust] = React.useState(false);
  const [generalDegree, setGeneralDegree] = React.useState(false);
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [tags, setTags] = useState([]);

  React.useEffect(() => {
    if (fqEdit !== null) {
      setSelectedDegree(fqEdit.selectedDegree);
      if (fqEdit.degreeType === "general") {
        setGeneralDegree(true);
        setDegreeType("general");
      } else {
        setDegreeType(fqEdit.degreeType);
      }
      setIsDegreeIsMust(fqEdit.isDegreeAMust);
      setAdditionalInfo(fqEdit.addInfo);
      setTags(fqEdit.tags);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  /*Single Degree Object
  {
        selectedDegree: selectedDegree,
        degreeType: degreeType,
        isDegreeAMust: isDegreeIsMust,
      }
  */

  const clearForm = () => {
    //Clear the form:
    setSelectedDegree("");
    setDegreeType("");
    setIsDegreeIsMust(false);
    setAdditionalInfo("");
    setTags([]);
  };

  const handleIsDegreeIsMust = (event) => {
    setIsDegreeIsMust(event.target.checked);
  };
  const handleDegreeTypeChange = (event) => {
    setDegreeType(event.target.value);
  };
  const handleChangeDegree = (event) => {
    setSelectedDegree(event.target.value);
    switch (event.target.value) {
      case "firstDegree":
        setDegreeType(DEGREE_TYPES.first[0]);
        break;
      case "secondDegree":
        setDegreeType(DEGREE_TYPES.first[1]);
        break;
      case "doctor":
        setDegreeType(DEGREE_TYPES.first[2]);
        setIsDegreeIsMust(false);
        break;
      default:
        break;
    }
  };

  const handleGeneralDegree = () => {
    setGeneralDegree((prev) => !prev);
    setDegreeType("general");
  };

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["selectedDegree"] = selectedDegree;
      existingObject["degreeType"] = degreeType;
      existingObject["isDegreeAMust"] = isDegreeIsMust;
      existingObject["addInfo"] = additionalInfo;
      existingObject["tags"] = tags;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      selectedDegree,
      degreeType: degreeType,
      isDegreeAMust: isDegreeIsMust,
      addInfo: additionalInfo,
      tags,
    });
  };

  function createDegreeTypeRadios() {
    switch (selectedDegree) {
      case "firstDegree":
        return DEGREE_TYPES.first.map((dt) => (
          <FormControlLabel
            disabled={isActiveReadOnly}
            key={dt}
            value={dt}
            control={<Radio color="primary" />}
            label={dt}
          />
        ));
      case "secondDegree":
        return DEGREE_TYPES.second.map((dt) => (
          <FormControlLabel
            disabled={isActiveReadOnly}
            key={dt}
            value={dt}
            control={<Radio color="primary" />}
            label={dt}
          />
        ));

      case "doctor":
        return DEGREE_TYPES.doctor.map((dt) => (
          <FormControlLabel
            disabled={isActiveReadOnly}
            key={dt}
            value={dt}
            control={<Radio color="primary" />}
            label={dt}
          />
        ));
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="filled" disabled={isActiveReadOnly} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              תואר אקדמי
            </InputLabel>
            <Select
              labelId="AcademicDegreeLabel"
              id="select-outlined"
              value={selectedDegree}
              onChange={handleChangeDegree}
              label="תואר אקדמי"
            >
              <MenuItem value="">
                <em> ללא תואר</em>
              </MenuItem>
              <MenuItem value={"firstDegree"}>תואר ראשון</MenuItem>
              <MenuItem value={"secondDegree"}>תואר שני</MenuItem>
              <MenuItem value={"doctor"}>דוקטור</MenuItem>
              <MenuItem value={"engineer"}>מהנדס</MenuItem>
              <MenuItem value={"practicalEngineer"}>הנדסאי</MenuItem>
              <MenuItem value={"technition"}>טכנאי</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                disabled={selectedDegree === "" || isActiveReadOnly}
                checked={generalDegree}
                onClick={handleGeneralDegree}
                name="generalDegree"
                color="primary"
              />
            }
            label="כל סוג תואר"
          />
          <Collapse in={selectedDegree !== "" && !generalDegree}>
            <Typography
              style={{ fontWeight: 700, marginBottom: 15 }}
              color="textPrimary"
              variant="subtitle1"
            >
              בחר את סוג התואר:
            </Typography>
            <FormControl component="fieldset" disabled={isActiveReadOnly}>
              <RadioGroup
                aria-label="degreeTypeRadio"
                name="radioType"
                value={degreeType}
                onChange={handleDegreeTypeChange}
              >
                {createDegreeTypeRadios()}
              </RadioGroup>
            </FormControl>
          </Collapse>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="additionalInfo"
            label="מידע נוסף למועמד"
            variant="filled"
            value={additionalInfo}
            onChange={(event) => setAdditionalInfo(event.target.value)}
            fullWidth
            disabled={isActiveReadOnly}
          />
        </Grid>
        <Grid item xs={6}>
          {isActive && (
            <FilterQuestionRelationsTag
              category={AI_ACADEMIC_DGREE}
              term={selectedDegree}
              onSelect={(value) => setTags(value)}
              value={tags}
              readOnly={isActiveReadOnly}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={selectedDegree === "" || isActiveReadOnly}
            control={
              <Checkbox
                checked={isDegreeIsMust ? true : false}
                onClick={handleIsDegreeIsMust}
                name="requiredFQ"
                color="primary"
              />
            }
            label="תנאי זה הוא חובה"
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isActive && (
              <FilterQuestionDisplayPrevew
                previewQuestion={academicDegreePreviewQuestion(
                  selectedDegree !== "" ? selectedDegree : "",
                  degreeType,
                  generalDegree,
                  additionalInfo
                )}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CancelApproveButtons
            positiveCaption="סיימתי"
            negativeCaption="ביטול"
            positiveDisabled={
              selectedDegree === "" || !tags || tags.length === 0
            }
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default AcademicDegree;
