import React from "react";
import EventRow from "./EventRow";
import ThumbUpAltRoundedIcon from "@material-ui/icons/ThumbUpAltRounded";
import { Button } from "@material-ui/core";

const CandidateEvents = (props) => {
  const [eventsRendering, setEventsRendering] = React.useState([]);

  React.useEffect(() => {
    let localEvents = [];
    localEvents =
      props.filterByType && props.filterByType !== ""
        ? props.candidateData.filter(
            (event) => event.event.event_key === props.filterByType
          )
        : props.candidateData;
    if (props.jobId && props.showCurrentJobOnly) {
      localEvents = localEvents.filter(
        (event) =>
          parseInt(event.event.job_id) === props.jobId ||
          ((event.event.event_key === "link_to_job" ||
            event.event.event_key === "removeFromJob") &&
            parseInt(event.event.event_value) === props.jobId)
      );
    }
    if (
      props.filter &&
      props.filter.filterByDate &&
      props.filter.filterByDate !== ""
    ) {
      switch (props.filter.filterByDate) {
        case "lastSevenDays": {
          const sevenDays = new Date(
            new Date().setDate(new Date().getDate() - 7)
          );
          localEvents = localEvents.filter(
            (event) => new Date(event.event.event_date) >= sevenDays
          );
          break;
        }
        case "lastFourteenDays": {
          const sevenDays = new Date(
            new Date().setDate(new Date().getDate() - 14)
          );
          localEvents = localEvents.filter(
            (event) => new Date(event.event.event_date) >= sevenDays
          );
          break;
        }
        case "thisMonth": {
          const firstDay = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          );
          localEvents = localEvents.filter(
            (event) => new Date(event.event.event_date) >= firstDay
          );
          break;
        }
        case "lastMonth": {
          const firstDay = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1,
            1
          );
          const lastDay = new Date(
            firstDay.getFullYear(),
            firstDay.getMonth() + 1,
            0
          );
          localEvents = localEvents.filter(
            (event) =>
              new Date(event.event.event_date) >= firstDay &&
              new Date(event.event.event_date) <= lastDay
          );
          break;
        }
        case "thisYear": {
          const currentYear = new Date(new Date().getFullYear(), 1, 1);
          localEvents = localEvents.filter(
            (event) => new Date(event.event.event_date) >= currentYear
          );
          break;
        }

        default: {
          //localEvents = [];
        }
      }
    }

    setEventsRendering(localEvents);
  }, [
    props.candidateData,
    props.showCurrentJobOnly,
    props.filterByType,
    props.jobId,
    props.filter,
  ]);
  const events = eventsRendering
    .sort((a, b) => new Date(b.event.event_date) - new Date(a.event.event_date))
    .map((event) => {
      switch (event.event.event_key) {
        case "job_mail_sent":
          return (
            <EventRow
              key={event.event.event_key + "_" + event.event.id}
              eventTitle={`מועמד זומן לצפייה במשרה  ${event.event.job_id}`}
              authorImage={event.author_image}
              event={event.event}
              jobTitle={event.job_title}
              authorName={event.author_name}
            />
          );
        case "link_to_job":
          return (
            <EventRow
              key={event.event.id}
              eventTitle={`מועמד שוייך למשרה  ${event.event.event_value}`}
              authorImage={event.author_image}
              event={event.event}
              jobTitle={event.job_title}
              authorName={event.author_name}
            />
          );
        case "generateJobInvitation":
          return (
            <EventRow
              key={event.event.id}
              eventTitle="נשלח זימון נוסף למשרה"
              event={event.event}
              jobTitle={event.job_title}
              authorImage={event.author_image}
              authorName={event.author_name}
            />
          );
        case "removeFromJob":
          return (
            <EventRow
              key={event.event.id}
              eventTitle="המועמד הוסר ממשרה"
              authorImage={event.author_image}
              event={event.event}
              jobTitle={event.job_title}
              authorName={event.author_name}
            />
          );
        case "unsubscribeRequest":
          return (
            <EventRow
              key={event.event.id}
              eventType="unsubscribeRequest"
              eventTitle={`מועמד התווסף לרשימת הסרה מדיוור. סיבת ההסרה: ${event.event.event_value}.`}
              authorImage={event.author_image}
              event={event.event}
              authorName={event.author_name}
            />
          );
        case "addComment":
          return (
            <EventRow
              key={event.event.id}
              eventType="addComment"
              authorImage={event.author_image}
              event={event.event}
              jobTitle={event.job_title}
              authorName={event.author_name}
            />
          );
        case "cvReferrals":
          return (
            <EventRow
              key={event.event.id}
              eventTitle={`קורות החיים של המועמד נשלחו אל הלקוח עבור משרה מספר ${event.event.event_value}`}
              eventType="cvReferrals"
              authorImage={event.author_image}
              event={event.event}
              jobTitle={event.job_title}
              authorName={event.author_name}
            />
          );
        case "addRating":
          return (
            <EventRow
              key={event.event.id}
              eventType="addRating"
              eventTitle="הוספת דירוג למועמד"
              authorImage={event.author_image}
              event={event.event}
              authorName={event.author_name}
            />
          );

        case "candidate_declined_job":
          let rejectionReason = "";
          switch (event.event.event_value) {
            case "employed":
              rejectionReason = "כבר מועסק";
              break;
            case "salary":
              rejectionReason = "השכר המוצע";
              break;
            case "location":
              rejectionReason = "מיקום המשרה";
              break;
            case "jobRequirements":
              rejectionReason = "דרישות המשרה";
              break;
            case "other":
              rejectionReason = `אחר ${
                event.event.event_comment
                  ? ": " + event.event.event_comment
                  : ""
              }`;
              break;
            default:
              break;
          }
          return (
            <EventRow
              key={event.event.id}
              eventType="candidate_declined_job"
              eventTitle={`מועמד דחה את הצעת משרה מספר ${event.event.job_id}. סיבת הדחייה: ${rejectionReason}`}
              jobTitle={event.job_title}
              authorImage={event.author_image}
              event={event.event}
              authorName={event.author_name}
            />
          );
        case "addPhoneInterview":
          return (
            <EventRow
              key={event.event.id}
              eventType="addPhoneInterview"
              eventTitle={`בוצע ראיון טלפוני למועמד עבור משרה מספר ${event.event.job_id}. `}
              authorImage={event.author_image}
              jobTitle={event.job_title}
              event={event.event}
              authorName={event.author_name}
              onAddEvent={props.onAddEvent}
            />
          );
        case "advancedRecruitmentProcedures":
          return (
            <EventRow
              key={event.event.id}
              eventType="advancedRecruitmentProcedures"
              eventTitle={`מועמד עבר לתהליכי גיוס מתקדמים במשרה מספר ${event.event.job_id}. `}
              authorImage={event.author_image}
              jobTitle={event.job_title}
              event={event.event}
              authorName={event.author_name}
              onAddEvent={props.onAddEvent}
            />
          );
        case "sendToInterview":
          return (
            <EventRow
              key={event.event.id}
              eventType="sendToInterview"
              eventTitle={`מועמד זומן לראיון עבודה עבור משרה מספר ${event.event.event_value} `}
              authorImage={event.author_image}
              jobTitle={event.job_title}
              event={event.event}
              authorName={event.author_name}
              interview={event.interview ? event.interview : ""}
            />
          );
        case "candidate_accepted_job":
          let title = "";
          if (event.event.event_value === "failed")
            title = `מועמד התעניין במשרה מספר ${event.event.job_id}, אך נדחה אוטומטית`;
          else if (event.event.event_value === "partial")
            title = `מועמד התעניין במשרה מספר ${event.event.job_id}, ועבר שאלות סינון באופן חלקי`;
          else
            title = `מועמד התעניין במשרה מספר ${event.event.job_id}, ועבר את שאלות הסינון`;
          return (
            <EventRow
              key={event.event.id}
              eventType="candidate_accepted_job"
              jobTitle={event.job_title}
              eventTitle={title}
              authorImage={event.author_image}
              event={event.event}
              authorName={event.author_name}
            />
          );
        case "sendInvitation":
          return (
            <EventRow
              key={event.event.id}
              eventType="sendInvitation"
              eventTitle={`מועמד קיבל זימון למשרה מספר ${event.event.event_value}`}
              authorImage={event.author_image}
              event={event.event}
              jobTitle={event.job_title}
              authorName={event.author_name}
            />
          );
        case "candidate_watched_job":
          return (
            <EventRow
              key={event.event.id}
              eventType="candidate_watched_job"
              eventTitle={`מועמד צפה במשרה מספר ${event.event.job_id}`}
              authorImage={event.author_image}
              event={event.event}
              jobTitle={event.job_title}
              authorName={event.author_name}
            />
          );
        case "recruitmentSucceeded":
          return (
            <EventRow
              key={event.event.id}
              eventType="recruitmentSucceeded"
              eventTitle={`מועמד גויס בהצלחה למשרה ${event.event.job_id}`}
              authorImage={event.author_image}
              event={event.event}
              jobTitle={event.job_title}
              authorName={event.author_name}
              endIcon={<ThumbUpAltRoundedIcon />}
            />
          );
        default:
          return null;
      }
    });

  return (
    <React.Fragment>
      {events}
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={props.eventsOffset ? props.eventsOffset : null}
          style={{ marginTop: 16 }}
          disabled={parseInt(props.totalEvents) === eventsRendering.length}
        >
          הצג אירועים נוספים
        </Button>
      </div>
    </React.Fragment>
  );
};
export default CandidateEvents;
