import React, { useContext } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import {
  MenuDashboardIcon,
  LinkToJobIcon,
  MenuJobsIcon,
  MenuLibiExploreIcon,
  MenuSettingsIcon,
  MenuCandidatesIcon,
} from "../../Assets/CustomIcons/CustomIconsList";

import ConfigurationListsContext from "../../context/configurationListsContext";

const StyleListItem = withStyles((theme) => ({
  root: {
    "&.Mui-selected": {
      backgroundColor: pink[50],
      "& .MuiListItemIcon-root": {
        color: pink[400],
      },
      "& .MuiTypography-root": {
        color: pink[400],
      },
    },
    "&:hover": {
      backgroundColor: pink[50],
      "& .MuiListItemIcon-root": {
        color: pink[400],
      },
      "& .MuiTypography-root": {
        color: pink[400],
      },
    },

    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
  },
}))(ListItem);

const MainMenuItems = (props) => {
  const history = useHistory();
  const usersContext = useContext(ConfigurationListsContext).users;

  const handleGoToJobs = () => {
    sessionStorage.removeItem("ljp");
    history.push("/jobs");
  };

  const mainListItems = (
    <div>
      <StyleListItem
        button
        selected={history.location.pathname === "/dashboard"}
        onClick={() => history.push("/dashboard")}
      >
        <ListItemIcon>
          <MenuDashboardIcon />
        </ListItemIcon>
        <ListItemText primary="לוח מחוונים" />
      </StyleListItem>
      <StyleListItem
        button
        selected={history.location.pathname === "/candidates"}
        onClick={() => history.push("/candidates")}
      >
        <ListItemIcon>
          <MenuCandidatesIcon />
        </ListItemIcon>
        <ListItemText primary="מועמדים" />
      </StyleListItem>
      <StyleListItem
        button
        selected={history.location.pathname === "/jobs"}
        onClick={handleGoToJobs}
      >
        <ListItemIcon>
          <MenuJobsIcon />
        </ListItemIcon>
        <ListItemText primary="משרות" />
      </StyleListItem>
      <StyleListItem
        button
        selected={history.location.pathname === "/explore"}
        onClick={() => history.push("/explore")}
      >
        <ListItemIcon>
          <MenuLibiExploreIcon />
        </ListItemIcon>
        <ListItemText primary="אקספלור" />
      </StyleListItem>
    </div>
  );
  const secondaryListItems = (
    <div>
      <ListSubheader inset>מידע נוסף</ListSubheader>
      <StyleListItem
        button
        selected={props.screen === "activityReport"}
        onClick={() => history.push("/activityReport")}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="דוח פעילות מפורט" />
      </StyleListItem>
      <StyleListItem
        button
        selected={props.screen === "tracers"}
        onClick={() => history.push("/tracers")}
      >
        <ListItemIcon>
          <LinkToJobIcon />
        </ListItemIcon>
        <ListItemText primary="קישורי הפצה" />
      </StyleListItem>
      {/* <StyleListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="דוח מהיר 3" />
      </StyleListItem> */}
    </div>
  );

  const thirdListItems = (
    <div>
      <ListSubheader inset>הגדרות תצורה</ListSubheader>
      <StyleListItem
        button
        selected={history.location.pathname === "/settings"}
        onClick={() => history.push("/settings")}
      >
        <ListItemIcon>
          <MenuSettingsIcon />
        </ListItemIcon>
        <ListItemText primary="הגדרות" />
      </StyleListItem>
      <StyleListItem
        button
        selected={history.location.pathname === "/tags-manager"}
        onClick={() => history.push("/tags-manager")}
        disabled={
          usersContext &&
          usersContext.length &&
          !usersContext.find(
            (user) =>
              parseInt(user.id) === parseInt(localStorage.getItem("userId"))
          ).isAdmin
            ? true
            : false
        }
      >
        <ListItemIcon>
          <MenuSettingsIcon />
        </ListItemIcon>
        <ListItemText primary="ניהול תגיות" />
      </StyleListItem>
    </div>
  );
  switch (props.list) {
    case "main":
      return mainListItems;
    case "secondary":
      return secondaryListItems;
    case "third":
      return thirdListItems;
    default:
      break;
  }
};

export default MainMenuItems;
