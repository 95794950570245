export const SCREEN_DASHBOARD = "Dashboard";
export const SCREEN_CANDIDATES = "Candidates";
export const SCREEN_COMPANIES = "Companies";
export const SCREEN_JOBS = "Jobs";
export const SCREEN_TRACERS = "Tracers";
export const SCREEN_SETTINGS = "Settings";
export const DEGREE_TYPES = {
  first: [
    "B.A",
    "B.Sc",
    "B.Ed",
    "B.SW",
    "LL.B.",
    "B.Sw",
    "B.Tech",
    "B.Des",
    "B.Arch.",
  ],
  second: ["M.A", "M.B.A", "L.L.M", "M.Sc", "M.E", "M.Ed"],
  doctor: ["Ph.D", "LL.D.", "M.D", "D.M.D", "D.V.M"],
  engineer: ["B.Sc"],
  practicalEngineer: [],
  technition: [],
};

export const AI_EXPERIENCES = "experiences";
export const AI_SKILLS = "skills";
export const AI_LICENSES = "licenses";
export const AI_ACADEMIC_DGREE = "academicDegree";
export const AI_CERTIFICATES = "certificates";
export const AI_LANGUAGES = "languages";
export const AI_SALARY_EXPECTATIONS = "salaryExpectations";
export const AI_DYNAMICS = "dynamics";
export const AI_LOCATION = "location";
export const AI_AVAILABILITY = "availability";

export const translateCategories = (category) => {
  switch (category) {
    case AI_EXPERIENCES:
      return "ניסיון מקצועי";
    case "experience":
      return "ניסיון מקצועי";
    case AI_SKILLS:
      return "יכולות";
    case AI_LICENSES:
      return "רישיונות";
    case "academicDegrees":
      return "תארים אקדמיים";
    case AI_ACADEMIC_DGREE:
      return "תארים אקדמיים";
    case AI_CERTIFICATES:
      return "תעודות";
    case AI_LANGUAGES:
      return "שפות";
    case AI_SALARY_EXPECTATIONS:
      return "ציפיות שכר";
    case "salary":
      return "ציפיות שכר";
    case AI_DYNAMICS:
      return "שאלות דינאמיות";
    case AI_LOCATION:
      return "מיקום";
    case AI_AVAILABILITY:
      return "זמינות";
    default:
      return "";
  }
};

export const LOW_SMS_BALANCE = 500;
export const MED_SMS_BALANCE = 10000;
//export const HIGH_SMS_BALANCE IS NOT REQUIRED, as the value is MED_SMS_BALANCE + 1

let smsTemplateDefault =
  "שלום {{שם מלא}},\nשמי {{שם רכזת}}, מלין ביכלר משאבי אנוש\n\rקיבלתי את קורות החיים שלך ויש לי משרה שעשויה להתאים לך\nלפרטי המשרה ותנאי השכר >> {{job_url}}\nבהצלחה, {{שם פרטי רכזת}}\nלהסרה >> {{unsubscribe_url}}";

let smsTemplate2 =
  "שלום {{שם מלא}},\nשמי {{שם רכזת}}, מלין ביכלר משאבי אנוש\n\rצועדים יד ביד כל הדרך למציאת משרת חלומותיך!\nלפרטי המשרה ותנאי השכר >> {{job_url}}\nבהצלחה, {{שם פרטי רכזת}}\nלהסרה >> {{unsubscribe_url}}";

let smsTemplate3 =
  "שלום {{שם מלא}},\nשמי {{שם רכזת}}, מלין ביכלר משאבי אנוש\n\rכמה ופשוט ונוח לתת לי לאתר עבורך משרה! \nלפרטי המשרה ותנאי השכר >> {{job_url}}\nבהצלחה, {{שם פרטי רכזת}}\nלהסרה >> {{unsubscribe_url}}";

let smsTemplate4 =
  "שלום {{שם מלא}},\nשמי {{שם רכזת}}, מלין ביכלר משאבי אנוש\n\rאני חושבת שמצאתי עבורך משרה מתאימה\nלפרטי המשרה ותנאי השכר >> {{job_url}}\nבהצלחה, {{שם פרטי רכזת}}\nלהסרה >> {{unsubscribe_url}}";

let smsTemplate5 =
  "שלום {{שם מלא}},\nשמי {{שם רכזת}}, מלין ביכלר משאבי אנוש\n\rאוכל לפתוח עבורך דלתות לעבודה בחברות מובילות\nלפרטי המשרה ותנאי השכר >> {{job_url}}\nבהצלחה, {{שם פרטי רכזת}}\nלהסרה >> {{unsubscribe_url}}";

export const SMSTEMPLATES = [
  smsTemplateDefault,
  smsTemplate2,
  smsTemplate3,
  smsTemplate4,
  smsTemplate5,
];

export const ACCOUNT_REDIRECT_WEBSITE = "https://l-b.co.il";
export const ACCOUNT_CONTACT_EMAIL = "cv@l-b.co.il";
export const ACCOUNT_DISPLAY_NAME = "לין ביכלר משאבי אנוש";

export const filterOptions = [
  { id: 2, name: "gender", label: "מגדר", allowDuplicate: false },
  { id: 3, name: "familyStatus", label: "מצב משפחתי", allowDuplicate: false },
  { id: 4, name: "ages", label: "טווח גילאים", allowDuplicate: false },
  { id: 5, name: "rating", label: "דירוג כוכבים", allowDuplicate: false },
  { id: 6, name: "city", label: "עיר מגורים", allowDuplicate: false },
  {
    id: 7,
    name: "workingArea",
    label: "אזור מותאם לעבודה",
    allowDuplicate: false,
  },
  { id: 8, name: "professions", label: "מקצועות", allowDuplicate: false },
  {
    id: 9,
    name: AI_EXPERIENCES,
    label: "ניסיון תעסוקתי",
    allowDuplicate: true,
  },
  { id: 10, name: AI_SKILLS, label: "כישורים", allowDuplicate: true },
  { id: 11, name: AI_LICENSES, label: "רישיונות", allowDuplicate: true },
  {
    id: 12,
    name: AI_ACADEMIC_DGREE,
    label: "תואר אקדמי",
    allowDuplicate: false,
  },
  { id: 13, name: AI_CERTIFICATES, label: "תעודות", allowDuplicate: true },
  { id: 14, name: AI_LANGUAGES, label: "שפות", allowDuplicate: true },
  {
    id: 15,
    name: AI_SALARY_EXPECTATIONS,
    label: "ציפיות שכר",
    allowDuplicate: false,
  },
  //{ id: 16, name: "location", label: "מיקום", allowDuplicate: false },
  //{ id: 17, name: "buildInTags", label: "תגיות מובנות", allowDuplicate: true },
  { id: 18, name: "freeTags", label: "תגיות חופשיות", allowDuplicate: true },
  {
    id: 19,
    name: "addedToSystem",
    label: "נוסף למערכת",
    allowDuplicate: false,
  },
  {
    id: 20,
    name: AI_AVAILABILITY,
    label: "זמינות",
  },
  {
    id: 21,
    name: AI_LOCATION,
    label: "תגיות מיקום",
  },
  {
    id: 22,
    name: "acceptedCities",
    label: "ערים שאושרו לעבודה",
  },
];
