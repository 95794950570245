import React from "react";
import {
  Container,
  Grid,
  useTheme,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
//import Scorecard from "../../../../../components/Scorecard/Scorecard";
import ScorecardJobItem from "../../../../../components/Scorecard/ScorecardJobItem";
import DeclineJobReasons from "../../../../../components/DeclineJobReasonsGraph/DeclineJobReasons";
import PropTypes from "prop-types";
import JobByGenderGraph from "../../../../../components/JobStatsByGenderGraph/JobByGenderGraph";
import JobQuestionierResultStats from "../../../../../components/JobQuestionierResultsDistributionStats/JobQuestionierResultStats";
import JobByDeviceGraph from "../../../../../components/JobByDevicePieGraph/JobByDeviceGraph";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiPaper-root": {
      marginLeft: 15,
      marginBottom: 15,
      // "&:nth-child(3n+1)": {
      //   marginLeft: 0,
      // },
    },
  },
  paper: {
    "& .MuiPaper-root": {
      padding: theme.spacing(2),
    },
    marginBottom: theme.spacing(4),
  },
}));

const calcTotalPercent = (value, total) => {
  if (value === 0 || total === 0 || !value || !total) return 0;
  return parseFloat((value / total) * 100).toFixed(1);
};

const JobStatistics = ({ jobData }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">
            אחוז תגובה כללי:{" "}
            {jobData.total_assigned > 0
              ? (
                  parseFloat(
                    (jobData.total_declined.length +
                      jobData.total_auto_disqualified.length +
                      jobData.total_passed.length +
                      jobData.total_partially_passed.length) /
                      jobData.total_assigned
                  ) * 100
                ).toFixed(1)
              : null}
            %
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ padding: 0, marginBottom: theme.spacing(4) }}
        >
          <Box display="flex" flexWrap="wrap" className={classes.root}>
            <ScorecardJobItem
              label="מועמדים"
              value={
                jobData.total_assigned ? parseInt(jobData.total_assigned) : 0
              }
              color="#A12CFF"
            />

            <ScorecardJobItem
              label="צפו במשרה"
              value={
                jobData.total_watched
                  ? parseInt(jobData.total_watched.length)
                  : 0
              }
              color="#00B2FF"
              total={calcTotalPercent(
                jobData.total_watched
                  ? parseInt(jobData.total_watched.length)
                  : 0,
                jobData.total_assigned ? parseInt(jobData.total_assigned) : 0
              )}
              differenceValuePrefix={"%"}
            />
            <ScorecardJobItem
              label="צפו ולא הגיבו"
              value={
                jobData.total_watched.length -
                jobData.total_interested.length -
                jobData.total_declined.length
              }
              color="#005980"
              total={calcTotalPercent(
                jobData.total_watched.length -
                  jobData.total_interested.length -
                  jobData.total_declined.length,
                jobData.total_assigned ? parseInt(jobData.total_assigned) : 0
              )}
              differenceValuePrefix={"%"}
            />
            <ScorecardJobItem
              label="מעוניינים"
              value={jobData.total_interested.length}
              color="#0DD9A2"
              total={calcTotalPercent(
                jobData.total_interested.length,
                jobData.total_assigned ? parseInt(jobData.total_assigned) : 0
              )}
              differenceValuePrefix={"%"}
            />
            <ScorecardJobItem
              label="מעוניינים מתאימים"
              value={
                jobData.total_passed.length +
                jobData.total_partially_passed.length
              }
              color="#16C542"
              total={calcTotalPercent(
                jobData.total_passed.length +
                  jobData.total_partially_passed.length,
                jobData.total_assigned ? parseInt(jobData.total_assigned) : 0
              )}
              differenceValuePrefix={"%"}
            />
            <ScorecardJobItem
              label="דחו הצעה"
              value={
                jobData.total_declined
                  ? parseInt(jobData.total_declined.length)
                  : 0
              }
              color="#E51010"
              total={calcTotalPercent(
                jobData.total_declined
                  ? parseInt(jobData.total_declined.length)
                  : 0,
                jobData.total_assigned ? parseInt(jobData.total_assigned) : 0
              )}
              differenceValuePrefix={"%"}
            />
            <ScorecardJobItem
              label="נדחו אוטומוטית"
              value={
                jobData.total_auto_disqualified
                  ? parseInt(jobData.total_auto_disqualified.length)
                  : 0
              }
              color="#FC6E00"
              total={calcTotalPercent(
                jobData.total_auto_disqualified
                  ? parseInt(jobData.total_auto_disqualified.length)
                  : 0,
                jobData.total_assigned ? parseInt(jobData.total_assigned) : 0
              )}
              differenceValuePrefix={"%"}
            />

            <ScorecardJobItem
              label="הועברו קורות חיים"
              value={
                jobData.total_cv_referrals
                  ? parseInt(jobData.total_cv_referrals.length)
                  : 0
              }
              color="#AC92EC"
              total={calcTotalPercent(
                jobData.total_cv_referrals
                  ? parseInt(jobData.total_cv_referrals.length)
                  : 0,
                jobData.total_assigned ? parseInt(jobData.total_assigned) : 0
              )}
              differenceValuePrefix={"%"}
            />
            <ScorecardJobItem
              label="גויסו למשרה"
              value={
                jobData.total_recruited
                  ? parseInt(jobData.total_recruited.length)
                  : 0
              }
              color={theme.palette.primary.main}
              total={calcTotalPercent(
                jobData.cv_referrals
                  ? parseInt(jobData.total_recruited.length)
                  : 0,
                jobData.total_assigned ? parseInt(jobData.total_assigned) : 0
              )}
              differenceValuePrefix={"%"}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.paper}>
          <DeclineJobReasons jobId={jobData.id} />
        </Grid>
        <Grid item xs={4} className={classes.paper}>
          <JobByGenderGraph jobId={jobData.id} />
        </Grid>
        <Grid item xs={4} className={classes.paper}>
          <JobByDeviceGraph jobId={jobData.id} />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ padding: 0, marginBottom: theme.spacing(4) }}
          className={classes.paper}
        >
          <JobQuestionierResultStats jobId={jobData.id} />
        </Grid>
      </Grid>
    </Container>
  );
};

JobStatistics.propTypes = {
  jobData: PropTypes.object.isRequired,
};

export default JobStatistics;
