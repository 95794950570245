import React from "react";
import { TextField, Box, InputAdornment } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
//import axios from "../../utils/axiosInstance";

const useStyles = () => (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  job: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const SearchJob = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [jobs, setJobs] = React.useState([]);
  const loading = open && jobs.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await axios.get("/wp-json/api/v2/jobs_title");
      const jobs = response.data;

      if (active) {
        setJobs(jobs);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const handleInputChange = async (event, value, reason) => {
    //check if we have the data:
    if (value.length > 2) {
      (async () => {
        const response = await axios.get("/wp-json/api/v2/jobs_title", {
          params: {
            search: value,
          },
        });
        const jobs = response.data;
        setJobs(jobs);
      })();
    } else {
      (async () => {
        const response = await axios.get("/wp-json/api/v2/jobs_title");
        const jobs = response.data;
        setJobs(jobs);
      })();
    }

    //props.linkToJobId(value.id)
  };

  return (
    <Box className={classes.root}>
      {props.searchMode ? (
        <Autocomplete
          id="jobsSearch"
          style={{ width: props.fullWidth ? "auto" : 400 }}
          open={open}
          fullWidth={props.fullWidth ? true : false}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onInputChange={handleInputChange}
          onChange={(event, value, reason) => props.linkToJobId(value.id)}
          //getOptionSelected={(job, value) => props.linkToJobId(value.id)}
          filterOptions={(options, params) => {
            if (params.inputValue === "") return options;
            return options.filter(
              (job) =>
                job.id.toString().includes(params.inputValue) ||
                job.title.includes(params.inputValue)
            );
          }}
          getOptionSelected={(job, value) => job.id === value.id}
          getOptionLabel={(job) => job.title}
          options={jobs}
          loading={loading}
          renderOption={(job) => (
            <React.Fragment>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: 12, marginLeft: theme.spacing(1) }}>
                  מספר:{job.id}
                </span>
                {job.title}
              </div>
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="אתר משרה"
              variant={props.variant ? props.variant : "outlined"}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      ) : (
        <TextField
          label="מספר משרה"
          id="jobId"
          className={classes.textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">ID</InputAdornment>
            ),
          }}
          onBlur={props.linkToJobId}
          variant="outlined"
        />
      )}
    </Box>
  );
};
export default SearchJob;
