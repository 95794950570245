import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import {
  Grid,
  Typography,
  Collapse,
  FormControlLabel,
  Checkbox,
  Box,
  TextField,
} from "@material-ui/core";
import FilterQuestionDisplayPrevew from "../FilterQuestionDisplayPreview";
import { licensePreviewQuestion } from "../../../../../config/jobsConfigData";
import FilterQuestionRelationsTag from "../FilterQuestionRelationsTag";
import { AI_LICENSES } from "../../../../../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
}));

const FQLicense = ({
  onCancel,
  onCompletion,
  fqEdit = null,
  isActive,
  isActiveReadOnly,
}) => {
  const [minLicenseYears, setMinLicenseYears] = React.useState(0);
  const [selectedLicense, setSelectedLicense] = React.useState("");
  const [isManualLicense, setIsManualLicense] = React.useState(false);
  const [isLicenseAMust, setIsLicenseAMust] = React.useState(false);
  const [isYouth, setIsYouth] = React.useState(false);
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [tags, setTags] = useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    if (fqEdit !== null) {
      setSelectedLicense(fqEdit.licenseOn);
      setMinLicenseYears(fqEdit.minYearsLicense);
      setIsLicenseAMust(fqEdit.isLicenseAMust);
      setIsManualLicense(fqEdit.isVehicalManual);
      setIsYouth(fqEdit.isNotYouth);
      setAdditionalInfo(fqEdit.addInfo);
      setTags(fqEdit.tags);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  /*
  Single license Object: 
  {
      licenseId: AutoID
      licenseOn: string: license type,
      minYearsLicense: num,
      isLicenseAMust: boolean,
      isVehicalManual: boolean,
      isNotYouth: boolean
  }
  */

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["licenseOn"] = selectedLicense;
      existingObject["minYearsLicense"] = parseInt(minLicenseYears);
      existingObject["isLicenseAMust"] = isLicenseAMust;
      existingObject["isVehicalManual"] = isManualLicense;
      existingObject["isNotYouth"] = isYouth;
      existingObject["addInfo"] = additionalInfo;
      existingObject["tags"] = tags;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      licenseOn: selectedLicense,
      minYearsLicense: minLicenseYears,
      isLicenseAMust,
      isVehicalManual: isManualLicense,
      isNotYouth: isYouth,
      addInfo: additionalInfo,
      tags,
    });
  };

  function clearForm() {
    //clean the form values:
    setMinLicenseYears(0);
    setIsLicenseAMust(false);
    setSelectedLicense("");
    setIsManualLicense(false);
    setIsYouth(false);
    setAdditionalInfo("");
    setTags([]);
  }

  const handleCheckEnableQ = (value) => {
    if (!value) setMinLicenseYears(0);
  };
  const handleMinYearsLicense = (event) =>
    setMinLicenseYears(event.target.value);
  const handleSelectedLicense = (event) => {
    setSelectedLicense(event.target.value);
    if (event.target.value !== "רכב") {
      setIsYouth(false);
      setIsManualLicense(false);
    }
  };

  const handleIsLicenseIsMust = (event) =>
    setIsLicenseAMust(event.target.checked);

  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          בחר רישיון:
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="lincense"
            label="רישיון נדרש"
            variant="filled"
            value={selectedLicense}
            onChange={handleSelectedLicense}
            fullWidth
            helperText='הזן "רכב" להגדרות רישיון רכב'
            disabled={isActiveReadOnly}
          />
          <div className={classes.carLicense}>
            <Collapse in={selectedLicense.startsWith("רכב")}>
              <FormControlLabel
                disabled={isActiveReadOnly}
                control={<Checkbox name="manualLicense" color="primary" />}
                label="רישיון ידני"
                onClick={() => setIsManualLicense(true)}
              />
              <FormControlLabel
                disabled={isActiveReadOnly}
                control={<Checkbox name="manualLicense" color="primary" />}
                label="לא נהג צעיר"
                onClick={() => setIsYouth(true)}
              />
            </Collapse>
          </div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="additionalInfo"
            label="מידע נוסף למועמד"
            variant="filled"
            value={additionalInfo}
            onChange={(event) => setAdditionalInfo(event.target.value)}
            fullWidth
            helperText="לדוגמה: כולל הכשרות מקצועיות"
            disabled={isActiveReadOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <QuestionValueInput
            disabled={selectedLicense === "" || isActiveReadOnly}
            question="מספר שנים מינימילי הנדרש לרישיון זה:"
            minYearsValue={handleMinYearsLicense}
            isAMustCheck={handleIsLicenseIsMust}
            value={minLicenseYears}
            isAmustValue={isLicenseAMust}
            checkEnalbeQ={handleCheckEnableQ}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          {isActive && (
            <FilterQuestionRelationsTag
              category={AI_LICENSES}
              term={selectedLicense}
              value={tags}
              onSelect={(value) => setTags(value)}
              readOnly={isActiveReadOnly}
            />
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FilterQuestionDisplayPrevew
              previewQuestion={licensePreviewQuestion(
                selectedLicense ? selectedLicense.trim() : "",
                additionalInfo
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CancelApproveButtons
            positiveCaption="סיימתי"
            negativeCaption="ביטול"
            positiveDisabled={
              selectedLicense === "" || !tags || tags.length === 0
            }
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default FQLicense;
